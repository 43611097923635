<app-wrapper header="Edits">
    <div *ngIf="!edits$">
        <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div *ngIf="edits$">
        <ng-container *ngIf="edits$ | async as edits ; else loadingOrError">
            <div *ngIf="!edits || edits.length == 0; else editstable">
                <app-empty icon="swimmer" header="No Swim Spot Edits"
                    description="All suggested swim spot edits will appear here">
                </app-empty>
            </div>
            <ng-template #editstable>
                <app-table [headers]="[{
                    header: 'Name'
                  }, {
                    header: 'Comment'
                  },{
                    header: 'Type'
                  },{
                    header: 'UserId'
                  }, {
                    header: ''
                  }]">
                    <tr class="cursor-pointer" (click)="open(edit.id)" *ngFor="let edit of edits">
                        <td app-table-cell [value]="edit.swimspot.name"></td>
                        <td app-table-cell [value]="edit.comment"></td>
                        <td *ngIf="edit.swimspot.type == WaterwayType.LAKE" app-table-cell value="Lake"></td>
                        <td *ngIf="edit.swimspot.type == WaterwayType.COASTAL" app-table-cell value="Coastal"></td>
                        <td *ngIf="edit.swimspot.type == WaterwayType.RIVER" app-table-cell value="River"></td>
                        <td *ngIf="edit.swimspot.type == WaterwayType.UNKNOWN" app-table-cell value="lake"></td>
                        <td app-table-cell [value]="edit.createdBy"></td>
                        <td app-table-cell [cta]="true" (onClick)="open(edit.id)" value="edit"></td>
                    </tr>
                </app-table>
                <br />
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>