<app-wrapper *ngIf="dataloaded" header="Badge Config">
    <div class="my-4">
        <h3 class="text-red-600 font-bold text-md mb-2">NOTES:</h3>
        <ul>
            <li>Date format in the value field is yyyy-mm-dd.</li>
            <li>Time format is hour based in the value field... so 5pm is 17.</li>
            <li>Some countries like Scotland are classed as a state! This is Googles structure.</li>
            <li>Waterway Types are WaterwayType = UNKNOWN, LAKE, RIVER, COASTAL. Use an enum Number in the value field
                for this.0,1,2,3</li>
        </ul>
    </div>
    <app-form [form]="form" (onSubmit)="update($event)" [floatingsave]="true">
        <app-inputgroup>
            <app-input [form]="form" name="title" description="Title" [span]=2>
            </app-input>
            <app-select [options]="sourceDocumentTypeOptions" [form]="form" name="sourceDocumentType"
                description="Source Document" [span]=2>
            </app-select>
            <app-select [options]="badgeConfigStatusOptions" [form]="form" name="status" description="Status" [span]=2>
            </app-select>
        </app-inputgroup>
        <div [formGroup]="form" class="mt-4">
            <div formArrayName="records">
                <div class="border-2 rounded-xl border-blue-900 mb-4 p-4 shadow-lg bg-white"
                    *ngFor="let record of records.controls; let pointIndex=index" [formGroupName]="pointIndex">
                    <div class="flex justify-between my-2 items-center">
                        <div class="flex w-56">
                            <img [src]="getImageUrl(pointIndex)" class="h-8 w-8 shadow-lg mr-4" />
                            <h2 class="font-semibold wild-text-primary text-lg">{{getTitle(record)}}
                                <span class="ml-1 text-2xl">
                                    <span *ngIf="getStatus(record) != 2">✍️</span>
                                    <span *ngIf="getStatus(record) == 2">🚀</span>
                                </span>
                            </h2>
                        </div>
                        <div class="flex justify-end ">
                            <div>
                                <a class="wild-text-primary cursor-pointer text-2xl mr-4"
                                    (click)="removeSectionWithConfirmation(pointIndex)" type="button">🗑️</a>
                                <a class="wild-text-primary cursor-pointer text-2xl mr-4"
                                    (click)="addBadgeConfigRecordForm(null,pointIndex)" type="button">➕</a>
                                <a class="wild-text-primary cursor-pointer text-2xl mr-4"
                                    (click)="moveSection(pointIndex,true)" type="button">☝️</a>
                                <a class="wild-text-primary cursor-pointer text-2xl mr-4"
                                    (click)="moveSection(pointIndex,false)" type="button">👇</a>
                                <a class="wild-text-primary cursor-pointer text-2xl" (click)="spot(record)"
                                    type="button">🌊</a>
                            </div>
                        </div>
                        <div class="cursor-pointer text-2xl" (click)="collapse(record)" *ngIf="openOrClosed(record)">⬆️
                        </div>
                        <div class="cursor-pointer text-2xl" (click)="expand(record)" *ngIf="!openOrClosed(record)">⬇️
                        </div>
                    </div>
                    <div [ngClass]="{'h-1 overflow-hidden': openOrClosed(record)}">
                        <app-inputgroup>
                            <app-select [options]="badgeTypeOptions" [form]="record" name="badgeType"
                                description="Badge" [span]=1>
                            </app-select>
                            <app-select [options]="badgeMetricTypeOptions" [form]="record" name="badgeMetricType"
                                description="Metric" [span]=1>
                            </app-select>
                            <app-select [options]="activityTypeOptions" [form]="record" name="activityType"
                                description="Activity" [span]=1>
                            </app-select>
                            <app-input [form]="record" name="threshold" description="Threshold" type="number" [span]=1>
                            </app-input>
                            <app-select [options]="badgeMetricDirectionOptions" [form]="record"
                                name="thresholdDirection" description="Threshold Direction" [span]=1>
                            </app-select>
                        </app-inputgroup>
                        <app-inputgroup>
                            <app-input [form]="record" name="value" description="Value / NA" [span]=1>
                            </app-input>
                            <app-select [options]="badgeConfigStatusOptions" [form]="record" name="status"
                                description="Status" [span]=1>
                            </app-select>
                        </app-inputgroup>
                        <app-inputgroup>
                            <app-input [form]="record" name="title" description="Title" [span]=2>
                            </app-input>
                            <app-input [form]="record" name="description" description="Description" [span]=4>
                            </app-input>
                        </app-inputgroup>
                        <app-inputgroup>
                            <app-input [form]="record" name="notificationMessage" description="Message" [span]=4>
                            </app-input>
                            <app-input [form]="record" name="notificationBody" description="Body" [span]=4>
                            </app-input>
                        </app-inputgroup>
                        <app-inputgroup>
                            <app-input [form]="record" name="image" description="Image" [span]=5>
                            </app-input>
                            <div class="flex items-center justify-center">
                                <img [src]="getImageUrl(pointIndex)" class="h-24 w-24 shadow-lg" />
                            </div>
                        </app-inputgroup>
                    </div>

                </div>
            </div>
        </div>
        <button class="wild-btn-primary mt-3" (click)="addBadgeConfigRecordForm(null)" type="button">Add</button>
    </app-form>
</app-wrapper>