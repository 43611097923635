import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { GarminActivityService } from 'src/portal/services/garminactivity-data.service';
import { GarminActivityStatus } from 'src/shared/data/enums/garmin_activity_status';

@Component({
  selector: 'app-garminactivities',
  templateUrl: './garminactivities.component.html'
})
export class GarminActivitiesComponent implements OnInit {

  activities$: Observable<any[]>;
  error: ErrorModel;
  GarminActivityStatus = GarminActivityStatus;

  constructor(
    private dates: DatesService,
    private data: GarminActivityService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  readableDateFromEpocy(epoch: number) {
    return this.dates.getFormattedDateTimeFromEpochTime(epoch);
  }

  getStatusColor(status: GarminActivityStatus): string {
    var color = "#DCDCDC";
    if (status == GarminActivityStatus.FAILED)
      color = "#dd3524";
    if (status == GarminActivityStatus.ALREADYPROCESSED)
      color = "#2E8B57";
    //CREATED MEANS CREATED POST
    if (status == GarminActivityStatus.CREATED)
      color = "#96bf65";
    if (status == GarminActivityStatus.NOUSERACCESSTOKEN)
      color = "#ffa07a";
    if (status == GarminActivityStatus.COULDNOTFINDUSERASSOCIATEDWITHACTIVITY)
      color = "#800000";
    if (status == GarminActivityStatus.NOTWATERBASED)
      color = "#f4a460";
    if (status == GarminActivityStatus.NOPOLYLINE)
      color = "#d2b48c";
    if (status == GarminActivityStatus.NOPOLYLINEMETADATA)
      color = "#ffa500";
    return color;
  }

  load() {
    this.activities$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
