export enum OrderStatus {
    UNKNOWN,
    SHOPPINGCART,
    EXPIRED,
    DELETED,
    PAID,
    ABANDONCART,
    PARTIALREFUND,
    FULLYREFUNDED,
    //Set on get by client secret
    PROCESSING,
    SUCCESSFULPAYMENTDETECTEDFROMSTRIPE,
    REPROCESSORDER,
    PROCESSSFREEORDER
}