<app-wrapper header="Alerts">
  <div *ngIf="!alerts$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="alerts$">
    <ng-container *ngIf="alerts$ | async as alerts ; else loadingOrError">
      <div *ngIf="!alerts || alerts.length == 0; else alertstable">
        <app-empty icon="edit" header="No alerts" description="New alerts will appear here">
        </app-empty>
      </div>
      <ng-template #alertstable>
        <app-table [headers]="[{
                    header: 'Created'
                  },{
                    header: 'Username'
                  },{
                    header: 'Spot'
                  },{
                    header: 'Likes'
                  },{
                    header: 'Comments'
                  },{
                    header: 'Alert'
                  },{
                    header: 'Message'
                  }, {
                    header: ''
                  }]">
          <tr *ngFor="let alert of alerts">
            <td app-table-cell [value]="readableDate(alert?.creation_date?.toDate())"></td>
            <td app-table-cell [value]="alert.username"></td>
            <td app-table-cell [cta]="true" (onClick)="openspot(alert.swimSpotId)" value="open spot"></td>
            <td app-table-cell [value]="alert.numberOfLikes ?? 0"></td>
            <td app-table-cell [value]="alert.numberOfComments ?? 0"></td>
            <td app-table-cell [value]="alert.comment"></td>
            <td app-table-cell [cta]="true" (onClick)="open(alert.id)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>