import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { GarminActivityService } from 'src/portal/services/garminactivity-data.service';
import { GarminPingStatus } from 'src/shared/data/enums/garmin_ping_status';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  selector: 'app-garminactivityping',
  templateUrl: './garminactivityping.component.html'
})
export class GarminActivityPingsComponent implements OnInit {

  pings$: Observable<any[]>;
  error: ErrorModel;
  GarminPingStatus = GarminPingStatus;
  retrying: boolean;

  constructor(
    private dates: DatesService,
    private http: HttpService,
    private notify: NotifyService,
    private data: GarminActivityService,) { }

  ngOnInit(): void {
    this.load();
  }

  async retry(id: string): Promise<void> {

    if (this.retrying == true)
      return;
    var confirm = window.confirm("Are you sure you want to retry " + id);
    if (confirm == true) {
      this.retrying = true;
      this.http.post(`${environment.api}/portal/integrations/resendgarmin/${id}`, {}).toPromise().then(() => {
        this.notify.notify("SUCESSS - RELOADING", "", NotifyType.SUCCESS);
        window.location.href = window.location.href;
        this.retrying = false;
      }).catch((error) => {
        this.notify.notify("RETRY FAILED", error.message, NotifyType.ERROR)
      })
    }
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  readableDateFromEpocy(epoch: number) {
    return this.dates.getFormattedDateTimeFromEpochTime(epoch);
  }

  getStatusColor(status: GarminPingStatus): string {
    var color = "#DCDCDC";
    if (status == GarminPingStatus.INSERTED)
      color = "#ADD8E6";
    if (status == GarminPingStatus.FAILED)
      color = "#dd3524";
    if (status == GarminPingStatus.ERRORFETCHINGACTIVITYDATA)
      color = "#8b0000";
    if (status == GarminPingStatus.PROCESSWITHWATERBASEDACTIVITIES)
      color = "#96bf65";
    if (status == GarminPingStatus.PROCESSEDWITHOUTWATERBASEDACTIVITIES)
      color = "#bbc9a5";
    if (status == GarminPingStatus.COULDNOTFINDWILDUSERFORTHISPING)
      color = "#800000";
    if (status == GarminPingStatus.INSERTEDRETRY)
      color = "#079563";
    return color;
  }

  load() {
    this.pings$ = this.data.pings().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
