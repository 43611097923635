import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { MessageModel } from 'src/shared/data/models/message_model';
import { MessagesDataService } from 'src/portal/services/messages-data.service';
import { UserBadgesDataServices } from 'src/portal/services/userbadges-data.service';
import { BadgeModel } from 'src/shared/data/models/badge_model';

@Component({
  selector: 'app-latestbadges',
  templateUrl: './latestbadges.component.html'
})
export class LatestBadgesComponent implements OnInit {

  badges$: Observable<BadgeModel[]>;
  error: ErrorModel;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: UserBadgesDataServices,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.badges$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  // open(id: string) {
  //   this.router.navigate(["/postssupdate", id]);
  // }

}
