<app-wrapper header="Photos">
  <div *ngIf="!images$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="images$">
    <ng-container *ngIf="images$ | async as images ; else loadingOrError">
      <div *ngIf="!images || images.length == 0; else imagestable">
        <app-empty icon="edit" header="No images" description="New images will appear here">
        </app-empty>
      </div>
      <ng-template #imagestable>
        <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <li [routerLink]="['/imagesupdate',image.id]" *ngFor="let image of images" class="relative cursor-pointer">
            <div
              class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img [src]='imagesUrl + image?.image + "_640x640.jpeg?alt=media"' alt=""
                class="object-cover pointer-events-none group-hover:opacity-75">
              <button [routerLink]="['/imagesupdate',image.id]" type="button"
                class="absolute inset-0 focus:outline-none">
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{{image.caption ?? 'no
              caption'}}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">
              {{readableDate(image.creation_date.toDate())}}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{image?.numberOfLikes ?? 0}}
              likes</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{image?.numberOfComments ??
              0}} comments</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none"
              [ngSwitch]="image?.sourceDocumentImageType">
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOT">Spot Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOTIMAGE">Spot Image Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOTMAINPIC">Spot Main Pic</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.USERPROFILE">Profile Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOTPOST">Post Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOTCHAT">Spot Chat Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.BUSINESS">Business Photo</some-element>
              <some-element *ngSwitchCase="SourceDocumentType.BUSINESSTEAMMEMBER">Business Team Member Photo
              </some-element>
              <some-element *ngSwitchCase="SourceDocumentType.SWIMSPOTIMAGE">Spot Image Photo</some-element>
              <some-element *ngSwitchDefault>Unknown Type</some-element>
            </p>
          </li>
        </ul>

        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>