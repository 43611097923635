import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostStatus } from 'src/shared/data/enums/post_status';
import { PostModel } from 'src/shared/data/models/post_model';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class PostsDataService {

  collection: string = "posts";

  constructor(
    private firestore: FirestoreService,
    private crud: CrudService
  ) { }

  latest(): Observable<PostModel[]> {
    return this.firestore.getListByQuery("posts", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "in",
        property: "status",
        value: [PostStatus.POSTLIVE, PostStatus.LIVE]
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creationDate",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 30
      }
    ])
  }

  async create(data: PostModel) {
    await this.crud.create(this.collection, data);
  }

  read(id: string): Observable<PostModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as PostModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async update(data: PostModel) {
    await this.crud.update(this.collection, data);
  }

}
