import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { OrderModel } from 'src/shared/data/models/order_model';
import { OrdersDataService } from 'src/portal/services/orders-data.service';
import { OrderStatus } from 'src/shared/data/enums/order_status';
import { CurrencyService } from 'src/shared/services/currency/currency_service';
import { CheckoutStatus } from 'src/shared/data/enums/checkout_status';
import { BusinessNoteDTO } from 'src/shared/data/dtos/business/BusinessNoteDTO';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { CheckoutType } from 'src/shared/data/enums/checkout_type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-latestorders',
  templateUrl: './latestorders.component.html',
  styleUrls: ['./latestorders.component.scss']
})
export class LatestOrdersComponent implements OnInit {

  orders$: Observable<OrderModel[]>;
  error: ErrorModel;
  OrderStatus = OrderStatus;
  constructor(
    private router: Router,
    private notifyService: NotifyService,
    private currency: CurrencyService,
    private modal: ModalService,
    private dates: DatesService,
    private data: OrdersDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  getSymbol(order: OrderModel): string {
    return this.currency.getSymbol(order.currency);
  }
  getGrandTotal(order: OrderModel): string {
    return this.getSymbol(order) + this.currency.priceToReadable(order.grandtotal);
  }
  getSubTotal(order: OrderModel): string {
    return this.getSymbol(order) + this.currency.priceToReadable(order.total);
  }
  getFees(order: OrderModel): string {
    return this.getSymbol(order) + this.currency.priceToReadable(order.applicationFeeCapturedAmount ?? 0);
  }
  getRefund(order: OrderModel): string {
    return this.getSymbol(order) + this.currency.priceToReadable(order.totalRefund ?? 0);
  }
  getDiscount(order: OrderModel): string {
    return this.getSymbol(order) + this.currency.priceToReadable(order.discount ?? 0);
  }
  getCheckoutStatus(order: OrderModel): string {
    var status = "";
    switch (order.checkoutStatus) {
      case CheckoutStatus.UNKNOWN:
        status = "Unknown"
        break;
      case CheckoutStatus.PROCESSING:
        status = "Processing"
        break;
      case CheckoutStatus.REQUIRESACTION:
        status = "Requires Action"
        break;
      case CheckoutStatus.REQUIRESCONFIRMATION:
        status = "Requires Confirmation"
        break;
      case CheckoutStatus.REQUIRESPAYMENTMETHOD:
        status = "Requires Payment Method"
        break;
      case CheckoutStatus.SUCCEEDED:
        status = "Succeeded"
        break;
      case CheckoutStatus.CANCELLED:
        status = "Cancelled"
        break;
      default:
        break;
    }
    return status;
  }

  getStripeLink(order: OrderModel) {
    if (order.clientSecret != undefined) {
      var split = order.clientSecret.split("_");
      var id = split[0] + "_" + split[1];
      if (environment.production == true)
        return `https://dashboard.stripe.com/payments/${id}`
      return `https://dashboard.stripe.com/test/payments/${id}`
    }
    return "unknown";
  }
  getBusinessUrl(order: OrderModel) {
    return `${environment.domain}/businessoverride?setBusinessId=${order.businessId}`;
  }
  getFirestoreLink(order: OrderModel) {

    return `https://console.firebase.google.com/u/0/project/${environment.firebase.projectId}/firestore/data/orders/${order.id}`
  }
  getBusinessPublicUrl(order: OrderModel) {
    return `${environment.domain}/${order.businessURL}`;

  }

  getCheckoutType(order: OrderModel): string {
    var type = "";
    switch (order.checkouttype) {
      case CheckoutType.APP:
        type = "App"
        break;
      case CheckoutType.NORMAL:
        type = "Web / Normal"
        break;
      case CheckoutType.WIDGET:
        type = "Widget"
        break;
      default:
        type = "Unknown";
        break;
    }
    return type;
  }

  reprocess(orderId: string) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.ERROR,
      description: "Are you sure you want to reprocess?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processReprocess(orderId);
      }
    }
    this.modal.show(settings);
  }

  processReprocess(orderId: string) {
    this.data.reprocess(orderId)
      .then(async () => {
        this.notifyService.notify("Reprocessed", "Order has been reprocessed", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modal.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Reprocessing  Error", message, NotifyType.ERROR);
  }

  getStatusColor(status: OrderStatus): string {
    var color = "#DCDCDC";
    if (status == OrderStatus.SHOPPINGCART)
      color = "#ADD8E6";
    if (status == OrderStatus.EXPIRED)
      color = "#dd3524";
    if (status == OrderStatus.ABANDONCART)
      color = "#D1BB00";
    if (status == OrderStatus.FULLYREFUNDED)
      color = "#8D22DC";
    if (status == OrderStatus.PARTIALREFUND)
      color = "#FC9D02";
    if (status == OrderStatus.PAID)
      color = "#96bf65";
    if (status == OrderStatus.PROCESSING)
      color = "#bbc9a5";
    if (status == OrderStatus.SUCCESSFULPAYMENTDETECTEDFROMSTRIPE)
      color = "#800000";
    if (status == OrderStatus.REPROCESSORDER)
      color = "#079563";
    if (status == OrderStatus.PROCESSSFREEORDER)
      color = "rgb(52 210 255)";
    return color;
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.orders$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  open(order: OrderModel) {
    // this.router.navigate(["/swimspotsupdate", id]);
  }

}
