<app-wrapper *ngIf="swimSpotEdit && swimSpot" header="Swim Spot Edit ">
    <app-header text="Status"></app-header>
    <app-paragraph *ngIf="swimSpotEdit.status == SwimSpotEditStatus.SUGGESTION" text="Suggestion Waiting for Approval">
    </app-paragraph>
    <app-paragraph *ngIf="swimSpotEdit.status == SwimSpotEditStatus.APPROVED" text="Approved"></app-paragraph>
    <app-paragraph *ngIf="swimSpotEdit.status == SwimSpotEditStatus.REJECTED" text="Rejected"></app-paragraph>
    <app-paragraph *ngIf="swimSpotEdit.status == SwimSpotEditStatus.UNKNOWN" text="Unknown"></app-paragraph>
    <app-userdetails [swimspot]="swimSpotEdit.swimspot" [userId]="swimSpotEdit.createdBy"></app-userdetails>
    <app-header text="Comment"></app-header>
    <app-paragraph
        [text]="swimSpotEdit.comment == null || swimSpotEdit.comment == '' ? 'No comment' : swimSpotEdit.comment">
    </app-paragraph>
    <div class="p-0 border-0 bg-transparent sm:col-span-2">
        <div *ngIf="swimSpotEdit.status == SwimSpotEditStatus.SUGGESTION">
            <div class="my-3">
                <small class="text-red-500">This is waiting for a decision</small>
            </div>
            <div>
                <span class="relative z-0 inline-flex shadow-sm rounded-md">
                    <button (click)="approve()" type="button"
                        class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        Approve
                    </button>
                    <button (click)="reject()" type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        Reject
                    </button>
                </span>
            </div>
        </div>
    </div>
    <app-header text="Location"></app-header>
    <div *ngIf="mapsLoaded | async" class=" w-full my-4" style="height:350px">
        <google-map height="350" width="100%" [options]="options">
            <map-marker *ngFor="let markerPosition of markerPositionsOldLocation" [position]="markerPosition"
                [options]="markerOptionsOldLocation"></map-marker>
            <map-marker *ngFor="let markerPosition of markerPositionsNewLocation" [position]="markerPosition"
                [options]="markerOptionsNewLocation"></map-marker>
        </google-map>
    </div>
    <p><span class="text-red-500 font-bold">Current Location</span><span class="text-blue-600 font-bold ml-3">Suggested
            Location</span></p>
    <div class="flex mt-4">
        <div class="w-1/2 bg-red-50">
            <app-wrapper header="Current">
                <app-header text="Created By"></app-header>
                <app-paragraph [text]="swimSpot.createdBy"></app-paragraph>
                <app-swimspotdetails [swimSpot]="swimSpot"></app-swimspotdetails>
            </app-wrapper>
        </div>
        <div class="w-1/2 bg-blue-50">
            <app-wrapper header="Suggested">
                <app-header text="Created By"></app-header>
                <app-paragraph [text]="swimSpotEdit.createdBy"></app-paragraph>
                <app-swimspotdetails [swimSpot]="swimSpotEdit.swimspot"></app-swimspotdetails>
            </app-wrapper>
        </div>
    </div>
</app-wrapper>