import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class GarminActivityService {

  collection: string = "posts";

  constructor(
    private firestore: FirestoreService,
  ) { }

  pings(): Observable<any[]> {
    return this.firestore.getListByQuery("garminactivityping", [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "generatedDate",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 75
      }
    ])
  }
  latest(): Observable<any[]> {
    return this.firestore.getListByQuery("garminactivity", [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "generatedDate",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 75
      }
    ])
  }
}
