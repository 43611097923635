import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ContentDataService } from 'src/portal/services/contentdata.service';
import { ContentModel } from 'src/shared/data/models/content_model';
import { ErrorModel } from 'src/shared/data/models/error_model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  content$: Observable<ContentModel[]>;
  error: ErrorModel;

  constructor(
    private router: Router,
    private data: ContentDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    // this.content$ = this.data.list().pipe(
    //   take(1),
    //   catchError(err => {
    //     this.error = err;
    //     return throwError(err);
    //   })
    // );
  }

  open(id: string) {
    this.router.navigate(["/contentupdate", id]);
  }

}
