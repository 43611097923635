import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { FitBitDataService } from 'src/portal/services/fitbit-data.service';
import { FITBITRECORDTOPROCESSSTATUS } from 'src/shared/data/enums/fitbit_recordtoprocess_status';

@Component({
  selector: 'app-fitbitrecordtoprocess',
  templateUrl: './fitbitrecordtoprocess.component.html'
})
export class FitBitRecrodsToProcessComponent implements OnInit {

  notifications$: Observable<any[]>;
  error: ErrorModel;
  FITBITRECORDTOPROCESSSTATUS = FITBITRECORDTOPROCESSSTATUS;
  retrying: boolean;

  constructor(
    private dates: DatesService,
    private http: HttpService,
    private notify: NotifyService,
    private data: FitBitDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  // async retry(id: string): Promise<void> {
  //   if (this.retrying == true)
  //     return;
  //   var confirm = window.confirm("Are you sure you want to retry " + id);
  //   if (confirm == true) {
  //     this.retrying = true;
  //     this.http.post(`${environment.api}/portal/integrations/resendsuunto/${id}`, {}).toPromise().then(() => {
  //       this.notify.notify("SUCESSS - RELOADING", "", NotifyType.SUCCESS);
  //       window.location.href = window.location.href;
  //       this.retrying = false;
  //     }).catch((error) => {
  //       this.notify.notify("RETRY FAILED", error.message, NotifyType.ERROR)
  //     })
  //   }
  // }


  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  readableDateFromEpocy(epoch: number) {
    return this.dates.getFormattedDateTimeFromEpochTime(epoch);
  }

  getStatusColor(status: FITBITRECORDTOPROCESSSTATUS): string {
    var color = "#DCDCDC";
    if (status == FITBITRECORDTOPROCESSSTATUS.INSERTED)
      color = "#ADD8E6";
    if (status == FITBITRECORDTOPROCESSSTATUS.ERRORREFRESHINGTOKEN)
      color = "#dd3524";
    if (status == FITBITRECORDTOPROCESSSTATUS.ERRORGENERATINGPOLYLINEFROMTCX)
      color = "#D1BB00";
    if (status == FITBITRECORDTOPROCESSSTATUS.COULDNOTFINDWILDUSERFORTHISNOTIFICATION)
      color = "#8D22DC";
    if (status == FITBITRECORDTOPROCESSSTATUS.ERRORGENERATINGPOLYLINEMETADATA)
      color = "#FC9D02";
    if (status == FITBITRECORDTOPROCESSSTATUS.ERRORGETTINGACTIVITYDETAILS)
      color = "#7C0000";
    if (status == FITBITRECORDTOPROCESSSTATUS.PROCESSED)
      color = "#96bf65";
    if (status == FITBITRECORDTOPROCESSSTATUS.ERRORDOWNLOADINGTCXFILE)
      color = "#800000";
    if (status == FITBITRECORDTOPROCESSSTATUS.HASNOLOCATIONDATA)
      color = "#079563";
    return color;
  }

  load() {
    this.notifications$ = this.data.fitbitrecordtoprocess().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
