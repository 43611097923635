import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { FunctionsService } from 'src/portal/services/functions.service';
import { SwimSpotEditService } from 'src/portal/services/swimspotedit-data.service';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { SwimSpotEditStatus } from 'src/shared/data/enums/swimspot_edit_status';
import { SwimSpotEditModel } from 'src/shared/data/models/swimspot_edit_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';

@Component({
  selector: 'app-swimspotsedit',
  templateUrl: './swimspotsedit.component.html',
  styleUrls: ['./swimspotsedit.component.scss']
})
export class SwimspotseditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private swimSpotEditData: SwimSpotEditService,
    private swimSpotData: SwimspotsdataService,
    private functions: FunctionsService,
    private maps: MapsService
  ) {
    this.mapsLoaded = this.maps.loadMaps();
  }


  SwimSpotEditStatus = SwimSpotEditStatus;

  swimSpot: SwimSpot;
  swimSpotEdit: SwimSpotEditModel;
  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptionsOldLocation!: google.maps.MarkerOptions;
  markerOptionsNewLocation!: google.maps.MarkerOptions;
  markerPositionsOldLocation!: google.maps.LatLngLiteral[];
  markerPositionsNewLocation!: google.maps.LatLngLiteral[];

  async ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.swimSpotEditData.read(params.id).subscribe((data) => {
        this.swimSpotEdit = data;
        this.dataLoaded();
      }, (error) => {
        alert(error.message);
      });
    });
  }

  dataLoaded() {
    this.swimSpotData.read(this.swimSpotEdit.swimSpotId).subscribe((data) => {
      this.swimSpot = data;
      this.options = {
        center: {
          lat: Number.parseFloat(this.swimSpot.latitude),
          lng: Number.parseFloat(this.swimSpot.longitude)
        },
        zoom: 14,
      };
      this.markerOptionsOldLocation = { draggable: false, icon: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/mappins%2Fwaterway_pin_small.png?alt=media&token=ff24b78b-f439-41a7-9a50-032057415085" };
      this.markerPositionsOldLocation = [{
        lat: Number.parseFloat(this.swimSpot.latitude),
        lng: Number.parseFloat(this.swimSpot.longitude)
      }];
      this.markerOptionsNewLocation = { draggable: false, icon: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/mappins%2Fswimspot_pin_small.png?alt=media&token=acdd10db-ea5d-44dd-9391-5a5fd0f1378f" };
      this.markerPositionsNewLocation = [{
        lat: Number.parseFloat(this.swimSpotEdit.swimspot.latitude),
        lng: Number.parseFloat(this.swimSpotEdit.swimspot.longitude)
      }];

    }, (error) => {
      alert(error.message);
    });
  }

  approve() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to approve this change",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        await this.confirmApprove();
      }
    }
    this.modalService.show(settings);
  }

  async confirmApprove() {
    // await this.functions.approveSwimSpotEdit(this.swimSpotEdit.id).catch((error) => {
    //   alert(error.toString());
    // }).then(() => {
    //   window.location.href = window.location.href;
    // });
  };

  reject() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to reject this change",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        await this.confirmReject();
      }
    }
    this.modalService.show(settings);
  }

  async confirmReject() {
    await this.swimSpotEditData.markAsRejected(this.swimSpotEdit.id).catch((error) => {
      alert(error.toString());
    }).then(() => {
      window.location.href = window.location.href;
    });
  }
}
