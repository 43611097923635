import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';
import { ImageModel } from 'src/shared/data/models/images_model';
import { FileService } from 'src/shared/services/file/file_service';
import { FileUploadType } from 'src/shared/services/file/file_upload_type';
import { ImageForUpload } from 'src/shared/services/file/image_for_upload_model';
import { UploadStatus } from 'src/shared/services/file/upload_status';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { TabService } from 'src/shared/services/tab_service/tab_service';
import { UtilService } from 'src/shared/services/util/util_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';

@Component({
  selector: 'app-uploadimage',
  templateUrl: './uploadimage.component.html',
  styleUrls: ['./uploadimage.component.scss']
})
export class UploadimageComponent implements OnInit {

  @Input() maxfilesize: number = 5000000;
  maxfilesizeOnView: string = "5MB";
  @Input() limit: number = 5;
  @Input() sourceDocumentImageType: SourceDocumentType;
  @Input() sourceDocumentId: string;
  @Output() onComplete = new EventEmitter<ImageModel[]>();
  @Output() onUploaded = new EventEmitter<ImageModel[]>();
  @Input() uploadOnly: boolean;
  // image/gif, image/bmp
  //'image/gif', 'image/bmp'
  //'image/heic'
  accept: string = "image/jpg, image/jpeg, image/png, image/webp";
  acceptArr: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
  acceptOnView: string = "PNG, JPG, HEIC, WEBP"
  imagesForUpload: ImageForUpload[] = [];
  UploadStatus = UploadStatus;

  constructor(
    private modalService: ModalService,
    private fileService: FileService,
    private util: UtilService,
    private tabsService: TabService,
    private notify: NotifyService
  ) { }

  ngOnInit(): void {
  }

  loading: boolean;

  fileTooLarge(fileName: string) {
    this.notify.notify("File to large", fileName + " exceeded the 5mb limit", NotifyType.ERROR);
  }

  fileIsNotAnImage(fileName: string) {
    this.notify.notify("Images only", fileName + " is not a supported image type", NotifyType.ERROR);
  }

  noFilesDetected() {
    this.notify.notify("No files detected", "No files found for upload", NotifyType.INFO);
  }

  maxFilesReached() {
    this.notify.notify("Max files reached", "You can upload " + this.limit + " image(s) at a time", NotifyType.WARNING);
  }

  cancel() {
    if (this.loading)
      return;
    this.modalService.hide();
  }

  removeImage(index) {
    this.imagesForUpload.splice(index, 1);
  }

  onFileSelected(event) {
    var fileName = "File name here"// ;
    if (event?.target?.files == null || event?.target?.files == undefined || event?.target?.files.length == 0) {
      this.noFilesDetected();
      return;
    }
    if (event?.target?.files.length > this.limit) {
      var message = "";
      if (this.limit == 1) {
        message = "Please select only one file";
      } else {
        message = "Please select " + this.limit + " files or less";
      }
      this.notify.notify("Too many files", message, NotifyType.WARNING);
      return;
    }
    this.loadImagePreview(event.target.files);
  }

  async upload() {
    if (this.loading)
      return;
    this.loading = true;
    await this.fileService.uploadImages(FileUploadType.BUSINESSBANNERIMAGE, this.imagesForUpload, this.sourceDocumentId, this.sourceDocumentImageType)
      .then((imagemodels) => {
        this.next(imagemodels);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.reset();
      })
  }

  next(imagemodels: ImageModel[]) {
    this.notify.notify("Images Added", "Images uploaded successfully", NotifyType.SUCCESS);
    this.onUploaded.emit(imagemodels);
    if (this.uploadOnly) {
      this.onComplete.emit(imagemodels);
      this.modalService.hide();
    } else {
      this.tabsService.changeTab("filemanager", "media");
    }
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  reset() {
    this.loading = false;
  }

  cancelUpload() {
    this.imagesForUpload = [];
  }

  loadImagePreview(images) {
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      var size = image.size;
      var name = image.name;
      var type = image.type;
      if (this.imagesForUpload.length >= this.limit) {
        this.maxFilesReached();
        return;
      } else if (!this.isImageFile(type)) {
        this.fileIsNotAnImage(name);
      } else if (size > this.maxfilesize) {
        this.fileTooLarge(name);
      } else {
        var imageForUpload: ImageForUpload = {
          image: image,
          size: this.util.formatBytes(size),
          uploadstatus: UploadStatus.UNKNOWN,
          name: name ?? "",
          src: URL.createObjectURL(image)
        }
        this.imagesForUpload.push(imageForUpload);
      }
    }
  };


  isImageFile(type) {
    const acceptedImageTypes = this.acceptArr;
    return acceptedImageTypes.includes(type)
  }

}