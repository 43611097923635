import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { MessageModel } from 'src/shared/data/models/message_model';
import { BadgeModel } from 'src/shared/data/models/badge_model';

@Injectable({
  providedIn: 'root'
})
export class UserBadgesDataServices {


  constructor(
    private firestore: FirestoreService,
  ) { }

  latest(): Observable<BadgeModel[]> {
    return this.firestore.getCollectionGroupListByQuery("badges", [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "awardedDate",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 50
      }
    ])
  }

}
