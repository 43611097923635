import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwimSpotEditStatus } from 'src/shared/data/enums/swimspot_edit_status';
import { SwimSpotEditModel } from 'src/shared/data/models/swimspot_edit_model';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class SwimSpotEditService {

  collection: string = "swimspots_edit";

  constructor(
    private crud: CrudService,
    private firestore: FirestoreService
  ) { }

  read(id: string): Observable<SwimSpotEditModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as SwimSpotEditModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async markAsRejected(id: string) {
    this.firestore.update(id, this.collection, { status: SwimSpotEditStatus.REJECTED })
      .catch((error) => {
        this.crud.error(error);
      });
  }


}
