import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { AlertsDataService } from 'src/portal/services/alerts-data.service';
import { AlertModel } from 'src/shared/data/models/alert_model';

@Component({
  selector: 'app-latestalerts',
  templateUrl: './latestalerts.component.html'
})
export class LatestAlertsComponent implements OnInit {

  alerts$: Observable<AlertModel[]>;
  error: ErrorModel;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: AlertsDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.alerts$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  openspot(id: string) {
    this.router.navigate(["/swimspotsupdate", id]);
  }

}
