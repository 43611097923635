import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwimSpotEditStatus } from 'src/shared/data/enums/swimspot_edit_status';
import { SwimSpotStatus } from 'src/shared/data/enums/swimspot_status_enum';
import { SwimSpotEditModel } from 'src/shared/data/models/swimspot_edit_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class SwimspotsdataService {

  collection: string = "swimspots";

  constructor(
    private firestore: FirestoreService,
    private crud: CrudService,
  ) { }

  async create(data: SwimSpot) {
    await this.crud.create(this.collection, data);
  }

  inReview(): Observable<SwimSpot[]> {
    return this.firestore.getListByQuery("swimspots", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: SwimSpotStatus.INREVIEW
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

  imported(): Observable<SwimSpot[]> {
    return this.firestore.getListByQuery("swimspots", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: SwimSpotStatus.IMPORTED
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }
  mapimages(): Observable<SwimSpot[]> {
    return this.firestore.getListByQuery("swimspots", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: SwimSpotStatus.LIVE
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: ">",
        property: "numberOfPhotos",
        value: 1
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "mapimage",
        value: true
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "mapimage",
        value: true
      }
    ])
  }

  latestLiveSpots(): Observable<SwimSpot[]> {
    return this.firestore.getListByQuery("swimspots", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: SwimSpotStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 20
      }
    ])
  }

  edits(): Observable<SwimSpotEditModel[]> {
    return this.firestore.getListByQuery("swimspots_edit", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: SwimSpotEditStatus.SUGGESTION
      }
    ])
  }

  read(id: string): Observable<SwimSpot> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as SwimSpot;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async update(data: SwimSpot) {
    await this.crud.update(this.collection, data);
  }

}
