<app-wrapper header="Content">
    <div *ngIf="!content$">
        <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div *ngIf="content$">
        <ng-container *ngIf="content$ | async as content ; else loadingOrError">
            <div *ngIf="!content || content.length == 0; else contenttable">
                <app-empty icon="user-alt" header="No Content" description="Add content so it appears in the list">
                </app-empty>
            </div>
            <ng-template #contenttable>
                <app-table [headers]="[{
                    header: 'Title'
                  }, {
                    header: 'Description'
                  }, {
                    header: ''
                  }]">
                    <tr *ngFor="let record of content">
                        <td app-table-cell [value]="record.title"></td>
                        <td app-table-cell [value]="record.description"></td>
                        <td app-table-cell [cta]="true" (onClick)="open(record.id)" value="edit"></td>
                    </tr>
                </app-table>
                <br />
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>