<app-wrapper *ngIf="image" header="Image Update">
  <div class="my-6">
    <app-header text="Image"></app-header>
    <img [src]="src" />
  </div>
  <div class="my-6">
    <app-header text="Source Document"></app-header>
    <small class="underline cursor-pointer" [routerLink]="['/swimspotsupdate',image.sourceDocumentId]"
      *ngIf="image.sourceDocumentImageType == SourceDocuementType.SWIMSPOT">Swim Spot</small>
    <small class="underline cursor-pointer" [routerLink]="['/waterwayssupdate',image.sourceDocumentId]"
      *ngIf="image.sourceDocumentImageType == SourceDocuementType.WATERWAY">Waterway</small>
    <small class="underline cursor-pointer" [routerLink]="['/swimspotsupdate',image.sourceDocumentId]"
      *ngIf="image.sourceDocumentImageType == SourceDocuementType.SWIMSPOTMAINPIC">Swim Spot Main Pic</small>
    <small class="underline cursor-pointer" [routerLink]="['/userprofilesupdate',image.sourceDocumentId]"
      *ngIf="image.sourceDocumentImageType == SourceDocuementType.USERPROFILE">User Profile</small>
    <small class="underline cursor-pointer" [routerLink]="['/waterwayssupdate',image.sourceDocumentId]"
      *ngIf="image.sourceDocumentImageType == SourceDocuementType.WATERWAYMAINPIC">Waterway Main pic</small>
    <small *ngIf="image.sourceDocumentImageType == SourceDocuementType.UNKNOWN">Unknown</small>
  </div>
  <div class="my-6">
    <app-header text="Source Document Parent Type"></app-header>
    <small class="underline cursor-pointer" [routerLink]="['/swimspotsupdate',image.sourceDocumentParentId]"
      *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.SWIMSPOT">Swim Spot</small>
    <small class="underline cursor-pointer" [routerLink]="['/waterwaysupdate',image.sourceDocumentParentId]"
      *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.WATERWAY">Waterway</small>
    <small class="underline cursor-pointer" [routerLink]="['/swimspotsupdate',image.sourceDocumentParentId]"
      *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.SWIMSPOTMAINPIC">Swim Spot Main Pic</small>
    <small class="underline cursor-pointer" [routerLink]="['/userprofilesupdate',image.sourceDocumentParentId]"
      *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.USERPROFILE">User Profile</small>
    <small class="underline cursor-pointer" [routerLink]="['/waterwaysupdate',image.sourceDocumentParentId]"
      *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.WATERWAYMAINPIC">Waterway Main pic</small>
    <small *ngIf="image.sourceDocumentImageParentType == SourceDocuementType.UNKNOWN">Unknown</small>
  </div>
  <div class="my-6">
    <app-header text="Post to community"></app-header>
    <div *ngIf="!postType">
      <small class="text-red-500">No post type set for this image. Unable to check posts or post it to community. THIS
        IS PROBABLY INTENTIONAL </small>
    </div>
  </div>
  <app-form [form]="form" (onSubmit)="update($event)">
    <app-inputgroup>
      <app-select [options]="statusOptions" [form]="form" name="status" description="Status" [span]=3></app-select>
      <app-select [options]="sourceDocumentImageTypeOptions" [form]="form" name="sourceDocumentImageType"
        description="Source Document Image Type" [span]=3></app-select>
    </app-inputgroup>
    <app-inputgroup>
      <app-input [form]="form" name="createdBy" description="Created By" [span]=6>
      </app-input>
    </app-inputgroup>
    <app-inputgroup>
      <app-input [form]="form" name="sourceDocumentId" description="Source Document Id" [span]=6>
      </app-input>
    </app-inputgroup>
    <app-inputgroup>
      <app-textarea [form]="form" name="caption" description="Caption" [span]=6>
      </app-textarea>
    </app-inputgroup>
  </app-form>
</app-wrapper>