import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { BadgeConfigModel } from 'src/shared/data/models/badge_config';
import { BadgeConfigService } from 'src/portal/services/badgeConfig_service.service';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';

@Component({
  selector: 'app-badgeconfiglist',
  templateUrl: './badgeconfiglist.component.html',
})
export class BadgeConfigListComponent implements OnInit {

  configs$: Observable<BadgeConfigModel[]>;
  error: ErrorModel;
  WaterwayType = WaterwayType;
  SourceDocumentType = SourceDocumentType;

  constructor(
    private router: Router,
    private data: BadgeConfigService,) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.configs$ = this.data.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  async new() {
    const confirm = window.confirm("Create New?")
    if (confirm == true) {
      const id = await this.data.new();
      this.open(id);
    }
  }

  open(id: string) {
    this.router.navigate(["/badgeconfig", id]);
  }

}
