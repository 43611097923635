import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  selector: 'app-imported',
  templateUrl: './imported.component.html'
})
export class ImportedComponent implements OnInit {

  swimspots$: Observable<SwimSpot[]>;
  error: ErrorModel;
  WaterwayType = WaterwayType;
  imagesUrl = environment.imagesUrl;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: SwimspotsdataService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.swimspots$ = this.data.imported().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  open(id: string) {
    this.router.navigate(["/swimspotsupdate", id]);
  }

}
