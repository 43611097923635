import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchResultType } from 'src/shared/components/searchresults/SearchResultType';
import { BusinessMode } from 'src/shared/data/enums/business_mode';
import { BusinessStatus } from 'src/shared/data/enums/business_status';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { LocationResultType } from 'src/shared/data/enums/location_result_type';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { SearchResult } from 'src/shared/data/models/SearchResult';
import { SearchResults } from 'src/shared/data/models/SearchResults';
import { SearchResultsHome } from 'src/shared/data/models/SearchResultsHome';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class userSearchService {

    constructor(
        private firestore: FirestoreService,
        private http: HttpService) { }

    global(query: string): Observable<SearchResults> {
        return this.http.get(`${environment.api}/user/search/global?query=${query}`);
    }

    home(): Observable<SearchResultsHome> {
        return this.http.get(`${environment.api}/user/search/home?limit=8`);
    }

    nearTo(latitude: string, longitude: string, swimSpotId: string): Observable<SearchResults> {
        var url = `${environment.api}/user/search/dupecheck?latitude=${latitude}&longitude=${longitude}`;
        return this.http.get(url).pipe(
            map((searchResults: SearchResults) => {
                searchResults.results = searchResults.results.filter((result) => result.id != swimSpotId)
                return searchResults;
            })
        );
    }

    nearby(query: string, latitude: string, longitude: string, filtersString: string, limit: number = null): Observable<SearchResults> {
        var url = `${environment.api}/user/search/nearby?q=${query}&latitude=${latitude}&longitude=${longitude}${filtersString}`;
        if (limit != null)
            url += `&limit=${limit}`
        return this.http.get(url);
    }

    swimspotBusinesses(swimspotId: string): Observable<SearchResult[]> {
        return this.firestore.getListByQuery("business_public", [
            {
                operator: "==",
                property: "status",
                type: FirestoreQueryType.WHERE,
                value: BusinessStatus.LIVE
            },
            {
                operator: "",
                property: "swimspots",
                type: FirestoreQueryType.ARRAYCONTAINS,
                value: swimspotId
            }
        ]).pipe(
            map((businesPublic: BusinessPublicModel[]) => {
                var results: SearchResult[] = [];
                for (let i = 0; i < businesPublic.length; i++) {
                    const biz = businesPublic[i];
                    results.push({
                        area: biz?.location?.area,
                        bookwithwild: biz.mode == BusinessMode.SYSTEM,
                        canonical: biz.url,
                        country: biz?.location?.country,
                        countryCode: biz?.location?.countryCode,
                        id: biz.id,
                        image: biz.uploadedprofilepic?.image ?? "",
                        latitude: biz.latitude,
                        longitude: biz.longitude,
                        name: biz.name,
                        region: "",
                        reviewaverage: "",
                        swimSpotType: WaterwayType.UNKNOWN,
                        swimSpotTypeDescription: "",
                        type: LocationResultType.BUSINESS,
                    })
                }
                return results;
            })
        )
    }

}

