export enum SuuntoNotificationStatus {
    UNKNOWN,
    INSERTED,
    PROCESSEDWITHOUTWATERBASEDACTIVITIES,
    PROCESSWITHWATERBASEDACTIVITIES,
    COULDNOTFINDWILDUSERFORTHISNOTIFICATION,
    ERRORREFRESHINGTOKEN,
    ERRORDOWNLOADINGFITFILE,
    ERRORGETTINGWORKOUTDATA,
    UNKNOWNERROR,
    INSERTEDRETRY
}