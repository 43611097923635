<app-wrapper header="Map Image Spots to Update">
  <div *ngIf="!swimspots$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="swimspots$">
    <ng-container *ngIf="swimspots$ | async as swimspots ; else loadingOrError">
      <div *ngIf="!swimspots || swimspots.length == 0; else swimspotstable">
        <app-empty icon="swimmer" header="No SwimSpots" description="Unverified swim spots will appear here">
        </app-empty>
      </div>
      <ng-template #swimspotstable>
        <app-table [headers]="[{
                    header: 'Image'
                  },{
                    header: 'Created'
                  },{
                    header: 'Name'
                  },{
                    header: 'Type'
                  },{
                    header: 'Notes'
                  },{
                    header: 'UserId'
                  }, {
                    header: ''
                  }]">
          <tr *ngFor="let swimspot of swimspots">
            <td class="cursor-pointer" (click)="open(swimspot.id)">
              <img class="h-32 w-32 rounded p-2"
                [src]="swimspot?.image != null ? imagesUrl + swimspot?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'" />
            </td>
            <td app-table-cell [value]="readableDate(swimspot.creation_date.toDate())"></td>
            <td app-table-cell [value]="swimspot.name"></td>
            <td *ngIf="swimspot.type == WaterwayType.LAKE" app-table-cell value="Lake"></td>
            <td *ngIf="swimspot.type == WaterwayType.COASTAL" app-table-cell value="Coastal"></td>
            <td *ngIf="swimspot.type == WaterwayType.RIVER" app-table-cell value="River"></td>
            <td *ngIf="swimspot.type == WaterwayType.UNKNOWN" app-table-cell value="lake"></td>
            <td app-table-cell [value]="swimspot.notes"></td>
            <td app-table-cell [value]="swimspot.createdBy"></td>
            <td app-table-cell [cta]="true" (onClick)="open(swimspot.id)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>