export enum JourneyActivityType {
    UNKNOWN,
    OUTDOORSWIM,
    POOLSWIM,
    BOATING,
    ROWING,
    SAILING,
    SUP,
    SURFING,
    WAKEBOARDING,
    WHITEWATERRAFTING,
    KITESURFING,
    DIVING,
    PADDLINGKAYAKCANOE,
    RUNNING, //Used for testing
    WALKING, //Used for testing
    DIPPING,
    COLDWATERIMMERSION
}