import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContentDataService } from 'src/portal/services/contentdata.service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { ContentProvider } from 'src/shared/data/enums/content_provider';
import { ContentStatus } from 'src/shared/data/enums/content_status';
import { SectionType } from 'src/shared/data/enums/section_type';
import { ContentModel } from 'src/shared/data/models/content_model';

@Component({
  selector: 'app-contentupdate',
  templateUrl: './contentupdate.component.html',
  styleUrls: ['./contentupdate.component.scss']
})
export class ContentupdateComponent implements OnInit {

  newRecord: boolean = false;
  form!: UntypedFormGroup;
  tagsForm: UntypedFormGroup = this.fb.group({
    tag: new UntypedFormControl([], [])
  });
  content!: ContentModel;
  statusOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: ContentStatus.UNKNOWN
    },
    {
      name: "Draft",
      value: ContentStatus.DRAFT
    },
    {
      name: "Live",
      value: ContentStatus.LIVE
    },
    {
      name: "Deleted",
      value: ContentStatus.DELETED
    }
  ]
  sectionOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: SectionType.UNKNOWN
    },
    {
      name: "Header",
      value: SectionType.HEADER
    },
    {
      name: "Paragraph",
      value: SectionType.PARAGRAPH
    },
    {
      name: "Image",
      value: SectionType.IMAGE
    },
    {
      name: "Video",
      value: SectionType.VIDEO
    },
    {
      name: "Logo CTA",
      value: SectionType.LOGOCTA
    },
    {
      name: "YouTube",
      value: SectionType.YOUTUBE
    },
  ]
  provideOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: ContentProvider.UNKNOWN
    },
    {
      name: "RNLI",
      value: ContentProvider.RNLI
    },
    {
      name: "Ocean Generation",
      value: ContentProvider.OCEANGENERATION
    },
    {
      name: "Marine Conservation Society",
      value: ContentProvider.MARINECONSERVATIONSOCIETY
    },
    {
      name: "Wild",
      value: ContentProvider.WILD
    },
    {
      name: "Straight Line Swimming",
      value: ContentProvider.SLS
    },
  ]

  constructor(
    private route: ActivatedRoute,
    private data: ContentDataService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (!params.id)
        this.newRecord = true;
      this.data.read(params.id).subscribe((data) => {
        this.content = data;
        this.dataLoaded();
      }, (error) => {
        alert(error.message);
      });
    });

  }
  dataLoaded() {
    this.form = this.fb.group({
      title: new UntypedFormControl(this.content?.title ?? "", [Validators.required]),
      description: new UntypedFormControl(this.content?.description ?? "", [Validators.required]),
      image: new UntypedFormControl(this.content?.image ?? "", [Validators.required]),
      ctalink: new UntypedFormControl(this.content?.ctalink ?? "", []),
      ctacopy: new UntypedFormControl(this.content?.ctacopy ?? "", []),
      status: new UntypedFormControl(this.content?.status ?? ContentStatus.UNKNOWN, [Validators.required]),
      provider: new UntypedFormControl(this.content?.provider ?? ContentProvider.UNKNOWN, [Validators.required]),
      sections: this.fb.array([]),
      tags: new UntypedFormControl(this.content?.tags ?? [], []),
    });
    if (this.content.sections && this.content.sections.length > 0) {
      this.content.sections.forEach(element => {
        this.addSection(element.type, element.content);
      });
    }
  }
  get sections() {
    return this.form.get('sections') as UntypedFormArray;
  }

  addSection(type: SectionType, content: string, index: number = null) {
    var formGroup = this.fb.group({
      type: new UntypedFormControl(type, [
        Validators.required
      ]), content: new UntypedFormControl(content, [
        Validators.required
      ])
    });
    if (index == null) {
      this.sections.push(formGroup);
    } else {
      this.sections.insert(index + 1, formGroup);
    }
  }

  moveSection(index: number, up: boolean) {
    if (index == 0 && up == true) {
      alert("Already at top")
      return
    }
    var section = this.sections.at(index);
    this.removeSection(index);
    if (up) {
      this.sections.insert(index - 1, section);
    } else {
      this.sections.insert(index + 1, section);
    }
  }

  removeSection(index) {
    this.sections.removeAt(index);
  }

  addTag(data: any) {
    let tags = <UntypedFormArray>this.form.controls.tags;
    let tagValue = tags.value;
    if (!tagValue)
      tagValue = [];
    tagValue.push(data.tag);
    this.form.controls.tags.setValue(tagValue);
    this.tagsForm.controls.tag.setValue("");
  }

  removeTag(removeValue: string) {
    let tagValue = this.form.controls.tags.value;
    if (tagValue.length > 0) {
      let indexOf = tagValue.indexOf(removeValue);
      tagValue.splice(indexOf, 1);

      this.form.controls.tags.setValue(tagValue);
    }
  }

  async update(data: any) {
    const content = {
      ...this.content,
      ...data,
    }
    await this.data
      .update(content)
      .then(() => {
        alert("Updated")
        if (this.newRecord)
          this.router.navigate(['./contentupdate', this.content.id])
      })
      .catch((error: any) => {
        this.handlerError(error.message);
      })
  }

  handlerError(message: string) {
    alert(message);
  }

}
