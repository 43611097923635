<app-wrapper header="Messages">
  <div *ngIf="!messages$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="messages$">
    <ng-container *ngIf="messages$ | async as messages ; else loadingOrError">
      <div *ngIf="!messages || messages.length == 0; else messagestable">
        <app-empty icon="edit" header="No messages" description="New messages will appear here">
        </app-empty>
      </div>
      <ng-template #messagestable>
        <app-table [headers]="[{
                    header: 'Created'
                  },{
                    header: 'Message'
                  },{
                    header: 'Body'
                  },{
                    header: 'userId'
                  },{
                    header: 'createdBy'
                  },{
                    header: 'Email'
                  },{
                    header: 'Push'
                  }, {
                    header: ''
                  }]">
          <tr *ngFor="let message of messages">
            <td app-table-cell [value]="readableDate(message?.creation_date?.toDate())"></td>
            <td app-table-cell [value]="message.message"></td>
            <td app-table-cell [value]="message.body"></td>
            <td app-table-cell [value]="message.userId"></td>
            <td app-table-cell [value]="message.createdBy"></td>
            <td app-table-cell [value]="message.email == true ? 'yes': 'no'"></td>
            <td app-table-cell [value]="message.push == true ? 'yes': 'no'"></td>
            <td app-table-cell [cta]="true" (onClick)="open(message.id)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>