import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { PostsDataService } from 'src/portal/services/posts-data.service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { PostStatus } from 'src/shared/data/enums/post_status';
import { PostModel } from 'src/shared/data/models/post_model';

@Component({
  selector: 'app-postsupdate',
  templateUrl: './postsupdate.component.html',
  styleUrls: ['./postsupdate.component.scss']
})
export class PostsupdateComponent implements OnInit {

  form!: UntypedFormGroup;
  post!: PostModel;
  statusOptions: SelectModel[] = [
    {
      name: "Live",
      value: PostStatus.LIVE
    },
    {
      name: "Discarded",
      value: PostStatus.DISCARDED
    },
    {
      name: "Abuse",
      value: PostStatus.ABUSE
    },
    {
      name: "Unknown",
      value: PostStatus.UNKNOWN
    },
  ]

  constructor(
    private route: ActivatedRoute,
    private data: PostsDataService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.data.read(params.id).subscribe((data) => {
        this.post = data;
        this.dataLoaded();
      }, (error) => {
        alert(error.message);
      });
    });

  }
  dataLoaded() {
    this.form = this.fb.group({
      message: new UntypedFormControl(this.post?.message ?? "", []),
      status: new UntypedFormControl(this.post?.status ?? PostStatus.UNKNOWN, [Validators.required]),
    });
  }

  async update(data: any) {
    const post = {
      ...this.post,
      ...data,
    }
    if (this.post) {
      await this.data
        .update(post)
        .then(() => {
          alert("Updated")
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    } else {
      await this.data
        .create(post)
        .then(() => {
          alert("Added")
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    }
  }

  handlerError(message: string) {
    alert(message);
  }

}
