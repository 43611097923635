import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BadgeConfigComponent } from './pages/badgeconfig/badgeconfig.component';
import { BadgeConfigListComponent } from './pages/badgeconfiglist/badgeconfiglist.component';
import { ContentComponent } from './pages/content/content.component';
import { ContentupdateComponent } from './pages/contentupdate/contentupdate.component';
import { EditsComponent } from './pages/edits/edits.component';
import { FitBitNotificationsComponent } from './pages/fitbitnotification/fitbitnotification.component';
import { FitBitRecrodsToProcessComponent } from './pages/fitbitrecordtoprocess/fitbitrecordtoprocess.component';
import { GarminActivitiesComponent } from './pages/garminactivities/garminactivities.component';
import { GarminActivityPingsComponent } from './pages/garminactivityping/garminactivityping.component';
import { HomeComponent } from './pages/home/home.component';
import { ImagesupdateComponent } from './pages/imagesupdate/imagesupdate.component';
import { ImportedComponent } from './pages/imported/imported.component';
import { InReviewComponent } from './pages/inreview/inreview.component';
import { LatestAlertsComponent } from './pages/latestalerts/latestalerts.component';
import { LatestBadgesComponent } from './pages/latestbadges/latestbadges.component';
import { LatestMessagesComponent } from './pages/latestmessages/latestmessages.component';
import { LatestOrdersComponent } from './pages/latestorders/latestorders.component';
import { LatestPhotosComponent } from './pages/latestphotos/latestphotos.component';
import { LatestPostsComponent } from './pages/latestposts/latestposts.component';
import { LatestReviewsComponent } from './pages/latestreviews/latestreviews.component';
import { LatestSpotsComponent } from './pages/latestspots/latestspots.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MapImagesWithNewPhotosComponent } from './pages/mapimageswithnewphotos/mapimageswithnewphotos.component';
import { PostsupdateComponent } from './pages/postsupdate/postsupdate.component';
import { SuuntoNotificationsComponent } from './pages/suuntonotifications/suuntonotifications.component';
import { SwimspotseditComponent } from './pages/swimspotsedit/swimspotsedit.component';
import { SwimspotsupdateComponent } from './pages/swimspotsupdate/swimspotsupdate.component';
import { UserprofilesupdateComponent } from './pages/userprofilesupdate/userprofilesupdate.component';
import { UsersupdateComponent } from './pages/usersupdate/usersupdate.component';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [{
  path: "",
  component: HomeComponent
},
{
  path: "logout",
  component: LogoutComponent
},
{
  path: "postssupdate",
  component: PostsupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "suuntonotifications",
  component: SuuntoNotificationsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "fitbitnotifications",
  component: FitBitNotificationsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "fitbitrecordtoprocess",
  component: FitBitRecrodsToProcessComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "garminactivitypings",
  component: GarminActivityPingsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "garminactivities",
  component: GarminActivitiesComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "postssupdate/:id",
  component: PostsupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestposts",
  component: LatestPostsComponent,
  resolve: {
    user: UserResolver
  }
},
{
  path: "latestorders",
  component: LatestOrdersComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestspots",
  component: LatestSpotsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestalerts",
  component: LatestAlertsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestphotos",
  component: LatestPhotosComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "badgeconfig/:id",
  component: BadgeConfigComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "badgeconfig",
  component: BadgeConfigListComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestmessages",
  component: LatestMessagesComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestbadges",
  component: LatestBadgesComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "latestreviews",
  component: LatestReviewsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "edits",
  component: EditsComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "usersupdate",
  component: UsersupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "usersupdate/:id",
  resolve: {
    user: UserResolver
  },
  component: UsersupdateComponent,
},
{
  path: "content",
  component: ContentComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "contentupdate",
  component: ContentupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "contentupdate/:id",
  resolve: {
    user: UserResolver
  },
  component: ContentupdateComponent,
},
{
  path: "userprofilesupdate",
  component: UserprofilesupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "userprofilesupdate/:id",
  resolve: {
    user: UserResolver
  },
  component: UserprofilesupdateComponent,
},
{
  path: "imagesupdate",
  component: ImagesupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "imagesupdate/:id",
  resolve: {
    user: UserResolver
  },
  component: ImagesupdateComponent,
},
{
  path: "inreview",
  component: InReviewComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "imported",
  component: ImportedComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "mapimages",
  component: MapImagesWithNewPhotosComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "swimspotsupdate",
  component: SwimspotsupdateComponent,
  resolve: {
    user: UserResolver
  },
},
{
  path: "swimspotsupdate/:id",
  resolve: {
    user: UserResolver
  },
  component: SwimspotsupdateComponent
}, {
  path: "swimspotsedit/:id",
  resolve: {
    user: UserResolver
  },
  component: SwimspotseditComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
