<app-wrapper header="Garmin pings">
  <div class="flex flex-wrap">
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.UNKNOWN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Unknown Status</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.INSERTED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>INSERTED</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.FAILED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>FAILED</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.COULDNOTFINDWILDUSERFORTHISPING)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Could not find Wild user for Ping</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.ERRORFETCHINGACTIVITYDATA)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>ERRORFETCHINGACTIVITYDATA</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.PROCESSWITHWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed and IT IS water based</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.PROCESSEDWITHOUTWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed but it's NOT water based</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminPingStatus.INSERTEDRETRY)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>INSERTED RETRY</p>
    </div>
  </div>
  <div *ngIf="!pings$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="pings$">
    <ng-container *ngIf="pings$ | async as pings ; else loadingOrError">
      <div *ngIf="!pings || pings.length == 0; else pingstable">
        <app-empty icon="edit" header="No pings" description="New pings will appear here">
        </app-empty>
      </div>
      <ng-template #pingstable>
        <app-table [headers]="[{
                    header: 'Generated Date'
                  },{
                    header: 'User Access Token'
                  },{
                    header: 'Retry'
                  },
                  {
                    header: ''
                  },{
                    header: 'uploadStartTimeInSeconds'
                  },{
                    header: 'uploadEndTimeInSeconds'
                  },{
                    header: 'WILD PING ID'
                  },{
                    header: 'status'
                  },{
                    header: 'callbackURL'
                  }]">
          <tr [ngStyle]="{'background-color': getStatusColor(ping.status)}" *ngFor="let ping of pings">
            <td app-table-cell [value]="readableDate(ping?.generatedDate?.toDate())"></td>
            <td app-table-cell [value]="ping.userAccessToken"></td>
            <td app-table-cell [value]="ping.retry == true ? 'yes':'no'"></td>
            <td><button class="wild-btn-primary" (click)="retry(ping.id)">
                <span *ngIf="retrying">loading...</span>
                <span *ngIf="!retrying">retry</span>
              </button></td>
            <td app-table-cell [value]="readableDateFromEpocy(ping.uploadStartTimeInSeconds)"></td>
            <td app-table-cell [value]="readableDateFromEpocy(ping.uploadEndTimeInSeconds)"></td>
            <td app-table-cell [value]="ping.id ?? 'not found'"></td>
            <td app-table-cell [value]="ping.status ?? 'not found'"></td>
            <td app-table-cell [value]="ping.callbackURL"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>