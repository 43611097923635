import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocationResultType } from 'src/shared/data/enums/location_result_type';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { SearchResult } from 'src/shared/data/models/SearchResult';
import { SearchResults } from 'src/shared/data/models/SearchResults';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SwimSpotSearchService } from '../../wild/modules/business/components/services/swimspotsearch_service';

@Component({
  template: `
  <app-modaltopper [header]="'Find Swim Spot'"></app-modaltopper>
  <app-form [hidebuttons]="true" [form]="form" [fullwidthbutton]="false" disableDivide="true">
      <app-inputgroup>
        <app-input [disableAutoComplete]="true" [form]="form" type="search" name="search" description="Search" [span]=6 placeholder="Spot Name or Location"></app-input>
      </app-inputgroup>
  </app-form> 
  <div *ngIf="results$">
    <ng-container *ngIf="results$ | async as results; else loadingOrError">
      <div *ngIf="loading">
        <app-loader [disablescreenheight]="true"></app-loader>
      </div> 
      <div *ngIf="!loading">
        <div *ngIf="(!results || results?.results?.length == 0); else showresults">
          <app-empty icon="water" header="No Results"
            description="Please refine your search">
          </app-empty>
        </div>
        <ng-template #showresults>
          <app-empty *ngIf="results?.results?.length == 0" icon="water" header="No Results" 
            description="Please refine your search"> 
          </app-empty>
          <ul *ngIf="results?.results?.length > 0" 
              class="max-h-96 scroll-py-3 overflow-y-auto py-2 " id="options" role="listbox">
              <li (click)="select(result)" *ngFor="let result of results?.results"
                  class="group flex cursor-pointer hover:bg-gray-50 select-none rounded-xl py-3"
                  id="option-1" role="option" tabindex="-1">
                  <div *ngIf="result.type == LocationResultType.PLACE"
                      class="flex h-10 w-10 flex-none items-center justify-center rounded-lg wild-bg-primary text-white">
                      <fa-icon icon="map-pin">
                      </fa-icon>
                  </div>
                  <div *ngIf="result.swimSpotType == WaterwayType.LAKE || result.swimSpotType == WaterwayType.COASTAL || result.swimSpotType == WaterwayType.RIVER || result.type ==  LocationResultType.BUSINESS"
                      class="flex h-10 w-10 flex-none items-center justify-center rounded-lg wild-bg-primary text-white overflow-hidden">
                      <img [src]="result?.image != null && result?.image != '' ? imagesUrl + result.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                          alt="" class="object-cover pointer-events-none group-hover:opacity-75">
                  </div>
                  <div class="ml-4 flex-auto">
                      <p class="text-sm font-medium text-gray-700">{{result.name}}</p>
                      <p class="text-sm text-gray-500">
                          <span
                              *ngIf="result.type == LocationResultType.PLACE">{{result.locationDescription}}</span>
                          <span *ngIf="result.swimSpotType == WaterwayType.LAKE"> Lake in
                              {{result.locationDescription}}</span>
                          <span *ngIf="result.swimSpotType == WaterwayType.COASTAL">Beach in
                              {{result.locationDescription}}</span>
                          <span *ngIf="result.swimSpotType == WaterwayType.RIVER">River in
                              {{result.locationDescription}}</span>
                      </p>
                  </div>
              </li>
          </ul>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `
})
export class SwimSpotSearchComponent implements OnInit {

  @Output() swimSpotId = new EventEmitter<string>();
  imagesUrl = environment.imagesUrl;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;

  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SwimSpotSearchService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  results$: Observable<SearchResults>;
  error: ErrorModel;

  ngOnInit(): void {
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value?.search);
      });
    }, 1000);
  }

  select(result: SearchResult) {
    this.swimSpotId.emit(result.id);
  }

  search(search: string) {
    if (search == "" || search == undefined)
      return;
    this.results$ = null;
    this.results$ = this.searchService.search(search).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  handlerError(message: string) {
    this.notifyService.notify("Update", message, NotifyType.ERROR);
  }

  hide() {
    this.modalService.hide();
  }

}
