<app-wrapper header="Suunto Notifications">
  <div class="flex flex-wrap">
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.UNKNOWN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Unknown Status</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.INSERTED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>INSERTED</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.ERRORREFRESHINGTOKEN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>ERROR REFRESHING TOKEN</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.UNKNOWNERROR)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>UNKNOWN ERROR</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.ERRORDOWNLOADINGFITFILE)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>ERROR DOWNLOADING FIT FILE</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.ERRORGETTINGWORKOUTDATA)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>ERROR DOWNLOADING WORKOUT DATA</p>
    </div>
    <div
      [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.COULDNOTFINDWILDUSERFORTHISNOTIFICATION)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Could not find Wild user for Ping</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.PROCESSWITHWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed and IT IS water based</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.PROCESSEDWITHOUTWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed but it's NOT water based</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(SuuntoNotificationStatus.INSERTEDRETRY)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>INSERTED RETRY</p>
    </div>
  </div>
  <div *ngIf="!notifications$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="notifications$">
    <ng-container *ngIf="notifications$ | async as notifications ; else loadingOrError">
      <div *ngIf="!notifications || notifications.length == 0; else notificationstable">
        <app-empty icon="edit" header="No notifications" description="New notifications will appear here">
        </app-empty>
      </div>
      <ng-template #notificationstable>
        <app-table [headers]="[{
                    header: 'Creation Date'
                  },{
                    header: 'Wild Record ID'
                  },{
                    header: 'Retry'
                  },
                  {
                    header: ''
                  },{
                    header: 'Suunto Username'
                  },{
                    header: 'Suunto workoutid'
                  },{
                    header: 'FIT sport'
                  },{
                    header: 'FIT sub_sport'
                  },{
                    header: 'email'
                  },{
                    header: 'userId'
                  }]">
          <tr [ngStyle]="{'background-color': getStatusColor(notification.status)}"
            *ngFor="let notification of notifications">
            <td app-table-cell [value]="readableDate(notification?.date?.toDate())"></td>
            <td app-table-cell [value]="notification.id"></td>
            <td app-table-cell [value]="notification.retry == true ? 'yes':'no'"></td>
            <td><button class="wild-btn-primary" (click)="retry(notification.id)">
                <span *ngIf="retrying">loading...</span>
                <span *ngIf="!retrying">retry</span>
              </button></td>
            <td app-table-cell [value]="notification.username"></td>
            <td app-table-cell [value]="notification.workoutid"></td>
            <td app-table-cell [value]="notification.sport ?? 'unknown'"></td>
            <td app-table-cell [value]="notification.sub_sport ?? 'unknown'"></td>
            <td app-table-cell [value]="notification.email ?? 'not found'"></td>
            <td app-table-cell [value]="notification.userId ?? 'not found'"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>