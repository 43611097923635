export enum BadgeMetricType {
    UNKNOWN,
    NUMBEROFREVIEWS,
    NUMBEROFSWIMSPOTS,
    NUMBEROFCONTRIBUTIONS,
    NUMBEROFPHOTOS,
    NUMBEROFFOLLOWERS,
    NUMBEROFFOLLOWING,
    NUMBEROFACTIVITIES,
    LOCATIONCOUNTRY,
    LOCATIONCOUNTY,
    LOCATIONSTATE,
    LOCATIONTOWN,
    LOCATIONLOCALITY,
    LOCATIONAREA,
    DATE,
    TIMEHOUR,
    MOONPHASE,
    WATERWAYTYPE,
    TEMPERATUREINCELCIUS,
    SWIMSPOTID,
    DISTANCEINMETERS
}