import { Component, Input, OnInit } from '@angular/core';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';

@Component({
  selector: 'app-swimspotdetails',
  templateUrl: './swimspotdetails.component.html',
  styleUrls: ['./swimspotdetails.component.scss']
})
export class SwimspotdetailsComponent implements OnInit {

  @Input() swimSpot!: SwimSpot;



  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
