import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { environment } from 'src/environments/environment';
import { SwimspotsupdateComponent } from './pages/swimspotsupdate/swimspotsupdate.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PostsupdateComponent } from './pages/postsupdate/postsupdate.component';
import { UsersupdateComponent } from './pages/usersupdate/usersupdate.component';
import { UserprofilesupdateComponent } from './pages/userprofilesupdate/userprofilesupdate.component';
import { ImagesupdateComponent } from './pages/imagesupdate/imagesupdate.component';
import { SwimspotseditComponent } from './pages/swimspotsedit/swimspotsedit.component';
import { SwimspotdetailsComponent } from './pages/swimspotsedit/components/swimspotdetails/swimspotdetails.component';
import { ContentupdateComponent } from './pages/contentupdate/contentupdate.component';
import { ContentComponent } from './pages/content/content.component';
import { SharedModule } from 'src/shared/shared.module';
import { AuthModule } from 'src/wild/modules/auth/auth.module';
import { InReviewComponent } from './pages/inreview/inreview.component';
import { LatestReviewsComponent } from './pages/latestreviews/latestreviews.component';
import { EditsComponent } from './pages/edits/edits.component';
import { UserDetailsComponent } from './pages/swimspotsupdate/userdetails.component';
import { AuthenticationInterceptorService } from 'src/shared/services/http/auth_interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { LatestSpotsComponent } from './pages/latestspots/latestspots.component';
import { LatestPostsComponent } from './pages/latestposts/latestposts.component';
import { LatestAlertsComponent } from './pages/latestalerts/latestalerts.component';
import { LatestMessagesComponent } from './pages/latestmessages/latestmessages.component';
import { LatestPhotosComponent } from './pages/latestphotos/latestphotos.component';
import { ImportedComponent } from './pages/imported/imported.component';
import { PortalmenuComponent } from './portalmenu/portalmenu.component';
import { MapImagesWithNewPhotosComponent } from './pages/mapimageswithnewphotos/mapimageswithnewphotos.component';
import { GarminActivitiesComponent } from './pages/garminactivities/garminactivities.component';
import { GarminActivityPingsComponent } from './pages/garminactivityping/garminactivityping.component';
import { BadgeConfigComponent } from './pages/badgeconfig/badgeconfig.component';
import { BadgeConfigListComponent } from './pages/badgeconfiglist/badgeconfiglist.component';
import { LatestBadgesComponent } from './pages/latestbadges/latestbadges.component';
import { SuuntoNotificationsComponent } from './pages/suuntonotifications/suuntonotifications.component';
import { FitBitNotificationsComponent } from './pages/fitbitnotification/fitbitnotification.component';
import { FitBitRecrodsToProcessComponent } from './pages/fitbitrecordtoprocess/fitbitrecordtoprocess.component';
import { LatestOrdersComponent } from './pages/latestorders/latestorders.component';
import { provideAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { provideFirestore } from '@angular/fire/firestore';
import { provideStorage } from '@angular/fire/storage';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LatestAlertsComponent,
    SwimspotsupdateComponent,
    LogoutComponent,
    PostsupdateComponent,
    GarminActivityPingsComponent,
    UsersupdateComponent,
    SuuntoNotificationsComponent,
    UserprofilesupdateComponent,
    ImagesupdateComponent,
    LatestBadgesComponent,
    BadgeConfigListComponent,
    GarminActivitiesComponent,
    FitBitNotificationsComponent,
    FitBitRecrodsToProcessComponent,
    ImportedComponent,
    LatestSpotsComponent,
    PortalmenuComponent,
    SwimspotseditComponent,
    SwimspotdetailsComponent,
    LatestMessagesComponent,
    ContentupdateComponent,
    LatestPhotosComponent,
    ContentComponent,
    LatestOrdersComponent,
    MapImagesWithNewPhotosComponent,
    InReviewComponent,
    BadgeConfigComponent,
    UserDetailsComponent,
    LatestPostsComponent,
    LatestReviewsComponent,
    EditsComponent
  ],
  imports: [
    SharedModule,
    //firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideStorage(() => getStorage()),
    AuthModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,


  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
