import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { SideMenuService } from 'src/shared/services/sidemenu_service/sidemenu.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { SearchService } from 'src/shared/services/search/search_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchResults } from 'src/shared/data/models/SearchResults';
import { LocationResultType } from 'src/shared/data/enums/location_result_type';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { SearchResult } from 'src/shared/data/models/SearchResult';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { environment } from 'src/environments/environment';
import { GlobalSearchType } from 'src/shared/data/enums/global_search_type';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { userSearchService } from 'src/wild/services/search/search_service';
import { PortalMenuItems } from './portalmenu_items_model';

@Component({
  selector: 'app-portalmenu',
  templateUrl: './portalmenu.component.html'
})

export class PortalmenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() light: boolean;
  @Input() image: string;
  @Input() name: string;
  @Input() buttontext: string;
  @Output() onClick = new EventEmitter<void>();
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('notifications') notifications: ElementRef;


  menu: boolean = false;
  collapsed: boolean = true;
  menuAnimate: boolean = false;
  smallmenu: boolean = false;
  smallmenuAnimate: boolean = false;
  showNotifications: boolean = false;
  showNotificationsAnimate: boolean = false;
  form: UntypedFormGroup;
  searchResults: SearchResults;
  GlobalSearchType = GlobalSearchType;
  showSearch: boolean;
  searchLoading: boolean;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  minSearchCharacters = 3;
  showMinCharactersNotReached: boolean;
  charactersTyped = 0;
  isAppUser: boolean = false;
  numberOfUnreadMessages: number = 0;
  imagesUrl = environment.imagesUrl;
  items: PortalMenuItems[] = [
    {
      description: "Content",
      route: ["", "content"],
      icon: "edit",
      visible: true
    },
    {
      description: "Orders",
      route: ["", "latestorders"],
      icon: "shopping-bag",
      visible: true
    },
    {
      description: "Imported",
      route: ["", "imported"],
      icon: "file-import",
      visible: true
    },
    {
      description: "Map Images",
      route: ["", "mapimages"],
      icon: "map",
      visible: true
    },
    {
      description: "Badge Config",
      route: ["", "badgeconfig"],
      icon: "shield-alt",
      visible: true
    },
    {
      description: "Latest Badges",
      route: ["", "latestbadges"],
      icon: "user-shield",
      visible: true
    },
    {
      description: "In Review",
      route: ["", "inreview"],
      icon: "map-pin",
      visible: true
    },
    {
      description: "Live",
      route: ["", "latestspots"],
      icon: "map-pin",
      visible: true
    },
    {
      description: "Suggested Edits",
      route: ["", "edits"],
      icon: "pen",
      visible: true
    },
    {
      description: "Latest Posts",
      route: ["", "latestposts"],
      icon: "sticky-note",
      visible: true
    },
    {
      description: "Latest Reviews",
      route: ["", "latestreviews"],
      icon: "star",
      visible: true
    },
    {
      description: "Latest Alerts",
      route: ["", "latestalerts"],
      icon: "bell",
      visible: true
    },
    {
      description: "Latest Messages",
      route: ["", "latestmessages"],
      icon: "comment",
      visible: true
    },
    {
      description: "Latest Photos",
      route: ["", "latestphotos"],
      icon: "images",
      visible: true
    },
    {
      description: "Garmin Pings",
      route: ["", "garminactivitypings"],
      icon: "broadcast-tower",
      visible: true
    },
    {
      description: "Garmin Activities",
      route: ["", "garminactivities"],
      icon: "stopwatch",
      visible: true
    },
    {
      description: "Suunto",
      route: ["", "suuntonotifications"],
      icon: "sun",
      visible: true
    },
    {
      description: "Fit Bit Notifications",
      route: ["", "fitbitnotifications"],
      icon: "running",
      visible: true
    },
    {
      description: "Fit Bit Processor",
      route: ["", "fitbitrecordtoprocess"],
      icon: "running",
      visible: true
    },
    {
      description: "Logout",
      route: ["", "logout"],
      icon: "sign-out-alt",
      visible: true
    },
  ]

  @ViewChild('scrollablecontainer') scrollablecontainer: ElementRef<HTMLElement>;

  constructor(
    private panelService: PanelService,
    private userService: UserService,
    private appComms: CommunitcateWithAppService,
    private globalSearch: userSearchService,
    private navigationService: NavigationService,
    private businessService: BusinessService,
    private notify: NotifyService, private searchService: SearchService, private fb: UntypedFormBuilder, private sideMenuService: SideMenuService, private router: Router, private auth: AuthenticationService, private renderer: Renderer2, private storage: StorageService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        try {
          window.scrollTo(0, 0);
          this.sideMenuService.scrollTop();
        } catch (error) { }
      }
    });
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.closeMenu();
        this.closeSmallMenu();
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      // if (!this.dropdown.nativeElement.contains(e.target)) {
      //   this.closeSmallMenu();
      // }
      // if (!this.notifications.nativeElement.contains(e.target)) {
      //   this.closeNotifications();
      // }
      // if (!this.mobilemenucontainer.nativeElement.contains(e.target) && !this.mobilemenutoggle.nativeElement.contains(e.target)) {
      //   this.mobileMenu = false;
      // }
    });
  }
  ngOnDestroy(): void {
    this.sideMenuService.active = false;
  }

  navItemClicked(item: PortalMenuItems) {
    if (item.onClick != undefined) {
      item.onClick();
    } else {
      this.router.navigate(item.route);
    }
  }

  logoClick() {
    this.navigationService.navigate(WildNavigationType.DASHBOARDHOME);
  }

  ngAfterViewInit(): void {
    this.sideMenuService.setScrollableContainer(this.scrollablecontainer);
    this.sideMenuService.active = true;
  }

  listenForNotifications() {
    this.userService.listener().subscribe((user) => {
      this.numberOfUnreadMessages = user?.numberOfUnreadMessages ?? 0;
    })
  }

  ngOnInit(): void {
    this.listenForNotifications();
    // this.checkCollapsedCookiet();
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
  }

  open() {
    this.router.navigate([this.businessService.businessUrl]);
  }

  get profilePic(): string {
    return this.auth.getProfilePic();
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.globalSearch.global(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  searchFinished() {
    this.searchLoading = false;
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  selectSearchResult(result: SearchResult) {
    if (result.type == LocationResultType.SWIMSPOT) {
      this.router.navigate(["/swimspotsupdate", result.id]);
      setTimeout(() => {
        window.location.href = window.location.href;
      }, 200);
      this.clearSearch();
    } else {
      this.notify.notify("Search Only Works with Spots on the portal", "Try Again", NotifyType.ERROR);
    }
  }

  checkCollapsedCookiet() {
    if (this.storage.getCookie("collapsed") == "yes") {
      this.collapsed = true;
      this.sideMenuService.collapsed = true;
    }
  }

  toggleCollapse() {
    if (!this.collapsed) {
      this.collapsed = true;
      this.storage.setCookie("collapsed", "yes", 9999);
      this.sideMenuService.collapsed = true;
    } else {
      this.collapsed = false;
      this.storage.deleteCookie("collapsed");
      this.sideMenuService.collapsed = false;
    }
  }

  toggleMenu() {
    if (!this.menu) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  toggleSmallMenu() {
    if (!this.smallmenu) {
      this.openSmallMenu();
    } else {
      this.closeSmallMenu();
    }
  }

  toggleNotifications() {
    if (!this.showNotifications) {
      this.openNotifications();
    } else {
      this.closeNotifications();
    }
  }

  openMenu() {
    this.menu = true;
    setTimeout(() => {
      this.menuAnimate = true;
    }, 30)
  }

  closeMenu() {
    this.menuAnimate = false;
    setTimeout(() => {
      this.menu = false;
    }, 300)
  }

  openSmallMenu() {
    this.smallmenu = true;
    setTimeout(() => {
      this.smallmenuAnimate = true;
    }, 30)
  }

  closeSmallMenu() {
    this.smallmenuAnimate = false;
    setTimeout(() => {
      this.smallmenu = false;
    }, 300)
  }

  openNotifications() {
    this.showNotifications = true;
    setTimeout(() => {
      this.showNotificationsAnimate = true;
    }, 30)
  }

  closeNotifications() {
    this.showNotificationsAnimate = false;
    setTimeout(() => {
      this.showNotifications = false;
    }, 300)
  }

  click() {
    this.onClick.emit();
  }

  signOut() {
    this.auth.logout();
    this.router.navigate(['']);
  }

}
