import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PostsDataService } from 'src/portal/services/posts-data.service';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { PostModel } from 'src/shared/data/models/post_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  selector: 'app-latestposts',
  templateUrl: './latestposts.component.html'
})
export class LatestPostsComponent implements OnInit {

  posts$: Observable<PostModel[]>;
  error: ErrorModel;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: PostsDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.posts$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  open(id: string) {
    this.router.navigate(["/postssupdate", id]);
  }

  openspot(id: string) {
    this.router.navigate(["/swimspotsupdate", id]);
  }

}
