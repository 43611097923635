import { Component, OnInit } from '@angular/core';
import { SideMenuItems } from 'src/shared/components/sidemenu/sidemenu_items_model';
import { LocationResultType } from 'src/shared/data/enums/location_result_type';
import { SearchResult } from 'src/shared/data/models/SearchResult';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { AuthenticationService } from '../shared/services/auth/auth_service';

interface link {
  icon: string;
  link: string;
  description: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  loggedIn: boolean = false;
  constructor(
    private auth: AuthenticationService,
    private navigation: NavigationService,
    private notify: NotifyService) { }
  ngOnInit(): void {
    // this.auth.listenForAuth();
    this.loggedIn = this.auth.isLoggedIn();
  }
  async signIn() {
    await this.auth.loginWithGoogle()
      .then(() => {
        window.location.href = window.location.href;
      })
      .catch((error) => {
        alert(error.message);
      });
  }

}