import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';
import { ImageModel } from 'src/shared/data/models/images_model';
import { TabModel } from '../tabs/tab_model';

@Component({
  selector: 'app-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.scss']
})
export class FilemanagerComponent implements OnInit {

  @Input() sourceDocumentId:string;
  @Input() sourceDocumentImageType:SourceDocumentType;
  @Input() selectedImages:ImageModel[] = [];
  @Output() onComplete = new EventEmitter<ImageModel[]>();
  @Input() limit:number = 1;
  @Input() uploadOnly:boolean;
  constructor() { }

  tabs:TabModel[] = [];
  activeTabId:string = "";

  ngOnInit(): void {
    this.setTabsAndActiveTabId()
  }

  updateSelectedImages(images:ImageModel[]){
    this.selectedImages = images;
  }

  updateActiveTabId(id:string){
    this.activeTabId = id;
  }

  complete(images:ImageModel[]){
    this.onComplete.emit(images);
  }

  setTabsAndActiveTabId(){
    //NOTE - NEED TO ENSURE THERE IS AT LEAST ONE ACTIVE TAB
    if(this.uploadOnly){
      this.tabs.push({
        id:"upload",
        name:"Upload",
        icon:"upload",
        active:true
      });
    }else{
      this.tabs.push({
        id:"upload",
        name:"Upload",
        icon:"upload",
        active:false
      });
      this.tabs.push({
        id:"media",
        name:"Media",
        icon:"images",
        active:true
      });
    }
    this.activeTabId = this.tabs.find(t => t.active == true).id
  }

}
