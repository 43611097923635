import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
  <span class="sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6" ></span>
  <div [formGroup]="form">
  <div *ngIf="!hideHeaders" class="flex justify-between">
      <label [for]="name" class="block wild-label">
          {{description}}<span *ngIf="required">*</span>
      </label>
  </div>
  <div class="mt-1 flex rounded-md shadow-sm relative">
      <span *ngIf="prefix"
          class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          {{prefix}}
      </span>
      <input [autocomplete]="!disableAutoComplete ? 'on' : 'off'" [readonly]="readonly" [placeholder]="placeholder"
          [ngClass]="{'pr-10':questiontitle && questiondescription, 'rounded-none':prefix,'rounded-l-md':!prefix, 'bg-gray-50 focus:ring-0 focus:border-gray-200 text-gray-400':readonly,'focus:ring-blue-500 focus:border-blue-500':!readonly}"
          [formControlName]="name" [type]="type" [name]="name" [id]="name"
          class="flex-1  block w-full min-w-0  rounded-r-md sm:text-sm border-gray-300">
      <div *ngIf="questiontitle && questiondescription" class="absolute inset-y-0 right-0 pr-3 flex items-center ">
          <svg class="h-5 w-5 text-gray-400 hover:text-blue-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd" />
          </svg>
      </div>
  </div>
  <div *ngIf="form.get(name)?.errors && (form.get(name)?.dirty || form['submitted'] == true)"> 
      <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(name)?.errors">
          <span *ngIf="!validation"> {{description}} is required</span>
          <span *ngIf="validation"> {{validation}}</span>
      </div>
  </div>
</div>`
})
export class InputComponent implements OnInit {

  @Input() prefix!: string;
  @Input() form!: UntypedFormGroup;
  @Input() name!: string;
  @Input() value!: any;
  @Input() readonly: boolean = false;
  @Input() type: string = "text";
  @Input() description!: string;
  @Input() validation!: string;
  @Input() placeholder: string = "";
  @Input() hideHeaders: boolean;
  @Input() span!: number;
  @Input() questiontitle!: string;
  @Input() questiondescription!: string;
  @Input() disableAutoComplete: boolean;

  @HostBinding('class') get t() {
    return "p-0 border-0 bg-transparent sm:col-span-" + this.span;
  };

  constructor() { }

  required;

  ngOnInit(): void {
    if (this.value != undefined && this.value != null)
      this.form.controls[this.name].setValue(this.value);
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
  }

}
