import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PostsDataService } from 'src/portal/services/posts-data.service';
import { ReviewsDataService } from 'src/portal/services/reviews-data.service';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { PostModel } from 'src/shared/data/models/post_model';
import { Review } from 'src/shared/data/models/review_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  selector: 'app-latestreviews',
  templateUrl: './latestreviews.component.html',
  styleUrls: ['./latestreviews.component.scss']
})
export class LatestReviewsComponent implements OnInit {

  reviews$: Observable<Review[]>;
  error: ErrorModel;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: ReviewsDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.reviews$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  openspot(id: string) {
    this.router.navigate(["/swimspotsupdate", id]);
  }

}
