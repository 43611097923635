import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Review } from 'src/shared/data/models/review_model';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { OrderStatus } from 'src/shared/data/enums/order_status';
import { OrderModel } from 'src/shared/data/models/order_model';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersDataService {

  constructor(
    private http: HttpService,
    private firestore: FirestoreService
  ) { }

  async reprocess(orderId: string) {
    await this.http.post(`${environment.api}/portal/orders/reprocess/${orderId}`, {}).toPromise();
  }

  latest(): Observable<OrderModel[]> {
    return this.firestore.getListByQuery("orders", [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 20
      }
    ])
  }
  latestPaidOnly(): Observable<OrderModel[]> {
    return this.firestore.getListByQuery("orders", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "in",
        property: "status",
        value: [OrderStatus.PAID]
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 20
      }
    ])
  }
}
