<app-wrapper header="badges">
  <div *ngIf="!badges$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="badges$">
    <ng-container *ngIf="badges$ | async as badges ; else loadingOrError">
      <div *ngIf="!badges || badges.length == 0; else badgestable">
        <app-empty icon="edit" header="No badges" description="New badges will appear here">
        </app-empty>
      </div>
      <ng-template #badgestable>
        <app-table [headers]="[{
                    header: ''
                  },{
                    header: 'Awarded'
                  },{
                    header: 'Title'
                  },{
                    header: 'Description'
                  },{
                    header: 'Type'
                  }]">
          <tr *ngFor="let message of badges">
            <td class="w-16 h-16"><img [src]="message.image" /></td>

            <td app-table-cell [value]="readableDate(message?.awardedDate?.toDate())"></td>
            <td app-table-cell [value]="message.title"></td>
            <td app-table-cell [value]="message.description"></td>
            <td app-table-cell [value]="message.type"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>