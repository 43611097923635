<app-wrapper header="FitBit Processor">
  <div class="flex flex-wrap">
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.UNKNOWN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Unknown Status</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.INSERTED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Inserted</p>
    </div>
    <div
      [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.COULDNOTFINDWILDUSERFORTHISNOTIFICATION)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Could not get wild user</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.ERRORREFRESHINGTOKEN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Refersh token error</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.ERRORGETTINGACTIVITYDETAILS)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Error getting activity details</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.ERRORDOWNLOADINGTCXFILE)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Error getting TCX</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.ERRORGENERATINGPOLYLINEFROMTCX)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Error generating polyline</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.ERRORGENERATINGPOLYLINEMETADATA)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Error generating polyline meta</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.PROCESSED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITRECORDTOPROCESSSTATUS.HASNOLOCATIONDATA)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Has no location data</p>
    </div>
  </div>
  <div *ngIf="!notifications$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="notifications$">
    <ng-container *ngIf="notifications$ | async as notifications ; else loadingOrError">
      <div *ngIf="!notifications || notifications.length == 0; else notificationstable">
        <app-empty icon="edit" header="No notifications" description="New notifications will appear here">
        </app-empty>
      </div>
      <ng-template #notificationstable>
        <app-table [headers]="[{
                    header: 'Creation Date'
                  },{
                    header: 'Wild Record ID'
                  },
                  {
                    header: 'userId'
                  },{
                    header: 'email'
                  },{
                    header: 'fitbitUserId'
                  }
                  ,{
                    header: 'fitbitActivityId'
                  }
                  ,{
                    header: 'fitbit activity name'
                  }
                  ]">
          <tr [ngStyle]="{'background-color': getStatusColor(notification.status)}"
            *ngFor="let notification of notifications">
            <td app-table-cell [value]="readableDate(notification?.generatedDate?.toDate())"></td>
            <td app-table-cell [value]="notification.id"></td>
            <!-- id: string,
                  userId: string,
                  generatedDate: Date,
                  generatedDateIso: string,
                  type: JourneyActivityType,
                  fitbitActivityId: number,
                  status: FITBITRECORDTOPROCESSSTATUS,
                  recentActivityRecord?: FitBitActivity, -->
            <!-- <td app-table-cell [value]="notification.retry == true ? 'yes':'no'"></td> -->
            <!-- <td><button class="wild-btn-primary" (click)="retry(notification.id)">
                <span *ngIf="retrying">loading...</span>
                <span *ngIf="!retrying">retry</span>
              </button></td> -->
            <td app-table-cell [value]="notification.userId"></td>
            <td app-table-cell [value]="notification.email ?? 'TODO'"></td>
            <td app-table-cell [value]="notification.fitbitUserId ?? 'TODO'"></td>
            <td app-table-cell [value]="notification.fitbitActivityId ?? 'unknown'"></td>
            <td app-table-cell [value]="notification.recentActivityRecord.activityName ?? 'Unknown'"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>