import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfileModel } from 'src/shared/data/models/user_profile_model';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfilesDataService {

  collection: string = "user_profiles";

  constructor(
    private crud: CrudService
  ) { }

  async create(data: UserProfileModel) {
    await this.crud.create(this.collection, data);
  }

  read(id: string): Observable<UserProfileModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as UserProfileModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async update(data: UserProfileModel) {
    await this.crud.update(this.collection, data);
  }

}
