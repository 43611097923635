export enum GarminActivityStatus {
    UNKNOWN,
    FAILED,
    ALREADYPROCESSED,
    CREATED,
    NOUSERACCESSTOKEN,
    COULDNOTFINDUSERASSOCIATEDWITHACTIVITY,
    NOTWATERBASED,
    NOPOLYLINE,
    NOPOLYLINEMETADATA
}