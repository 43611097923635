import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { UserProfileModel } from '../data/models/user_profile_model';


@Component({
  selector: '<app-userprofiledetails></app-userprofiledetails>',
  template: `
      <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <div *ngIf="!user; else userdetails">
          user not found
        </div>
        <ng-template #userdetails>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <a  class="flex-shrink-0 group block">
              <div class="flex items-center">
                <div>
                  <img class="inline-block h-9 w-9 rounded-full" [src]="user.profilePicture != null ? imageUrl + user.profilePicture.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'" alt="">
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{user.username}}</p>
                </div>
              </div>
            </a>
      </ng-template>
      </ng-container>
      <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
      </ng-template>
  `,
})
export class UserProfileDetailsComponent implements OnInit {
  @Input() userId: string;
  user$: Observable<UserProfileModel>;
  error: ErrorModel;
  imageUrl = environment.imagesUrl;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.user$ = null;
    this.user$ = this.userService.getUserProfileById(this.userId).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }
}
