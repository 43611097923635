export enum FITBITRECORDTOPROCESSSTATUS {
    UNKNOWN,
    INSERTED,
    COULDNOTFINDWILDUSERFORTHISNOTIFICATION,
    ERRORREFRESHINGTOKEN,
    ERRORGETTINGACTIVITYDETAILS,
    ERRORDOWNLOADINGTCXFILE,
    ERRORGENERATINGPOLYLINEFROMTCX,
    ERRORGENERATINGPOLYLINEMETADATA,
    PROCESSED,
    HASNOLOCATIONDATA
}