<app-wrapper header="FitBit Notifications ">
  <div class="flex flex-wrap">
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.UNKNOWN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Unknown Status</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.INSERTED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Inserted</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.NOFITBITUSERIDFROMFITBIT)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>No fit bit user id</p>
    </div>
    <div
      [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.COULDNOTFINDWILDUSERFORTHISNOTIFICATION)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Couldn't find wild user</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.FOUNDUSERINFORMATION)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Found Wild User</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.ERRORFETCHINGACTIVITYLOG)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Error fetching activity log</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.ERRORREFRESHINGTOKEN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Refresh token error</p>
    </div>
    <div
      [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.PROCESSEDACTIVITYLOGWITHWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed water based activities</p>
    </div>
    <div
      [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.PROCESSEDACTIVITYLOGWITHOUTWATERBASEDACTIVITIES)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Processed NO water based activities</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(FITBITNOTIFICATIONSTATUS.ACTIVITYLOGEMPTY)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Actvities log empty</p>
    </div>
  </div>
  <div *ngIf="!notifications$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="notifications$">
    <ng-container *ngIf="notifications$ | async as notifications ; else loadingOrError">
      <div *ngIf="!notifications || notifications.length == 0; else notificationstable">
        <app-empty icon="edit" header="No notifications" description="New notifications will appear here">
        </app-empty>
      </div>
      <ng-template #notificationstable>
        <app-table [headers]="[{
                    header: 'Creation Date'
                  },{
                    header: 'Wild Record ID'
                  },
                  {
                    header: 'userId'
                  },{
                    header: 'fitbitUserId'
                  }
                  ,{
                    header: 'email'
                  }
                  ,{
                    header: 'Logs'
                  }
                  ,{
                    header: 'Old'
                  }
                  ,{
                    header: 'New'
                  }
                  ,{
                    header: 'New Water'
                  }
                  ,{
                    header: 'New None Water'
                  }

                  ]">
          <tr [ngStyle]="{'background-color': getStatusColor(notification.status)}"
            *ngFor="let notification of notifications">
            <td app-table-cell [value]="readableDate(notification?.generatedDate?.toDate())"></td>
            <td app-table-cell [value]="notification.id"></td>
            <!-- <td app-table-cell [value]="notification.retry == true ? 'yes':'no'"></td> -->
            <!-- <td><button class="wild-btn-primary" (click)="retry(notification.id)">
                <span *ngIf="retrying">loading...</span>
                <span *ngIf="!retrying">retry</span>
              </button></td> -->
            <td app-table-cell [value]="notification.userId"></td>
            <td app-table-cell [value]="notification.fitbitUserId"></td>
            <td app-table-cell [value]="notification.email ?? 'unknown'"></td>
            <td app-table-cell [value]="notification.numberOfRecordsInLog ?? 0"></td>
            <td app-table-cell [value]="notification.numberAlreadyProcessed ?? 0"></td>
            <td app-table-cell [value]="notification.numberOfNewRecords ?? 0"></td>
            <td app-table-cell [value]="notification.numberOfNewRecordsThatAreWaterBased ?? 0"></td>
            <td app-table-cell [value]="notification.numberOfNewRecordsThatAreNOTWaterBased ?? 0"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>