import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { UserModel } from 'src/shared/data/models/user_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { UserService } from 'src/wild/modules/user/services/user_service';

@Component({
  selector: '<app-userdetails></app-userdetails>',
  template: `
      <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <div *ngIf="!user; else userdetails">
          user not found
        </div>
        <ng-template #userdetails>
            <div>
              <h3 class="text-sm text-grey-500">User ID</h3>
              <p class="text-sm text-grey-500">{{user.id}}</p>
            </div>
            <div>
              <h3 class="text-sm text-grey-500">Send Email</h3>
              <a class="underline font-semibold text-sm" [href]="'mailto:'+user.email">{{user.email}}</a>
            </div>
            <div>
              <h3 class="text-sm text-grey-500">Send Email asking for picture</h3>
              <a class="underline font-semibold text-sm" [href]="'mailto:'+ user.email +'?subject='+ this.swimspot.name +' - image&body=Hi,%0D%0A%0D%0AThank you so much for adding '+ this.swimspot.name +'.%0D%0A%0D%0ADo you have a picture of this location? We like to ensure we have at least one photo per swim spot so people know what to expect on arrival.%0D%0A%0D%0A Thanks in advance,%0D%0ARob'">send</a>
            </div>
            <div>
              <h3 class="text-sm text-grey-500">Send Email about duplicate</h3>
              <a class="underline font-semibold text-sm" [href]="'mailto:'+ user.email +'?subject='+ this.swimspot.name +' - deplicate&body=Hi,%0D%0A%0D%0AThank you so much for adding '+ this.swimspot.name +'.%0D%0A%0D%0AThis swim spot already exists on the platform so we cannot approve it.%0D%0A%0D%0A Thanks,%0D%0ARob'">send</a>
            </div>
            <div>
              <h3 class="text-sm text-grey-500">Send Email about approving changes</h3>
              <a class="underline font-semibold text-sm" [href]="'mailto:'+ user.email +'?subject='+ this.swimspot.name +' - changes approved&body=Hi,%0D%0A%0D%0AThank you so much for editing '+ this.swimspot.name +'.%0D%0A%0D%0AThese changes have now been approved and are live on the platform.%0D%0A%0D%0A Thanks,%0D%0ARob'">send</a>
            </div>
            <div>
              <h3 class="text-sm text-grey-500">Send Email about declining changes</h3>
              <a class="underline font-semibold text-sm" [href]="'mailto:'+ user.email +'?subject='+ this.swimspot.name +' - changes not approved&body=Hi,%0D%0A%0D%0AThank you so much for editing '+ this.swimspot.name +'.%0D%0A%0D%0AUnfortunately we cannot approve these changes.%0D%0A%0D%0A Thanks,%0D%0ARob'">send</a>
            </div>
      </ng-template>
      </ng-container>
      <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
      </ng-template>
  `,
})
export class UserDetailsComponent implements OnInit {
  @Input() userId: string;
  @Input() swimspot: SwimSpot;
  user$: Observable<UserModel>;
  error: ErrorModel;
  imageUrl = environment.imagesUrl;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.user$ = null;
    this.user$ = this.userService.getUserById(this.userId).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }
}
