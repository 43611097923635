import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
  ) { }


  // async approveSwimSpotEdit(swimSpotEditId: string) {
  //   const callable = this.fns.httpsCallable('approveEdit');
  //   await callable({ swimSpotEditId: swimSpotEditId }).toPromise().catch((error) => {
  //     this.error(error);
  //   });;
  // }

  error(error: any) {
    var errorCode = error.code;
    var errorMessage = error.message;
    throw Error(errorCode + ": " + errorMessage);
  }

}
