import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentModel } from 'src/shared/data/models/content_model';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ContentDataService {

  collection: string = "content";

  constructor(
    private crud: CrudService
  ) { }

  async create(data: ContentModel) {
    await this.crud.create(this.collection, data);
  }

  read(id: string): Observable<ContentModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as ContentModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async update(data: ContentModel) {
    await this.crud.update(this.collection, data);
  }

  // list(): Observable<ContentModel[]> {
  //   // return this.afs
  //   //   .collection('content', ref => {
  //   //     return ref
  //   //     // .where('status', '<=', 3)
  //   //   })
  //   //   .snapshotChanges()
  //   //   .pipe(
  //   //     map((actions: any) => actions.map((a: any) => {
  //   //       const data = a.payload.doc.data() as ContentModel;
  //   //       const id = a.payload.doc.id;
  //   //       return { id, ...data };
  //   //     })));
  // }

}
