export enum FITBITNOTIFICATIONSTATUS {
    UNKNOWN,
    INSERTED,
    NOFITBITUSERIDFROMFITBIT,
    COULDNOTFINDWILDUSERFORTHISNOTIFICATION,
    FOUNDUSERINFORMATION,
    ERRORFETCHINGACTIVITYLOG,
    ERRORREFRESHINGTOKEN,
    PROCESSEDACTIVITYLOGWITHWATERBASEDACTIVITIES,
    PROCESSEDACTIVITYLOGWITHOUTWATERBASEDACTIVITIES,
    ACTIVITYLOGEMPTY
}