import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { BadgeConfigModel } from 'src/shared/data/models/badge_config';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeConfigService {

  collection: string = "badgeconfig";

  constructor(
    private firestore: FirestoreService,
    private crud: CrudService,
  ) { }

  async new(): Promise<string> {
    return await this.firestore.create(this.collection, {
      "title": "UPDATE ME",
      "records": [],
      "status": 1
    })
  }

  list(): Observable<BadgeConfigModel[]> {
    return this.firestore.getListByQuery(this.collection, [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "title",
        value: "asc"
      }
    ])
  }

  read(id: string): Observable<BadgeConfigModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as BadgeConfigModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  async update(data: BadgeConfigModel) {
    await this.crud.update(this.collection, data);
  }
}
