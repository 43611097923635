import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactSearchResultsDTO } from 'src/shared/data/dtos/business/search/contact/ContactSearchResultsDTO';
import { SearchResults } from 'src/shared/data/models/SearchResults';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class SwimSpotSearchService {

    constructor(
        private http: HttpService
    ) { }

    search(query: string): Observable<SearchResults> {
        return this.http.get(`${environment.api}/business/search/swimspots?q=${query}`);

    }

}
