import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PostsDataService } from 'src/portal/services/posts-data.service';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { PostModel } from 'src/shared/data/models/post_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { ImageModel } from 'src/shared/data/models/images_model';
import { ImagesDataService } from 'src/portal/services/images-data.service';
import { environment } from 'src/environments/environment';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';

@Component({
  selector: 'app-latestphotos',
  templateUrl: './latestphotos.component.html'
})
export class LatestPhotosComponent implements OnInit {

  images$: Observable<ImageModel[]>;
  error: ErrorModel;
  imagesUrl = environment.imagesUrl;
  SourceDocumentType = SourceDocumentType;

  constructor(
    private router: Router,
    private dates: DatesService,
    private data: ImagesDataService,) { }

  ngOnInit(): void {
    this.load();
  }

  readableDate(date: Date) {
    return this.dates.getFormattedDateTimeFromObject(date)
  }

  load() {
    this.images$ = this.data.latest().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  openspot(id: string) {
    this.router.navigate(["/swimspotsupdate", id]);
  }

}
