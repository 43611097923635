<app-wrapper header="Recent Orders">
  <div *ngIf="!orders$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="orders$">
    <ng-container *ngIf="orders$ | async as orders ; else loadingOrError">
      <div *ngIf="!orders || orders.length == 0; else swimspotstable">
        <app-empty icon="shopping-bag" header="No Orders" description="">
        </app-empty>
      </div>
      <ng-template #swimspotstable>
        <div class="flex flex-wrap">
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.UNKNOWN)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Unknown Status</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.SHOPPINGCART)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Shopping Cart</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.EXPIRED)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Expired</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.ABANDONCART)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Abandon Cart</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.FULLYREFUNDED)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Fully Refunded</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.PARTIALREFUND)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Partially Refunded</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.PROCESSING)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Processing</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.PAID)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>PAID</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.SUCCESSFULPAYMENTDETECTEDFROMSTRIPE)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Success webhook from stripe</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.REPROCESSORDER)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Reprocess order</p>
          </div>
          <div [ngStyle]="{'background-color': getStatusColor(OrderStatus.PROCESSSFREEORDER)}"
            class="mr-4 p-1 mb-4 text-sm rounded-lg">
            <p>Process FREE Order Trigger</p>
          </div>
        </div>
        <div class="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let order of orders">
              <a (click)="open(order)" class="block hover:opacity-90">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="flex-shrink-0">
                      <div class="h-12 w-12 rounded-full" [ngStyle]="{'background-color': getStatusColor(order.status)}"
                        alt=""></div>
                    </div>
                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p class="truncate text-sm font-medium text-indigo-600">{{getGrandTotal(order)}} -
                          {{order.reference}}</p>
                        <p class="mt-0 flex items-center text-sm text-gray-700 font-semibold">
                          <span class="truncate">{{order.businessName ?? "unknown"}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-700 font-medium">
                          <span class="truncate">{{(order?.contact?.personal?.firstName ?? "unknown") + " " +
                            (order?.contact?.personal?.surname ?? "unknown")}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-500">
                          <span class="truncate">Subtotal: {{getSubTotal(order)}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-500">
                          <span class="truncate">Fees: {{getFees(order)}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-500">
                          <span class="truncate">Refunded: {{getRefund(order)}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-500">
                          <span class="truncate">Discount: {{getDiscount(order)}}</span>
                        </p>
                        <p class="mt-0 flex items-center text-sm text-gray-500">
                          <span class="truncate">Type: {{getCheckoutType(order)}}</span>
                        </p>
                      </div>
                      <div class="hidden md:block">
                        <div>
                          <p class="text-sm text-gray-900">
                            Created: <time>{{readableDate(order.creation_date.toDate())}}</time>
                          </p>
                          <p class="text-sm text-gray-900">
                            Cart Updated: <time>{{readableDate(order.cartUpdated.toDate())}}</time>
                          </p>
                          <p class="text-sm text-gray-900">
                            Transaction: <time>{{readableDate(!order?.transaction_date ? 'unknown' :
                              order.transaction_date.toDate())}}</time>
                          </p>
                          <p class="text-sm text-gray-900">
                            Checkout Status: <time>{{getCheckoutStatus(order)}}</time>
                          </p>
                          <p class="text-sm text-gray-900">
                            Coupons: {{(!order?.couponsApplied || (order?.couponsApplied?.length ?? 0) == 0) ? 'NA' :
                            order?.couponsApplied}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <!-- Heroicon name: mini/chevron-right -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>

                </div>
                <div class="flex justify-end px-4 py-4 sm:px-6">
                  <span class="isolate inline-flex rounded-md shadow-sm">
                    <!-- <button type="button" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Years</button> -->
                    <button (click)="reprocess(order.id)" type="button"
                      class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Reprocess</button>
                    <!-- <button type="button" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Days</button> -->
                  </span>
                </div>
              </a>
              <div class="px-4 py-4 sm:px-6">
                <p class="text-xs text-gray-600 mb-1">ID: {{order.id}}</p>
                <p class="text-xs text-gray-600 mb-1">applicationFeeId: {{order.applicationFeeId ?? 'unknown'}}</p>
                <p class="text-xs text-gray-600 mb-1">clientSecret: {{order.clientSecret ?? 'unknown'}}</p>
                <p class="text-xs text-gray-600 mb-1">Payment link: <a class="text-blue-500 hover:underline"
                    target="_blank" [href]="getStripeLink(order)">{{getStripeLink(order)}}</a></p>
                <p class="text-xs text-gray-600 mb-1">Business link: <a class="text-blue-500 hover:underline"
                    target="_blank" [href]="getBusinessUrl(order)">{{getBusinessUrl(order)}}</a></p>
                <p class="text-xs text-gray-600 mb-1">Business public link: <a class="text-blue-500 hover:underline"
                    target="_blank" [href]="getBusinessPublicUrl(order)">{{getBusinessPublicUrl(order)}}</a></p>
                <p class="text-xs text-gray-600 mb-1">Record Link: <a class="text-blue-500 hover:underline"
                    target="_blank" [href]="getFirestoreLink(order)">{{getFirestoreLink(order)}}</a></p>
              </div>
            </li>
          </ul>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>