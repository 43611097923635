import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BadgeConfigService } from 'src/portal/services/badgeConfig_service.service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BadgeMetricDirection } from 'src/shared/data/enums/badge_metric_direction';
import { BadgeMetricType } from 'src/shared/data/enums/badge_metric_type';
import { BadgeType } from 'src/shared/data/enums/badge_type';
import { JourneyActivityType } from 'src/shared/data/enums/jounrey_activity_type';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';
import { BadgeConfigModel } from 'src/shared/data/models/badge_config';
import { BadgeConfigRecord } from 'src/shared/data/models/badge_config_record';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SwimSpotSearchComponent } from 'src/shared/components/swimspotsearch.component';
import { BadgeConfigStatus } from 'src/shared/data/enums/badge_config_status';

@Component({
  selector: 'app-badgeconfig',
  templateUrl: './badgeconfig.component.html'
})
export class BadgeConfigComponent implements OnInit {

  form!: UntypedFormGroup;
  BadgeMetricDirection = BadgeMetricDirection;

  badgeConfigStatusOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: BadgeConfigStatus.UNKNOWN
    },
    {
      name: "Draft",
      value: BadgeConfigStatus.DRAFT
    },
    {
      name: "Live",
      value: BadgeConfigStatus.LIVE
    }
  ]
  badgeMetricDirectionOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: BadgeMetricDirection.UNKNOWN
    },
    {
      name: "Same or higher",
      value: BadgeMetricDirection.MUSTBESAMEORHIGHER
    },
    {
      name: "Same or lower",
      value: BadgeMetricDirection.MUSTBESAMEORLOWER
    },
    {
      name: "Exact Match",
      value: BadgeMetricDirection.EXACTMATCH
    },
  ]
  activityTypeOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: JourneyActivityType.UNKNOWN
    },
    {
      name: "Outdoor Swimming",
      value: JourneyActivityType.OUTDOORSWIM
    },
    {
      name: "Dipping",
      value: JourneyActivityType.DIPPING
    },
    {
      name: "Cold Water Immersion",
      value: JourneyActivityType.COLDWATERIMMERSION
    },
    {
      name: "Boating",
      value: JourneyActivityType.BOATING
    },
    {
      name: "Rowing",
      value: JourneyActivityType.ROWING
    },
    {
      name: "Sailing",
      value: JourneyActivityType.SAILING
    },
    {
      name: "SUP",
      value: JourneyActivityType.SUP
    },
    {
      name: "Surfing",
      value: JourneyActivityType.SURFING
    },
    {
      name: "Wake Boarding",
      value: JourneyActivityType.WAKEBOARDING
    },
    {
      name: "White Water Rafting",
      value: JourneyActivityType.WHITEWATERRAFTING
    },
    {
      name: "Kite Surfing",
      value: JourneyActivityType.KITESURFING
    },
    {
      name: "Diving",
      value: JourneyActivityType.DIVING
    },
    {
      name: "Paddling / Kayak / Canoe",
      value: JourneyActivityType.PADDLINGKAYAKCANOE
    }
  ]
  badgeMetricTypeOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: BadgeMetricType.UNKNOWN
    },
    {
      name: "Number of Reviews",
      value: BadgeMetricType.NUMBEROFREVIEWS
    },
    {
      name: "Number of Swim Spots",
      value: BadgeMetricType.NUMBEROFSWIMSPOTS
    },
    {
      name: "Number of Contributions",
      value: BadgeMetricType.NUMBEROFCONTRIBUTIONS
    },
    {
      name: "Number of Photos",
      value: BadgeMetricType.NUMBEROFPHOTOS
    },
    {
      name: "Number of Followers",
      value: BadgeMetricType.NUMBEROFFOLLOWERS
    },
    {
      name: "Number of Following",
      value: BadgeMetricType.NUMBEROFFOLLOWING
    },
    {
      name: "Number of Activities",
      value: BadgeMetricType.NUMBEROFACTIVITIES
    },
    {
      name: "Location Country",
      value: BadgeMetricType.LOCATIONCOUNTRY
    },
    {
      name: "Location County",
      value: BadgeMetricType.LOCATIONCOUNTY
    },
    {
      name: "Location State",
      value: BadgeMetricType.LOCATIONSTATE
    },
    {
      name: "Location Town",
      value: BadgeMetricType.LOCATIONTOWN
    },
    {
      name: "Location Locality",
      value: BadgeMetricType.LOCATIONLOCALITY
    },
    {
      name: "Location Area",
      value: BadgeMetricType.LOCATIONAREA
    },
    {
      name: "Date",
      value: BadgeMetricType.DATE
    },
    {
      name: "Time Hour",
      value: BadgeMetricType.TIMEHOUR
    },
    {
      name: "Moon Phase",
      value: BadgeMetricType.MOONPHASE
    },
    {
      name: "Waterway Type",
      value: BadgeMetricType.WATERWAYTYPE
    },
    {
      name: "Temperate in Celcius",
      value: BadgeMetricType.TEMPERATUREINCELCIUS
    },
    {
      name: "Swim Spot ID",
      value: BadgeMetricType.SWIMSPOTID
    },
    {
      name: "Distance in meters",
      value: BadgeMetricType.DISTANCEINMETERS
    },
  ]
  sourceDocumentTypeOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: SourceDocumentType.UNKNOWN
    },
    {
      name: "User Profile",
      value: SourceDocumentType.USERPROFILE
    },
    {
      name: "Activity Post",
      value: SourceDocumentType.ACTIVITYPOST
    }
  ]

  badgeTypeOptions: SelectModel[] = [];

  badgeConfig: BadgeConfigModel;
  configId: string;

  constructor(
    private notify: NotifyService,
    private modalService: ModalService,
    private data: BadgeConfigService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.configId = params.id;
      this.get();
    });
    this.setBadgeTypeOptions();
  }

  setBadgeTypeOptions() {
    const badgeTypeNumbers = Object.keys(BadgeType).filter((item) => {
      return !isNaN(Number(item));
    });
    var selectList: SelectModel[] = [];
    for (let i = 0; i < badgeTypeNumbers.length; i++) {
      const EnumValue = badgeTypeNumbers[i];
      selectList.push({
        name: this.getBadgeTypeDescription(BadgeType, Number.parseInt(EnumValue)),
        value: Number.parseInt(EnumValue)
      });
    }
    selectList.sort((a, b) => a.name.localeCompare(b.name))
    this.badgeTypeOptions = selectList;

  }

  getBadgeTypeDescription(e: any, id: number): string {
    return e[e[id].toString() + "_d"] ?? `No description for ${id}`;
  }

  get() {
    this.badgeConfig = null;
    this.data.read(this.configId).subscribe((data) => {
      this.badgeConfig = data;
      this.dataLoaded();
    }, (error) => {
      this.notify.notify("Error Loading Config", error.message, NotifyType.ERROR);
    });
  }

  spot(record: UntypedFormControl) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: SwimSpotSearchComponent,
      close: true,
      componentInputs: [],
      componentOutputs: [
        {
          outputName: "swimSpotId",
          func: async ($event: string) => {
            record.get("value").setValue($event);
            this.modalService.hide();
          }
        }
      ]
    }
    this.modalService.show(settings);
  }

  getTitle(record: UntypedFormControl): string {
    return record.get("title").value;
  }

  getStatus(record: UntypedFormControl): string {
    return record.get("status").value;
  }

  openOrClosed(record: UntypedFormControl): boolean {
    return record.get("open").value == "true";
  }

  expand(record: UntypedFormControl) {
    record.get("open").setValue("true");
  }

  collapse(record: UntypedFormControl) {
    record.get("open").setValue("false");
  }

  dataloaded = false;

  dataLoaded() {
    this.form = this.fb.group({
      title: new UntypedFormControl(this.badgeConfig == null ? BadgeType.UNKNOWN : this.badgeConfig.title, [
        Validators.required
      ]),
      status: new UntypedFormControl(this.badgeConfig == null ? BadgeConfigStatus.DRAFT : this.badgeConfig.status, [
        Validators.required
      ]),
      sourceDocumentType: new UntypedFormControl(this.badgeConfig == null ? SourceDocumentType.UNKNOWN : this.badgeConfig.sourceDocumentType, [
        Validators.required
      ]),
      records: this.fb.array([])
    });
    if (this.badgeConfig.records && this.badgeConfig.records.length > 0) {
      this.badgeConfig.records.forEach(element => {
        this.addBadgeConfigRecordForm(element);
      });
    }
    this.dataloaded = true;
  }

  get records() {
    return this.form.get('records') as UntypedFormArray;
  }

  addBadgeConfigRecordForm(record: BadgeConfigRecord, index: number = null) {
    var formGroup = this.fb.group({
      badgeType: new UntypedFormControl(record == null ? BadgeType.UNKNOWN : record.badgeType, [
        Validators.required
      ]),
      status: new UntypedFormControl(record == null ? BadgeConfigStatus.DRAFT : record.status, [
        Validators.required
      ]),
      activityType: new UntypedFormControl(record == null ? JourneyActivityType.UNKNOWN : record.activityType, [
        Validators.required
      ]),
      badgeMetricType: new UntypedFormControl(record == null ? BadgeMetricType.UNKNOWN : record.badgeMetricType, [
        Validators.required
      ]),
      threshold: new UntypedFormControl(record == null ? 0 : (record?.threshold ?? 0), [
        Validators.required
      ]),
      thresholdDirection: new UntypedFormControl(record == null ? BadgeMetricDirection.UNKNOWN : (record?.thresholdDirection ?? 0), [
        Validators.required
      ]),
      value: new UntypedFormControl(record == null ? "" : (record?.value ?? ""), [
        Validators.required
      ]),
      image: new UntypedFormControl(record == null ? "" : (record?.image ?? ""), [
        Validators.required
      ]),
      title: new UntypedFormControl(record == null ? "" : (record?.title ?? ""), [
        Validators.required
      ]),
      description: new UntypedFormControl(record == null ? "" : (record?.description ?? ""), [
        Validators.required
      ]),
      notificationMessage: new UntypedFormControl(record == null ? "" : (record?.notificationMessage ?? ""), [
        Validators.required
      ]),
      notificationBody: new UntypedFormControl(record == null ? "" : (record?.notificationBody ?? ""), [
        Validators.required
      ]),
      //Intentionally string here
      open: new UntypedFormControl("true", [])
    });
    if (index == null) {
      this.records.push(formGroup);
    } else {
      this.records.insert(index + 1, formGroup);
    }
  }

  getImageUrl(index: number) {
    if (this.badgeConfig)
      return this.badgeConfig?.records[index]?.image ?? "";
  }

  moveSection(index: number, up: boolean) {
    if (index == 0 && up == true) {
      this.notify.notify("Oops", "Already at the top", NotifyType.WARNING);
      return
    }
    var section = this.records.at(index);
    this.removeSection(index);
    if (up) {
      this.records.insert(index - 1, section);
    } else {
      this.records.insert(index + 1, section);
    }
  }

  removeSectionWithConfirmation(index) {
    var confirm = window.confirm("Are you sure?")
    if (confirm == true)
      this.removeSection(index);
  }

  removeSection(index) {
    this.records.removeAt(index);
  }

  async update(data: any) {
    for (let i = 0; i < data.records.length; i++) {
      data.records[i].threshold = Number.parseInt(data.records[i]?.threshold ?? 0)
    }
    const content = {
      ...this.badgeConfig,
      ...data,
    }
    await this.data
      .update(content)
      .then(() => {
        this.notify.notify("SUCCESS", "UPDATED!", NotifyType.SUCCESS);
      })
      .catch((error: any) => {
        this.handlerError(error.message);
      })
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

}
