export enum PostType {
    NEWSWIMSPOT,
    NEWSWIMSPOTIMAGE,
    NEWWATERWAYIMAGE,
    UNKNOWN,
    SWIMSPOTPOST,
    BUSINESSPOST,
    EVENTPOST,
    MANUALACTIVITYPOST,
    ACTIVITYPOST
}