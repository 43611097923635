<app-wrapper header="Reviews">
    <div *ngIf="!reviews$">
        <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div *ngIf="reviews$">
        <ng-container *ngIf="reviews$ | async as reviews ; else loadingOrError">
            <div *ngIf="!reviews || reviews.length == 0; else reviewstable">
                <app-empty icon="edit" header="No Reviews" description="New reviews will appear here">
                </app-empty>
            </div>
            <ng-template #reviewstable>
                <app-table [headers]="[{
                    header: 'Created'
                  },{
                    header: 'Username'
                  },{
                    header: 'Spot'
                  },{
                    header: 'Likes'
                  },{
                    header: 'Comments'
                  },{
                    header: 'Rating'
                  },{
                    header: 'Review'
                  }]">
                    <tr *ngFor="let review of reviews">
                        <td app-table-cell [value]="readableDate(review.creation_date.toDate())"></td>
                        <td app-table-cell [value]="review.username"></td>
                        <td app-table-cell [cta]="true" (onClick)="openspot(review.swimSpotId)" value="open spot"></td>
                        <td app-table-cell [value]="review.numberOfLikes ?? 0"></td>
                        <td app-table-cell [value]="review.numberOfComments ?? 0"></td>
                        <td app-table-cell [value]="review.rating"></td>
                        <td app-table-cell [value]="review.comment"></td>
                    </tr>
                </app-table>
                <br />
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>