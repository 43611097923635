<app-header text="Swim Spot Details"></app-header>
<app-label text="ID"></app-label>
<app-paragraph [text]="swimSpot?.id?.toString() ?? ' &nbsp;'"></app-paragraph>
<!-- <a [routerLink]="['','swimspotsupdate',swimSpot?.id]">Open Swim Spot</a> -->
<app-label text="Name"></app-label>
<app-paragraph [text]="swimSpot?.name?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Description"></app-label>
<app-paragraph [text]="swimSpot?.description?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Swimming"></app-label>
<app-paragraph [text]="swimSpot?.swimming?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Water Ski"></app-label>
<app-paragraph [text]="swimSpot?.waterskiing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Wateboarding"></app-label>
<app-paragraph [text]="swimSpot?.wakeboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Kneeboarding"></app-label>
<app-paragraph [text]="swimSpot?.kneeboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Surfing"></app-label>
<app-paragraph [text]="swimSpot?.surfing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Bodyboarding"></app-label>
<app-paragraph [text]="swimSpot?.bodyboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Body Surfing"></app-label>
<app-paragraph [text]="swimSpot?.bodysurfing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Canoeing"></app-label>
<app-paragraph [text]="swimSpot?.canoeing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Kayaking"></app-label>
<app-paragraph [text]="swimSpot?.kayaking?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Windsurfing"></app-label>
<app-paragraph [text]="swimSpot?.windsurfing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Kiteboarding"></app-label>
<app-paragraph [text]="swimSpot?.kiteboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Sailing"></app-label>
<app-paragraph [text]="swimSpot?.sailing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Skim Boarding"></app-label>
<app-paragraph [text]="swimSpot?.skimboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Scuba Diving"></app-label>
<app-paragraph [text]="swimSpot?.scubadiving?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Snorkeling"></app-label>
<app-paragraph [text]="swimSpot?.snorkeling?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="White Water Rafting"></app-label>
<app-paragraph [text]="swimSpot?.whitewaterrafting?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Free Diving"></app-label>
<app-paragraph [text]="swimSpot?.freediving?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Canyoning"></app-label>
<app-paragraph [text]="swimSpot?.canyoning?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Hydrofoil"></app-label>
<app-paragraph [text]="swimSpot?.hydrofoil?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Yachting"></app-label>
<app-paragraph [text]="swimSpot?.yachting?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Flyboarding"></app-label>
<app-paragraph [text]="swimSpot?.flyboarding?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Rowing"></app-label>
<app-paragraph [text]="swimSpot?.rowing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Ice Diving"></app-label>
<app-paragraph [text]="swimSpot?.icediving?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Triathlon"></app-label>
<app-paragraph [text]="swimSpot?.triathlon?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Swim Run"></app-label>
<app-paragraph [text]="swimSpot?.swimrun?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Walking"></app-label>
<app-paragraph [text]="swimSpot?.walking?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Ice Swimming"></app-label>
<app-paragraph [text]="swimSpot?.iceswimming?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Coasteering"></app-label>
<app-paragraph [text]="swimSpot?.coasteering?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Getting There"></app-label>
<app-paragraph [text]="swimSpot?.gettingthere?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Accessibility"></app-label>
<app-paragraph [text]="swimSpot?.accessibilityinformation?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Gentle Slope"></app-label>
<app-paragraph [text]="swimSpot?.gentleSlope?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Big Drop Off"></app-label>
<app-paragraph [text]="swimSpot?.bigDropOff?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Easy Walk"></app-label>
<app-paragraph [text]="swimSpot?.easyToWalkTo?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Beach"></app-label>
<app-paragraph [text]="swimSpot?.hasABeach?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Muddy"></app-label>
<app-paragraph [text]="swimSpot?.itIsMuddy?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Reeds"></app-label>
<app-paragraph [text]="swimSpot?.lotsOfReeds?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Mountains"></app-label>
<app-paragraph [text]="swimSpot?.mountainViews?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Nearby Parking"></app-label>
<app-paragraph [text]="swimSpot?.nearbyParking?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Quiet"></app-label>
<app-paragraph [text]="swimSpot?.quietLocation?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Rocky"></app-label>
<app-paragraph [text]="swimSpot?.rockyGround?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Steps"></app-label>
<app-paragraph [text]="swimSpot?.stepsToGetIn?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Children"></app-label>
<app-paragraph [text]="swimSpot?.suitableForKids?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Paddling"></app-label>
<app-paragraph [text]="swimSpot?.paddling?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Skinny Dip"></app-label>
<app-paragraph [text]="swimSpot?.skinnyDip?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Train Access"></app-label>
<app-paragraph [text]="swimSpot?.trainAccess?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Cycling"></app-label>
<app-paragraph [text]="swimSpot?.cycling?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Boating"></app-label>
<app-paragraph [text]="swimSpot?.boating?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Supping"></app-label>
<app-paragraph [text]="swimSpot?.supping?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Pubs"></app-label>
<app-paragraph [text]="swimSpot?.pubs?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Camping"></app-label>
<app-paragraph [text]="swimSpot?.camping?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Jumping"></app-label>
<app-paragraph [text]="swimSpot?.jumping?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Cliffs"></app-label>
<app-paragraph [text]="swimSpot?.cliffs?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Bridges"></app-label>
<app-paragraph [text]="swimSpot?.bridges?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Chutes"></app-label>
<app-paragraph [text]="swimSpot?.chutes?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Waterfalls"></app-label>
<app-paragraph [text]="swimSpot?.waterfalls?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Dog Friendly"></app-label>
<app-paragraph [text]="swimSpot?.dogs?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Accessible"></app-label>
<app-paragraph [text]="swimSpot?.accessibility?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Pay to swim"></app-label>
<app-paragraph [text]="swimSpot?.payToSwim?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Private Property"></app-label>
<app-paragraph [text]="swimSpot?.privateProperty?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Swing"></app-label>
<app-paragraph [text]="swimSpot?.swing?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Long Swim"></app-label>
<app-paragraph [text]="swimSpot?.longSwim?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Picnic"></app-label>
<app-paragraph [text]="swimSpot?.picnic?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Sunset"></app-label>
<app-paragraph [text]="swimSpot?.sunset?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Pools"></app-label>
<app-paragraph [text]="swimSpot?.pools?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Weir"></app-label>
<app-paragraph [text]="swimSpot?.weir?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Pebbles"></app-label>
<app-paragraph [text]="swimSpot?.pebbles?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Caves"></app-label>
<app-paragraph [text]="swimSpot?.caves?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Estuary"></app-label>
<app-paragraph [text]="swimSpot?.estuary?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Lido"></app-label>
<app-paragraph [text]="swimSpot?.lido?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Toilets"></app-label>
<app-paragraph [text]="swimSpot?.toilets?.toString() ?? ' &nbsp;'"></app-paragraph>
<app-label text="Quarry"></app-label>
<app-paragraph [text]="swimSpot?.quarry?.toString() ?? ' &nbsp;'"></app-paragraph>