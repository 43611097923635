import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { MailingListType } from 'src/shared/data/enums/mailinglist_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class MailinglistRepositoryService {

  constructor(private readonly afs: Firestore, private firestore: FirestoreService) { }

  async add(email: string) {
    await this.firestore.create('mailinglist', { email: email, type: MailingListType.USER }).catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      throw Error(errorMessage);
    });
  }

  async addOrganiser(email: string) {
    await this.firestore.create('mailinglist', { email: email, type: MailingListType.ORGANISER })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        throw Error(errorMessage);
      });
  }
}

