import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { BadgeModel } from 'src/shared/data/models/badge_model';
import { MessageModel } from 'src/shared/data/models/message_model';

@Injectable({
  providedIn: 'root'
})
export class MessagesDataService {


  constructor(
    private firestore: FirestoreService,
  ) { }

  latest(): Observable<MessageModel[]> {
    return this.firestore.getCollectionGroupListByQuery("messages", [
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 50
      }
    ])
  }

}
