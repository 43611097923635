import { Injectable } from '@angular/core';
import { Currency } from 'src/shared/data/enums/currency';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    constructor() { }

    getSymbol(currency: Currency): string {
        var symbol = "";
        switch (currency) {
            case Currency.GBP:
                symbol = "£";
                break;
            case Currency.EUR:
                symbol = "€";
                break;
            case Currency.USD:
                symbol = "$";
                break;
            default:
                break;
        }
        return symbol;
    }

    priceToReadable(price: number): string {
        return (price / 100).toFixed(2);
    }

    priceToReadableNumber(price: number): number {
        return parseFloat((price / 100).toFixed(2));
    }

    readableToPrice(price: number): number {
        var stringPrice = price.toString();
        var split = stringPrice.split(".");
        var pounds = split[0];
        while (pounds.charAt(0) === '0') {
            pounds = pounds.substring(1);
        }
        var pence = split.length > 1 ? split[1] : "";
        while (pence.charAt(0) === '0') {
            pence = pence.substring(1);
        }
        if (pence.length == 0) {
            pence = "00";
        } else if (pence.length == 1) {
            pence = pence + "0";
        }
        var int = parseInt(pounds + pence);
        return int;
    }

}
