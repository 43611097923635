<app-wrapper header="Badge Configs" icon="plus" ctatext="Add Config" (onClick)="new()">
    <div *ngIf="!configs$">
        <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div *ngIf="configs$">
        <ng-container *ngIf="configs$ | async as configs ; else loadingOrError">
            <div *ngIf="!configs || configs.length == 0; else configstable">
                <app-empty icon="shield-alt" header="No Configs" description="Add a config record">
                </app-empty>
            </div>
            <ng-template #configstable>
                <app-table [headers]="[{
                    header: 'Title'
                  },{
                    header: 'Source Document'
                  },{
                    header: 'Status'
                  }, {
                    header: ''
                  }]">
                    <tr class="cursor-pointer" (click)="open(config.id)" *ngFor="let config of configs">
                        <td app-table-cell [value]="config.title"></td>
                        <td *ngIf="config.sourceDocumentType != SourceDocumentType.ACTIVITYPOST && config.sourceDocumentType != SourceDocumentType.USERPROFILE"
                            app-table-cell [value]="'Unknown'"></td>
                        <td *ngIf="config.sourceDocumentType == SourceDocumentType.ACTIVITYPOST" app-table-cell
                            [value]="'Activity Post'"></td>
                        <td *ngIf="config.sourceDocumentType == SourceDocumentType.USERPROFILE" app-table-cell
                            [value]="'User Profile'"></td>
                        <td *ngIf="config.status != 1 && config.status != 2" app-table-cell [value]="'Unknown'"></td>
                        <td *ngIf="config.status == 1" app-table-cell [value]="'Draft'"></td>
                        <td *ngIf="config.status == 2" app-table-cell [value]="'Live'"></td>
                        <td app-table-cell [cta]="true" (onClick)="open(config.id)" value="edit"></td>
                    </tr>
                </app-table>
                <br />
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>