<app-wrapper header="Posts">
  <div *ngIf="!posts$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="posts$">
    <ng-container *ngIf="posts$ | async as posts ; else loadingOrError">
      <div *ngIf="!posts || posts.length == 0; else poststable">
        <app-empty icon="edit" header="No Posts" description="New posts will appear here">
        </app-empty>
      </div>
      <ng-template #poststable>
        <app-table [headers]="[{
                    header: 'Created'
                  },{
                    header: 'Username'
                  },{
                    header: 'Spot'
                  },{
                    header: 'Likes'
                  },{
                    header: 'Comments'
                  },{
                    header: 'Source Document'
                  },{
                    header: 'Header'
                  },{
                    header: 'Message'
                  }, {
                    header: ''
                  }]">
          <tr *ngFor="let post of posts">
            <td app-table-cell [value]="readableDate(post?.creationDate?.toDate())"></td>
            <td app-table-cell [value]="post.username"></td>
            <td app-table-cell [cta]="true" (onClick)="openspot(post.sourceDocumentId)" value="open spot"></td>
            <td app-table-cell [value]="post.numberOfLikes ?? 0"></td>
            <td app-table-cell [value]="post.numberOfComments ?? 0"></td>
            <td app-table-cell [value]="post.sourceDocumentName"></td>
            <td app-table-cell [value]="post.header"></td>
            <td app-table-cell [value]="post.message"></td>
            <td app-table-cell [cta]="true" (onClick)="open(post.id)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>