export enum ErrorType {
    //0
    UNKNOWN,
    //1
    TIMEOUT,
    //2
    USERIDNOTFOUND,
    //3 - 401
    ACCESSDENIED,
    //4
    INVALIDARGUMENT400,
    //5
    PERMISSIONDENIED403,
    //6
    NEEDSINDEX,
    //7
    INDEXBEINGBUILT,
    //8
    INVALIDQUERY,
    //9
    MISSINGLATITUDEONSEARCH,
    //10
    MISSINGLONGITUDEONSEARCH,
    //11
    MISSINGQUERYONSEARCH,
    //12
    ROUTENOTFOUND,
    //13
    UNKNOWNERROR,
    //14
    UNKNOWNHTTPERRORONCLIENT,
    //15
    GETDOCUMENTBYQUERY,
    //16
    MULTIPLEDOCUMENTSFOUNDFORSINGLEDOCUMENTQUERY,
    //17
    GETLISTBYQUERY,
    //18
    GETCOLLECTIONGROUPLISTBYQUERY,
    //19
    GETLISTFROMSUBCOLLECTIONBYQUERY,
    //20
    GETLISTFROMSUBSUBCOLLECTIONBYQUERY,
    //21
    GETQUERYREFERENCESERROR,
    //22
    REALTIMEDATABASELISTERROR,
    //23
    REALTIMEDATABASEUPDATE,
    //24
    ACCESSDENIEDNOAUTHFOUNDONBUSINESSCHECKMIDDLEWARE,
    //25
    ACCESSDENIEDWHENCHECKINGBUSINESS,
    //26
    ACCESSDENIEDNOTAPARTOFTHISCOMPANY,
    //27
    RECORDNOTFOUND,
    //28
    COULDNOTCREATESTRIPEACCOUNT,
    //29
    UNKNOWNCURRENCY,
    //30
    UNKNOWNTIMEZONE,
    //31
    UNKNOWNALPHATWO,
    //32
    CLOUDSEARCHERROR,
    //33
    UNHANDLEDSTRIPEWEBHOOK,
    //34
    NOTANADMINUSER,
    //35
    FAILEDONCHECKINGFORADMINUSER,
    //36
    CURRENCYISONOTFOUND,
    //37
    CURRENCTNOTFOUNDFORCOUNTRY,
    //38
    CURRENCTNOTFOUNDWHENCONVERTINGFROMSTRIPECURRENCYCODE,
    //39
    ACTIVITYNOTFOUNDWHENGENERATINGUSERACTIVITYDTO,
    //40
    COULDNOTFINDBUSINESSWHENGENERATINGUSERFACINGORDER,
    //41
    COULDNOTGETIANATIMEZONESTRINGFORTIMEZONE,
    //42
    COULDNOTGETTIMEZONEFORCOUNTRY,
    //43
    REFUNDALREADYPROCESSEDBYSTRIPE,
    //44
    COULDNOTFINDSCHEDULEFREQUENCY,
    //45
    COULDNOTFINDSCHEDULEDAYFORRECURRENCE,
    //46
    COULDNOTGETDAYOFWEEKFORSCHEDULEPATTERN,
    //47
    COULDNOTGETPOSITIONTODEFINEREPEATRULE,
    //47
    TRYINGTOACCESSSUNCREATEDSCHEDULEDRECORDANDNOTTRYINGTOCREATEITEITHER,
    //48
    COULDNOTGETTIMEFORGENERATINGSCHEDULEUNKNOWNTIME,
    //49
    SLOTANDSCHEDULEOFBOTHNULLADDINGTOCART,
    //50
    USERALREADYADDEDTOACTIVITYWHENADDINGTOCART,
    //51
    CONVERTEDACTIVITYNOTFOUND,
    //52
    TRYINGTOBOOKANOVERCAPACTITYACTIVITY,
    //53
    USERTRYINGTOBOOKANACTIVITYTHEYAREALREADYON,
    //54
    UNRECOGNISEDTIMEPERIODGETTINGUSERACTIVITIES,
    //55
    ORGANISERHASCHANGEDASCHEDULEDEVENTWHILSTAUSERISTRYINGTOCONVERTIT,
    //56
    UNKNOWNLOCATIONTYPEONUPDATINGLOCATION,
    //57
    CAUGHTINTRYCATCHONBUSINESSMIDDLEWARE,
    //58
    ORDERNULLWHENSENDINGUSERCONFIRMATION,
    //59
    ORDERNULLWHENSENDINGBUSINESSCONFIRMATION,
    //60
    ACTIVITYNULLWHENSENDINGUSERCACTIVITYDETAILS,
    //61
    BUSINESSNOTFOUNDONUSERVONFIRMATIONORDEREMAIL,
    //62
    BUSINESSNOTFOUNDONBUSINESSCONFIRMATIONEMAIL,
    //63
    BUSINESSNOTFOUNDONACTIVITYCONFIRMATIONEMAIL,
    //64
    CANTGETSERVICEONACTIVITYTOSENDEMAIL,
    //65
    NOOWNERSORADMINSRETURNEDTOSENDCONFIRMATIONEMAILTOO,
    //66
    ACTIVITYNOTFOUNDFORUSERORDERCONFIRMATIONEMAILORDERLINE,
    //67
    ACTIVITYNOTFOUNDFORBUSINESSORDERCONFIRMATIONEMAILORDERLINE,
    //68
    ACTIVITYNOTFOUNDWHENABANDONINGCART,
    //69
    SERVICENOTFOUNDWHENABANDONINGCART,
    //70
    CANNOTDELETEPRICEBECAUSEITISINUSEONACTIVITIES,
    //71
    COMPANYURLALREADYTAKENDURINGONBOARDING,
    //72
    TRYINGTOREQUESTANACTIVITYTHATDOESNOTBELONGTOTHEM,
    //73
    COULDNOTFINDUSERINSHOPPINGCARTIDWHENREMOVINGFROMCART,
    //74
    COULDNOTFINDAVTICITYONUSERREFUND,
    //75
    USERTRYINGTOREFUNDORDERLINEINTHEPAST,
    //76
    USERTRYINGTOCANCELTOSOONTOTHEACTIVITYSTARTDATE,
    //77
    TRYINGTOUPDATEACTIVITYWHENTIMEISGREATERTHANADAYANDNOTSETTOALLDAY,
    //78
    TRYINGTOADDACTIVITYWHENTIMEISGREATERTHANADAYANDNOTSETTOALLDAY,
    //79
    CANNOTFINDCONTACTIDINCONTACTARRAYWHENREMOVING,
    //80
    CANNOTFINDCONTACTRECORDINCONTACTARRAYWHENREMOVING,
    //81
    CANTFINDORDERFORUSERREFUND,
    //82
    CANTFINDORDERLINEFORUSERREFUND,
    //83
    CANTFINDORDERFORBUSINESSREFUND,
    //84
    CANTFINDORDERLINEFORBUSINESSREFUND,
    //85
    NOOWNERSORADMINSTOSENDEMAILTOFORREFUND,
    //86
    BUSINESSNOTFOUNDFORREFUNDEMAILTOBUSINESS,
    //86
    BUSINESSPUBLICNOTFOUNDFORREFUNDEMAILTOBUSINESS,
    //97
    ACTIVITYNOTFOUNDFORBUSINESSREFUNDEMAIL,
    //98
    BUSINESSPUBLICNOTFOUNDFORUSERREFUNDEMAIL,
    //99
    BUSINESSNOTFOUNDFORUSERREFUNDEMAIL,
    //100
    ACTIVITYNOTFOUNDFORUSERREFUNDEMAIL,
    //101
    CANNOTFINDBUSINESSWHENSENDINGEMAILTOADDEDCONTACT,
    //102
    CANNOTFINDBUSINESSPUBLICWHENSENDINGEMAILTOADDEDCONTACT,
    //103
    CANNOTFINDSERVICEWHENSENDINGEMAILTOADDEDCONTACT,
    //104
    CANNOTFINDBUSINESSWHENSENDINGEMAILTOREMOVEDCONTACT,
    //105
    CANNOTFINDBUSINESSPUBLICWHENSENDINGEMAILTOREMOVEDCONTACT,
    //106
    CANNOTFINDSERVICEWHENSENDINGEMAILTOREMOVEDCONTACT,
    //107
    CANNOTFINDUSERTOUPDATESENDINBLUE,
    //108
    CANNOTFINDUSERPROFILETOUPDATESENDINBLUE,
    //109
    CANNOTFINDBUSINESSUSERTOUPDATESENDINBLUE,
    //108
    CANNOTFINDBUSINESSUSERPROFILETOUPDATESENDINBLUE,
    //109
    CANNOTFINDTEAMMEMBERTOSENDTOSENDINBLUE,
    //110
    CANNOTFINDBUSINESSFORSENDINBLUECONTACT,
    //111
    CANNOTFINDBUSINESSPUBLICFORSENDINBLUECONTACT,
    //112
    SWIMSPOTALREADYEXISTSONBUSINESS,
    //113
    TRYINGTOADDMORETHANTENSPOTSAGAINSTBUSINESS,
    //114
    ERRORGENERATINGCUSTOMTOKEN,
    //115
    CANNOTFINDCOUPONWHENPROCESSINGSUCCESFULPAYMENT,
    //116
    CANNOTFINDDURATIONTYPEWHENPROCESSINGSUCCESSFULPAYMENTFORCOUPON,
    //117
    COUPONNOTFOUNDWHENTRYINGTOAPPLYITBYCODE,
    //118
    TRYINGTOUSECOUCHERTHATHASALREADYBEENUSED,
    //119
    TRYINGTOUSEVOUCHERTHATHASEXPIRED,
    //120
    COUPONNOTFOUNDFROMCOUPONCREDITRECORDWHENAPPLYCOUPON,
    //121
    TRYINGTOCHECKOUTFORFREEWHENORDERHASTOTALGREATERTHANZERO,
    //122
    TRYINGTOAPPLYCOUPONATCHECKOUTWHENONEHASALREADYBEENAPPLIED,
    //123
    COUPONNOTAPPLICABLETOANYORDERLINEWHENAPPLIEDATCHECKOUT,
    //124
    UNKNOWNLOCATIONBASEDMESSAGETYPE,
    //125
    INSUFFICIENTFUNDSFORREFUND,
    //126
    USERDIDNOTGRANTACCESSTOGARMINONTHEFRONTEND,
    //127
    GARMINACTIVITYPROCESSINGERROR,
    //128
    GARMINACTIVITYINSERTERROR,
    //129
    USERDIDNOTGRANTACCESSTOSUUNTOONTHEFRONTEND,
    //130
    ERRORGETTINGDOCUMENTCOUNT,
    //131
    NOTINREQUIREDROLE,
    //132
    FAILEDONCHECKINGROLEBASEDACCESS,
    //133
    FAILEDONCHECKINGROLEBASEDACCESSFRONTEND,
    //134
    SERVICENOLONGERAVAILABLE,
    //135
    USERDIDNOTGRANTACCESSTOFITBITONTHEFRONTEND,
    //136
    TRYINGTOABANDONCARTWHENPAYMENTINPROGRESS,
    //137
    USERTRIEDTOCANCELBUTBUSINESSDISABLEDREFUNDS,
    //138
    NODOCUMENTTOUPDATE,

}