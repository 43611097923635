/*
WARNING - ALWAYS ADD NEW ENUMS TO BOTTOM OF THIS LIST
*/
export enum WildNavigationType {
    UNKNOWN,
    DASHBOARDHOME,
    LOGIN,
    REGISTER,
    GROUPS,
    TEAM,
    SEARCH,
    BOOKINGBASKET,
    BOOKINGCHECKOUT,
    INVITE,
    FORGOTTONPASSWORD,
    USERACTIVITIES,
    USERACTIVITYDETAILS,
    BRAND,
    SETTINGS,
    BUSINESSFINANCE,
    NEWSWIMSPOTFROMBUSINESS,
    BUSINESSSEARCHRESULTS,
    BUSINESSSWIMSPOTDETAILS,
    USERDASHBOARD,
    BROCHURESWIMSPOT,
    PRICING,
    CLASSPASSES,
    BUSINESSCONTACTS,
    BUSINESSORDERS,
    USERNAMEPAGE,
    HOME,
    BUSINESSACTIVITIES,
    ROADMAP,
    BUSINESSUSERSETTINGS,
    SOS,
    BROCHURESEARCHRESULTS,
    USERORDERS,
    TERMS,
    BUSINESSSTATUS,
    PRIVACY,
    ONBOARDING,
    BUSINESSLOCATION,
    BUSINESSAREA,
    BUSINESSUPDATEMYDETAILS,
    BUSINESSBANK,
    BUSINESSPAYOUTS,
    NEWSLETTER,
    ABOUT,
    BOOKINGUSERDETAILS,
    CONTACT,
    USERSEARCHRESULTS,
    FAVOURITES,
    MYSWIMSPOTS,
    BUSINESSSWIMSPOTS,
    USERSWIMSPOTDETAILS,
    USERPROFILEPAGE,
    USERADDSWIMSPOT,
    NOTFOUND,
    BUSINESSSERVICES,
    BUSINESSSERVICEDETAILS,
    USERNOTIFICATIONS,
    BECOMEANORGANISER,
    BUSINESSCONFIGURATION,
    BUSINESSCALENDAR,
    DEMO,
    CLASSPASSESEDIT,
    BUSINESSMODE,
    //PORTAL
    PORTALSWIMSPOTDETAILS,
    ORDERCONFIRMATION,
    USERVOUCHERS,
    DIRECTORY,
    WIDGETINTEGRATION,
    INTEGRATIONS,
    ONBOARDINGV2,
    GARMININTEGRATION,
    BUSINESSPLUGINS,
    BUSINESSROADMAP,
    BUSINESSCATEGORIES,
    SUUNTOINTEGRATION,
    FITBITINTEGRATION,
    BOOKERDETAILS,
    LINKEDACCOUNTS,
    WHERETO,
    IFRAMEINTEGRATION,
    WELCOMEEMAIL,
    BUSINESSONLINEBOOKINGSETTINGS,
    TIMETABLES,
    TIMETABLESDETAILS
}