<app-wrapper *ngIf="content" header="Content Update">
    <app-form [form]="form" (onSubmit)="update($event)">
        <app-header text="Creation Date"> </app-header>
        <p *ngIf="content.creation_date">{{content.creation_date.toDate() | date: 'yyyy-MM-dd'}}</p>
        <small *ngIf="!content.creation_date">Unknown</small>
        <app-inputgroup>
            <app-input [form]="form" name="title" description="Title" [span]=6></app-input>
        </app-inputgroup>
        <app-inputgroup>
            <app-input [form]="form" name="image" description="Image URL" [span]=6></app-input>
        </app-inputgroup>
        <app-inputgroup>
            <app-input [form]="form" name="ctacopy" description="CTA Copy" [span]=3></app-input>
            <app-input [form]="form" name="ctalink" description="CTA Link" [span]=3></app-input>
        </app-inputgroup>
        <app-inputgroup>
            <app-textarea [form]="form" name="description" description="Description" [span]=6></app-textarea>
        </app-inputgroup>
        <app-inputgroup>
            <app-select [options]="statusOptions" [form]="form" name="status" description="Status" [span]=3>
            </app-select>
            <app-select [options]="provideOptions" [form]="form" name="provider" description="Provider" [span]=3>
            </app-select>
        </app-inputgroup>
        <div [formGroup]="form">
            <div formArrayName="sections">
                <div class="mb-4" *ngFor="let section of sections.controls; let pointIndex=index"
                    [formGroupName]="pointIndex">
                    <app-inputgroup>
                        <app-select [options]="sectionOptions" [form]="section" name="type" description="Type" [span]=1>
                        </app-select>
                        <app-textarea [form]="section" name="content" description="Content" [span]=5>
                        </app-textarea>
                    </app-inputgroup>
                    <div class="flex justify-end">
                        <button class="mt-3 wild-btn-primary" type="button" (click)="removeSection(pointIndex)">Delete
                            Section
                            {{pointIndex + 1}}</button>
                    </div>
                    <div class="flex justify-end ">
                        <a class="mt-3 wild-text-primary underline cursor-pointer text-xs"
                            (click)="addSection(0,'Populate me',pointIndex)" type="button">add
                            section below</a>
                    </div>
                    <div class="flex justify-end ">
                        <div>
                            <a class="mt-3 wild-text-primary cursor-pointer text-xl mr-4"
                                (click)="moveSection(pointIndex,true)" type="button">☝️</a>
                            <a class="mt-3 wild-text-primary cursor-pointer text-xl"
                                (click)="moveSection(pointIndex,false)" type="button">👇</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <button class="wild-btn-primary mt-3" (click)="addSection(0,'Populate me')" type="button">Add Section</button>
        <app-header text="Tags"></app-header>
        <div class="flex justify-between lg:w-1/2" *ngFor="let tag of content.tags">
            <p>{{tag}}</p>
            <p class="underline cursor-pointer" (click)="removeTag(tag)">X</p>
        </div>
        <app-form [form]="tagsForm" (onSubmit)="addTag($event)" ctaText="Add Tag">
            <app-inputgroup>
                <app-input [form]="tagsForm" name="tag" description="Add Tags" [span]=6></app-input>
            </app-inputgroup>
        </app-form>
    </app-form>
</app-wrapper>