import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { AlertStatus } from 'src/shared/data/enums/alert_status';
import { AlertModel } from 'src/shared/data/models/alert_model';

@Injectable({
  providedIn: 'root'
})
export class AlertsDataService {

  collection: string = "alerts";

  constructor(
    private firestore: FirestoreService,
  ) { }

  latest(): Observable<AlertModel[]> {
    return this.firestore.getListByQuery("alerts", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "in",
        property: "status",
        value: [AlertStatus.LIVE]
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 30
      }
    ])
  }

}
