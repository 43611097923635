import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { ImageStatus } from 'src/shared/data/enums/image_status';
import { ImageModel } from 'src/shared/data/models/images_model';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ImagesDataService {

  collection: string = "images";

  constructor(
    private firestore: FirestoreService,
    private crud: CrudService
  ) { }

  list(sourceDocumentId: string): Observable<ImageModel[]> {
    return this.firestore.getListByQuery("images", [
      {
        operator: "==",
        property: "sourceDocumentId",
        type: FirestoreQueryType.WHERE,
        value: sourceDocumentId
      },
      {
        operator: "in",
        property: "status",
        type: FirestoreQueryType.WHERE,
        value: [ImageStatus.INREVIEW, ImageStatus.LIVE]
      }
    ])
  }

  async create(data: ImageModel) {
    await this.crud.create(this.collection, data);
  }

  read(id: string): Observable<ImageModel> {
    return this.crud.read(this.collection, id).get().pipe(map((a: any) => {
      const data = a.data() as ImageModel;
      const id = a.id;
      return { id, ...data };
    }));
  }

  latest(): Observable<ImageModel[]> {
    return this.firestore.getListByQuery("images", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "in",
        property: "status",
        value: [ImageStatus.LIVE]
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 50
      }
    ])
  }


  async update(data: ImageModel) {
    await this.crud.update(this.collection, data);
  }

}
