import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FunctionsService } from 'src/portal/services/functions.service';
import { ImagesDataService } from 'src/portal/services/images-data.service';
import { PostsDataService } from 'src/portal/services/posts-data.service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { ImageStatus } from 'src/shared/data/enums/image_status';
import { PostType } from 'src/shared/data/enums/post_type';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';
import { ImageModel } from 'src/shared/data/models/images_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  selector: 'app-imagesupdate',
  templateUrl: './imagesupdate.component.html',
  styleUrls: ['./imagesupdate.component.scss']
})
export class ImagesupdateComponent implements OnInit {

  passinimage!: ImageModel;
  form!: UntypedFormGroup;
  src: string;
  image!: ImageModel;
  SourceDocuementType = SourceDocumentType;
  postType: PostType;
  statusOptions: SelectModel[] = [
    {
      name: "Live",
      value: ImageStatus.LIVE
    },
    {
      name: "Deleted",
      value: ImageStatus.DELETED
    },
    {
      name: "Unknown",
      value: ImageStatus.UNKNOWN
    },
    {
      name: "In Review",
      value: ImageStatus.INREVIEW
    }
  ]
  sourceDocumentImageTypeOptions: SelectModel[] = [
    {
      name: "Swim Spot",
      value: SourceDocumentType.SWIMSPOT
    },
    {
      name: "Swim Spot Main Pic",
      value: SourceDocumentType.SWIMSPOTMAINPIC
    },
    {
      name: "Swim Spot Post",
      value: SourceDocumentType.SWIMSPOTPOST
    },
    {
      name: "Business",
      value: SourceDocumentType.BUSINESS
    },
    {
      name: "Business Post",
      value: SourceDocumentType.BUSINESSPOST
    },
    {
      name: "Business Team Member",
      value: SourceDocumentType.BUSINESSTEAMMEMBER
    },
    {
      name: "User Profile",
      value: SourceDocumentType.USERPROFILE
    },
    {
      name: "Waterway",
      value: SourceDocumentType.WATERWAY
    },
    {
      name: "Waterway Main Pic",
      value: SourceDocumentType.WATERWAYMAINPIC
    },
    {
      name: "Unknown",
      value: SourceDocumentType.UNKNOWN
    },
  ]


  constructor(
    private route: ActivatedRoute,
    private data: ImagesDataService,
    private postData: PostsDataService,
    private notify: NotifyService,
    private functions: FunctionsService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.passinimage != null) {
      this.image = this.passinimage;
      this.src = environment.imagesUrl + this.image.image + "_200x200.jpeg?alt=media";
      this.dataLoaded();
    } else {
      this.route.params.subscribe((params: Params) => {
        this.data.read(params.id).subscribe((data) => {
          this.image = data;
          this.src = environment.imagesUrl + this.image.image + "_200x200.jpeg?alt=media";
          this.dataLoaded();
        }, (error) => {
          alert(error.message);
        });
      });
    }

  }

  dataLoaded() {
    this.form = this.fb.group({
      status: new UntypedFormControl(this.image?.status ?? ImageStatus.UNKNOWN, [Validators.required]),
      caption: new UntypedFormControl(this.image?.caption ?? "", []),
      createdBy: new UntypedFormControl(this.image?.createdBy ?? "", []),
      sourceDocumentId: new UntypedFormControl(this.image?.sourceDocumentId ?? "", []),
      sourceDocumentImageType: new UntypedFormControl(this.image?.sourceDocumentImageType ?? SourceDocumentType.UNKNOWN, [Validators.required]),
    });
    if (this.image.sourceDocumentImageType == SourceDocumentType.SWIMSPOT)
      this.postType = PostType.NEWSWIMSPOTIMAGE
    if (this.image.sourceDocumentImageType == SourceDocumentType.WATERWAY)
      this.postType = PostType.NEWWATERWAYIMAGE

  }

  async update(data: any) {
    const post = {
      ...this.image,
      ...data,
    }
    if (this.image) {
      await this.data
        .update(post)
        .then(() => {
          this.notify.notify("Updated", "", NotifyType.SUCCESS);
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    } else {
      await this.data
        .create(post)
        .then(() => {
          this.notify.notify("Added", "", NotifyType.SUCCESS);
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    }
  }

  handlerError(message: string) {
    alert(message);
  }

}
