<app-wrapper header="Garmin Activities">
  <div class="flex flex-wrap">
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.UNKNOWN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Unknown Status</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.CREATED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Created Post</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.FAILED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>FAILED</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.ALREADYPROCESSED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Already Processed & created Post</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.NOUSERACCESSTOKEN)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>No User access token</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.COULDNOTFINDUSERASSOCIATEDWITHACTIVITY)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Couldn't find Wild user for this activity</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.NOTWATERBASED)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>Not Water based. Shouln't be here. Check ping creation filters</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.NOPOLYLINE)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>No polyline</p>
    </div>
    <div [ngStyle]="{'background-color': getStatusColor(GarminActivityStatus.NOPOLYLINEMETADATA)}"
      class="mr-4 p-1 mb-4 text-sm rounded-lg">
      <p>No polyline meta</p>
    </div>
  </div>
  <div *ngIf="!activities$">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="activities$">
    <ng-container *ngIf="activities$ | async as activities ; else loadingOrError">
      <div *ngIf="!activities || activities.length == 0; else activitiestable">
        <app-empty icon="edit" header="No activities" description="New activities will appear here">
        </app-empty>
      </div>
      <ng-template #activitiestable>
        <app-table [headers]="[{
                    header: 'Generated Date'
                  },{
                    header: 'Activity Date'
                  },{
                    header: 'Activity ID'
                  },{
                    header: 'Email'
                  },{
                    header: 'Wild User Id'
                  },{
                    header: 'Name'
                  },{
                    header: 'Type'
                  },{
                    header: 'User Access Token'
                  },{
                    header: 'Summary ID'
                  },{
                    header: 'User Id'
                  },{
                    header: 'WILD ID'
                  },{
                    header: 'Status'
                  }]">
          <tr [ngStyle]="{'background-color': getStatusColor(activity.status)}" *ngFor="let activity of activities">
            <td app-table-cell [value]="readableDate(activity?.generatedDate?.toDate())"></td>
            <td app-table-cell [value]="readableDateFromEpocy(activity?.summary?.startTimeInSeconds ?? 0)">
            </td>
            <td app-table-cell [value]="activity.activityId"></td>
            <td app-table-cell [value]="activity?.email ?? 'unknown'"></td>
            <td app-table-cell [value]="activity?.wildUserId ?? 'unknown'"></td>
            <td app-table-cell [value]="activity.summary?.activityName"></td>
            <td app-table-cell [value]="activity.summary?.activityType"></td>
            <td app-table-cell [value]="activity.userAccessToken"></td>
            <td app-table-cell [value]="activity.summaryId"></td>
            <td app-table-cell [value]="activity.userId"></td>
            <td app-table-cell [value]="activity.id ?? ''"></td>
            <td app-table-cell [value]="activity.status"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>