<app-wrapper *ngIf="swimspot" header="Swim Spot Update">
  <h3 class="text-sm text-grey-500">Status</h3>
  <h2 *ngIf="swimspot.status == SwimSpotStatus.LIVE" class="text-green-500">LIVE</h2>
  <h2 *ngIf="swimspot.status != SwimSpotStatus.LIVE" class="text-red-500">NOT LIVE</h2>
  <h3 class="text-sm text-grey-500">Creator Notified about approval</h3>
  <h2 *ngIf="swimspot.notifiedCreatorAboutVerification" class="text-green-500">YES</h2>
  <h2 *ngIf="!swimspot.notifiedCreatorAboutVerification" class="text-red-500">NO</h2>
  <h3 class="text-sm text-grey-500">Creator Details</h3>
  <app-userprofiledetails [userId]="swimspot.createdBy"></app-userprofiledetails>
  <app-userdetails [swimspot]="swimspot" [userId]="swimspot.createdBy"></app-userdetails>
  <div class="my-6 flex items-center justify-between">
    <div>
      <button type="button" (click)="approve()" type="button" class="wild-btn-primary w-44">
        <span *ngIf="!loading">Approve</span>
        <span class="loader" *ngIf="loading"></span>
      </button>
    </div>
  </div>
  <app-form [floatingsave]="true" [form]="form" (onSubmit)="update($event)" ctaText="Save" [loading]="loading">
    <app-inputgroup>
      <app-input [form]="form" name="name" description="Name" [span]=3 [value]="swimspot.name ?? ''"></app-input>
      <app-input *ngIf="swimspot.status != SwimSpotStatus.LIVE" [form]="form" name="canonical"
        [value]="swimspot.canonical ?? ''" description="Canonical URL" [span]=3></app-input>
      <div *ngIf="swimspot.status == SwimSpotStatus.LIVE">
        <app-header text="Canonical URL"></app-header>
        <small class="text-green-500">{{swimspot.canonical}}</small>
        <small class="text-red-500" *ngIf="!swimspot.canonical">No value set... you need to save this page to trigger
          it</small>
      </div>
    </app-inputgroup>
    <app-inputgroup>
      <app-textarea [form]="form" name="description" description="Description" [span]=6
        [value]="swimspot.description ?? ''"></app-textarea>
    </app-inputgroup>
    <div class="my-6 text-sm">
      <p *ngIf="!swimspot.providerurl">no resource url available</p>
      <p *ngIf="swimspot.providerurl">provider resource url:</p>
      <a class="underline cursor-pointer" target="_blank" *ngIf="swimspot.providerurl"
        [href]="swimspot.providerurl">{{swimspot.providerurl}}</a>
    </div>
    <app-inputgroup>
      <app-textarea [form]="form" name="copywrite" description="Copywrite" [span]=6 [value]="swimspot.copywrite ?? ''">
      </app-textarea>
    </app-inputgroup>
    <app-inputgroup>
      <app-textarea [form]="form" name="gettingthere" description="Getting There" [span]=6
        [value]="swimspot.gettingthere ?? ''"></app-textarea>
    </app-inputgroup>
    <app-inputgroup>
      <app-textarea [form]="form" name="accessibilityinformation" description="Accessibility" [span]=6
        [value]="swimspot.accessibilityinformation ?? ''">
      </app-textarea>
    </app-inputgroup>
    <app-inputgroup>
      <app-textarea [form]="form" name="notes" description="Notes" [span]=6 [value]="swimspot.notes ?? ''">
      </app-textarea>
    </app-inputgroup>
    <app-inputgroup>
      <app-select [options]="statusOptions" [form]="form" name="status" description="Status" [span]=3
        [value]="swimspot.status ?? SwimSpotStatus.UNKNOWN"></app-select>
      <app-select [options]="typeOptions" [form]="form" name="type" description="Type" [span]=3
        [value]="swimspot.type ?? WaterwayType.UNKNOWN"></app-select>
    </app-inputgroup>
    <app-inputgroup>
      <app-input [form]="form" name="latitude" description="Latitude" [span]=3 [value]="swimspot.latitude ?? ''">
      </app-input>
      <app-input [form]="form" name="longitude" description="Longitude" [span]=3 [value]="swimspot.longitude ?? ''">
      </app-input>
    </app-inputgroup>
    <div *ngIf="mapsLoaded | async" class=" w-full my-4" style="height:350px">
      <google-map height="350" width="100%" [options]="options" (mapClick)="updateMarker($event)"
        (zoomChanged)="zoomChanged($event)">
        <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
          [options]="markerOptions"></map-marker>
      </google-map>
    </div>
    <div class="my-6 text-lg text-red-600">
      Zoom Level is <span class="font-bold">{{zoom}}</span>
    </div>
    <div class="my-6 ">
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="p-0 border-0 bg-transparent sm:col-span-2">
          <app-header text="Location"></app-header>
          <div *ngIf="swimspot.location">
            <small class="text-green-500">{{swimspot.location?.town}},
              {{swimspot.location?.county}},{{swimspot.location?.state}},{{swimspot.location?.country}}</small>
            <app-header text="Location Properties - userful for making badges based on location"></app-header>
            <ul>
              <li>area = {{swimspot.location?.area ?? "not populated"}}</li>
              <li>area2 = {{swimspot.location?.area2 ?? "not populated"}}</li>
              <li>area3 = {{swimspot.location?.area3 ?? "not populated"}}</li>
              <li>town = {{swimspot.location?.town ?? "not populated"}}</li>
              <li>locality = {{swimspot.location?.locality ?? "not populated"}}</li>
              <li>county = {{swimspot.location?.county ?? "not populated"}}</li>
              <li>country = {{swimspot.location?.country ?? "not populated"}}</li>
              <li>countryCode = {{swimspot.location?.countryCode ?? "not populated"}}</li>
            </ul>
          </div>
          <div *ngIf="!swimspot.location" class="p-0 border-0 bg-transparent sm:col-span-2">
            <small class="text-red-500">No location yet.</small>
          </div>
        </div>
      </div>
    </div>
    <div class="my-6">
      <h1 class="mb-6">Duplicate Checker</h1>
      <button type="button" class="wild-btn-primary mb-6" (click)="dupecheck()">Run Duplicate Check</button>
      <p *ngIf="dupeCheckinProgress == true">Running duplicate check...</p>
      <div>
        <div class="mb-4" *ngFor="let result of dupeCheckResults">
          <p class="font-bold mb-1 text-md ">{{result.name}}</p>
          <p class="text-sm mb-1">{{result.swimSpotTypeDescription}}</p>
          <p class="text-sm mb-1">{{result.locationDescription}}</p>
          <p class="text-sm text-blue-700">{{result.distance}}km away</p>
          <a class="cursor-pointer underline text-sm mt-1" target="_blank"
            [href]="domainforspot + result.canonical">open</a>
        </div>
      </div>
    </div>
    <div class="my-6">
      <h1>Photos</h1>
      <div class="my-10">
        <div class="mt-12">
          <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            <li *ngFor="let image of images$ | async" class="relative">
              <div
                class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img [src]='imagesUrl + image.image + "_1080x1080.jpeg?alt=media"' alt=""
                  class="object-cover pointer-events-none group-hover:opacity-75">
                <button type="button" class="absolute inset-0 focus:outline-none">
                </button>
              </div>
              <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                {{image.creation_date.toDate() | date}} <span class="text-green-500 font-bold"
                  *ngIf="image.image == swimspot?.image?.image">Main Photo</span></p>
              <p *ngIf="image.status == ImageStatus.DELETED"
                class="block text-sm font-medium text-gray-500 pointer-events-none">Deleted</p>
              <p *ngIf="image.status == ImageStatus.INREVIEW"
                class="block text-sm font-medium text-gray-500 pointer-events-none">In Review</p>
              <p *ngIf="image.status == ImageStatus.LIVE"
                class="block text-sm font-medium text-gray-500 pointer-events-none">Live</p>
              <p *ngIf="image.status == ImageStatus.UNKNOWN"
                class="block text-sm font-medium text-gray-500 pointer-events-none">UNKNOWN</p>
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                <button (click)="setAsMainPhoto(image)" type="button"
                  class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  Use
                </button>
                <button (click)="openImageInModal(image)" type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  Modal
                </button>
                <a target="_blank" [href]='imagesUrl + image.image + "_1080x1080.jpeg?alt=media"'
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  Expand
                </a>
                <button [routerLink]="['/imagesupdate',image.id]" type="button"
                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                  Open
                </button>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <button type="button" class="wild-btn-primary" (click)="uploadPics()">Upload Photos</button>
    </div>
    <div class="my-6">
      <h1 class="mb-6">Generate Map Image</h1>
      <p class="mb-1 text-sm text-gray-400 font-bold">Select zoom level</p>
      <ul class="mb-6">
        <!-- Make bold and add zoom level prefix to text -->
        <li (click)="generateImageSetZoom(10)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 10}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 10 - This makes windermere seems small
        </li>
        <li (click)="generateImageSetZoom(11)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 11}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 11 - Fits Lough Neagh in Ireland</li>
        <li (click)="generateImageSetZoom(12)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 12}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 12 - Fits Windermere
          sands</li>
        <li (click)="generateImageSetZoom(13)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 13}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 13 - extremely large beach like saunton
          sands</li>
        <li (click)="generateImageSetZoom(14)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 14}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 14 - large beach like woolacombe</li>
        <li (click)="generateImageSetZoom(15)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 15}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 15 - medium beach like Dawlish </li>
        <li (click)="generateImageSetZoom(16)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 16}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 16 - small beach like Croyde Bay</li>
        <li (click)="generateImageSetZoom(17)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 17}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 17 - Fits Wyndley Lake with padding</li>
        <li (click)="generateImageSetZoom(18)" [ngClass]="{'text-red-700 hover:font-bold': zoom == 18}"
          class="cursor-pointer hover:text-red-700 hover:font-bold">Zoom level 18 - Smaller than wyndley lake</li>
      </ul>
      <button type="button" class="wild-btn-primary " (click)="generateImage()">Generate <span *ngIf="zoom"> at zoom
          level {{zoom}} </span></button>
      <p class="my-3" *ngIf="generatingMapImage">Generating Map Image...</p>
    </div>
    <div class="my-6">
      <h1 class="mb-6">Scrape for Images</h1>
      <button type="button" class="wild-btn-primary " (click)="imageSearch(false)">Scrape</button>
      <div class="mt-6" *ngIf="imageSearchResults">
        <div *ngFor="let result of imageSearchResults.results">
          <div class="flex mb-4">
            <div>
              <a class="my-2 cursor-pointer " target="_blank" [href]="result.imageContextLink">
                <img class="rounded-lg cursor-pointer" [src]="result.imageThumbnail" />
              </a>
            </div>
            <div class="ml-4">
              <h3 class="font-bold mb-2 text-md">{{result.title}}</h3>
              <p class="text-sm mb-2">h - {{result.imageHeight}} X w - imageWidth</p>
              <p class="text-sm mb-2">{{result.snippet}}</p>
              <a class=" block text-xs my-2 cursor-pointer underline" target="_blank" [href]="result.link">Image
                Link</a>
              <a class="block text-xs my-2 cursor-pointer underline" target="_blank"
                [href]="result.imageContextLink">Website containing image</a>
            </div>
          </div>
        </div>
      </div>
      <button type="button" *ngIf="!imageSearchInProgress && imageSearchResults" class="wild-btn-primary my-2"
        (click)="imageSearch(true)">Load more...</button>
      <p *ngIf="imageSearchInProgress">Search for images...</p>
    </div>
    <h1 class="mt-6">Warnings</h1>
    <div [formGroup]="form">
      <div formArrayName="warnings">
        <div class="mb-4" *ngFor="let warning of warnings.controls; let pointIndex=index" [formGroupName]="pointIndex">
          <app-inputgroup>
            <app-select [options]="warningTypeOptions" [form]="warning" name="type" description="Type" [span]=2>
            </app-select>
            <app-input [form]="warning" name="header" description="Header" [span]=4>
            </app-input>
          </app-inputgroup>
          <app-inputgroup>
            <app-textarea [form]="warning" name="body" description="Body" [span]=5>
            </app-textarea>
          </app-inputgroup>
          <app-inputgroup>
            <app-formdatepicker [form]="warning" name="date" description="Date" [span]=3>
            </app-formdatepicker>
          </app-inputgroup>
          <div class="flex justify-end">
            <button type="button" class="mt-3 wild-btn-primary" type="button" (click)="removeWarning(pointIndex)">Delete
              Warning
              {{pointIndex + 1}}</button>
          </div>
          <div class="flex justify-end ">
            <a class="mt-3 wild-text-primary underline cursor-pointer text-xs"
              (click)="addWarning(0,'Header','Body',null,pointIndex)" type="button">add
              warning below</a>
          </div>
          <div class="flex justify-end ">
            <div>
              <a class="mt-3 wild-text-primary cursor-pointer text-xl mr-4" (click)="moveWarning(pointIndex,true)"
                type="button">☝️</a>
              <a class="mt-3 wild-text-primary cursor-pointer text-xl" (click)="moveWarning(pointIndex,false)"
                type="button">👇</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <button type="button" class="wild-btn-primary mt-3" (click)="addWarning(0,'Header','Body',null)" type="button">Add
      Warning</button>
    <h1 class="mt-6">Is Using a Map Image</h1>
    <app-inputgroup>
      <app-toggle [form]="form" name="mapimage" description="Map Image as Main" [span]=2
        [value]="swimspot.mapimage ?? ''">
      </app-toggle>
    </app-inputgroup>
    <h1 class="mt-6">Activities</h1>
    <app-inputgroup>
      <app-toggle [form]="form" name="swimming" description="Swimming" [span]=2 [value]="swimspot.swimming ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="waterskiing" description="Water ski" [span]=2
        [value]="swimspot.waterskiing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="wakeboarding" description="Wake Boarding" [span]=2
        [value]="swimspot.wakeboarding ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="kneeboarding" description="Knee Boarding" [span]=2
        [value]="swimspot.kneeboarding ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="surfing" description="Surfing" [span]=2 [value]="swimspot.surfing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="bodyboarding" description="Body Boarding" [span]=2
        [value]="swimspot.bodyboarding ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="bodysurfing" description="Body Surfing" [span]=2
        [value]="swimspot.bodysurfing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="canoeing" description="Canoeing" [span]=2 [value]="swimspot.canoeing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="kayaking" description="Kayaking" [span]=2 [value]="swimspot.kayaking ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="windsurfing" description="Wind Surfing" [span]=2
        [value]="swimspot.windsurfing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="kiteboarding" description="Kite Boarding" [span]=2
        [value]="swimspot.kiteboarding ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="sailing" description="Sailing" [span]=2 [value]="swimspot.sailing ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="skimboarding" description="Skim Boarding" [span]=2
        [value]="swimspot.skimboarding ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="scubadiving" description="Scuba Diving" [span]=2
        [value]="swimspot.scubadiving ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="snorkeling" description="Snorkeling" [span]=2 [value]="swimspot.snorkeling ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="whitewaterrafting" description="White Water Rafting" [span]=2
        [value]="swimspot.whitewaterrafting ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="freediving" description="Free Diving" [span]=2
        [value]="swimspot.freediving ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="canyoning" description="Canyoning" [span]=2 [value]="swimspot.canyoning ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="hydrofoil" description="Hydrofoil" [span]=2 [value]="swimspot.hydrofoil ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="yachting" description="Yachting" [span]=2 [value]="swimspot.yachting ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="flyboarding" description="Flyboarding" [span]=2
        [value]="swimspot.flyboarding ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="rowing" description="Rowing" [span]=2 [value]="swimspot.rowing ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="icediving" description="Ice Diving" [span]=2 [value]="swimspot.icediving ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="triathlon" description="Triathlon" [span]=2 [value]="swimspot.triathlon ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="swimrun" description="Swim Run" [span]=2 [value]="swimspot.swimrun ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="walking" description="Walking" [span]=2 [value]="swimspot.walking ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="iceswimming" description="Ice Swimming" [span]=2
        [value]="swimspot.iceswimming ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="coasteering" description="Coasteering" [span]=2
        [value]="swimspot.coasteering ?? ''">
      </app-toggle>

    </app-inputgroup>
    <br />
    <h1>Other Tags</h1>
    <app-inputgroup>
      <app-toggle [form]="form" name="gentleSlope" description="Gentle Slope" [span]=2
        [value]="swimspot.gentleSlope ?? ''"></app-toggle>
      <app-toggle [form]="form" name="bigDropOff" description="Big Drop Off" [span]=2
        [value]="swimspot.bigDropOff ?? ''"></app-toggle>
      <app-toggle [form]="form" name="easyToWalkTo" description="Easy Walk" [span]=2
        [value]="swimspot.easyToWalkTo ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="hasABeach" description="Beach" [span]=2 [value]="swimspot.hasABeach ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="itIsMuddy" description="Muddy" [span]=2 [value]="swimspot.itIsMuddy ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="lotsOfReeds" description="Reeds" [span]=2 [value]="swimspot.lotsOfReeds ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="mountainViews" description="Mountains" [span]=2
        [value]="swimspot.mountainViews ?? ''"></app-toggle>
      <app-toggle [form]="form" name="nearbyParking" description="Nearby Parking" [span]=2
        [value]="swimspot.nearbyParking ?? ''"></app-toggle>
      <app-toggle [form]="form" name="quietLocation" description="Quiet" [span]=2
        [value]="swimspot.quietLocation ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="rockyGround" description="Rocky" [span]=2 [value]="swimspot.rockyGround ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="stepsToGetIn" description="Steps" [span]=2 [value]="swimspot.stepsToGetIn ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="suitableForKids" description="Children" [span]=2
        [value]="swimspot.suitableForKids ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="paddling" description="Paddling" [span]=2 [value]="swimspot.paddling ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="skinnyDip" description="Skinny Dip" [span]=2 [value]="swimspot.skinnyDip ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="trainAccess" description="Train Access" [span]=2
        [value]="swimspot.trainAccess ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="cycling" description="Cycling" [span]=2 [value]="swimspot.cycling ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="boating" description="Boating" [span]=2 [value]="swimspot.boating ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="supping" description="Supping" [span]=2 [value]="swimspot.supping ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="pubs" description="Pubs" [span]=2 [value]="swimspot.pubs ?? ''"></app-toggle>
      <app-toggle [form]="form" name="camping" description="Camping" [span]=2 [value]="swimspot.camping ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="jumping" description="Jumping" [span]=2 [value]="swimspot.jumping ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="cliffs" description="Cliffs" [span]=2 [value]="swimspot.cliffs ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="bridges" description="Bridges" [span]=2 [value]="swimspot.bridges ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="chutes" description="Chutes" [span]=2 [value]="swimspot.chutes ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="waterfalls" description="Waterfalls" [span]=2 [value]="swimspot.waterfalls ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="dogs" description="Dog Friendly" [span]=2 [value]="swimspot.dogs ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="accessibility" description="Accessible" [span]=2
        [value]="swimspot.accessibility ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="payToSwim" description="Pay to swim" [span]=2 [value]="swimspot.payToSwim ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="privateProperty" description="Private Property" [span]=2
        [value]="swimspot.privateProperty ?? ''"></app-toggle>
      <app-toggle [form]="form" name="swing" description="Swing" [span]=2 [value]="swimspot.swing ?? ''"></app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="longSwim" description="Long Swim" [span]=2 [value]="swimspot.longSwim ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="picnic" description="Picnic" [span]=2 [value]="swimspot.picnic ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="sunset" description="Sunset" [span]=2 [value]="swimspot.sunset ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="pools" description="Pools" [span]=2 [value]="swimspot.pools ?? ''"></app-toggle>
      <app-toggle [form]="form" name="weir" description="Weir" [span]=2 [value]="swimspot.weir ?? ''"></app-toggle>
      <app-toggle [form]="form" name="pebbles" description="Pebbles" [span]=2 [value]="swimspot.pebbles ?? ''">
      </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="caves" description="Caves" [span]=2 [value]="swimspot.caves ?? ''"></app-toggle>
      <app-toggle [form]="form" name="estuary" description="Estuary" [span]=2 [value]="swimspot.estuary ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="lido" description="Lido" [span]=2 [value]="swimspot.lido ?? ''"> </app-toggle>
    </app-inputgroup>
    <app-inputgroup>
      <app-toggle [form]="form" name="toilets" description="Toilets" [span]=2 [value]="swimspot.toilets ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="quarry" description="Quarry" [span]=2 [value]="swimspot.quarry ?? ''">
      </app-toggle>
      <app-toggle [form]="form" name="sunrise" description="Sunrise" [span]=2 [value]="swimspot.sunrise ?? ''">
      </app-toggle>
    </app-inputgroup>
  </app-form>
</app-wrapper>