import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { Observable } from 'rxjs';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { ImagesDataService } from 'src/portal/services/images-data.service';
import { NearestWaterway } from 'src/shared/data/models/nearestWaterway_model';
import { WaterwayType } from 'src/shared/data/enums/waterway_type';
import { ImageModel } from 'src/shared/data/models/images_model';
import { ImageStatus } from 'src/shared/data/enums/image_status';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { SwimSpotStatus } from 'src/shared/data/enums/swimspot_status_enum';
import { SelectModel } from 'src/shared/components/select/select_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';
import { SwimSpotWarningType } from 'src/shared/data/enums/swimspot_warning_type';
import { GoogleSearchResultsTrimmedModel } from 'src/shared/data/models/GoogleSearchResultsTrimmedModel';
import { FilemanagerComponent } from 'src/shared/components/filemanager/filemanager.component';
import { SourceDocumentType } from 'src/shared/data/enums/source_document_type';
import { SearchResult } from 'src/shared/data/models/SearchResult';
import { ImagesupdateComponent } from '../imagesupdate/imagesupdate.component';
import { GoogleMap } from '@angular/google-maps';
// import { HttpService } from 'src/shared/services/http/http_service'; 

@Component({
  selector: 'app-swimspotsupdate',
  templateUrl: './swimspotsupdate.component.html',
  styleUrls: ['./swimspotsupdate.component.scss']
})
export class SwimspotsupdateComponent implements OnInit {
  options!: google.maps.MapOptions;
  domainforspot: string = environment.domain + "/swimspot/";
  markerOptions!: google.maps.MarkerOptions;
  imageSearchResults: GoogleSearchResultsTrimmedModel;
  imageSearchInProgress: boolean = false;
  generatingMapImage: boolean = false;
  dupeCheckinProgress: boolean = false;
  markerPositions!: google.maps.LatLngLiteral[];
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  updateMarker(event: google.maps.MapMouseEvent) {
    var latlng = event.latLng.toJSON();
    this.markerPositions = [event.latLng.toJSON()];
    this.form.controls['latitude'].setValue(latlng.lat);
    this.form.controls['longitude'].setValue(latlng.lng);
  }
  zoomChanged(event: any) {
    this.zoom = this.map.getZoom();
  }

  nearestWaterway: NearestWaterway;
  loading: boolean;
  dupeCheckResults: SearchResult[];
  zoom: number = 14;
  imagesUrl = environment.imagesUrl;
  mapsLoaded: Observable<boolean>;
  coordinates: google.maps.LatLng | undefined;
  form: UntypedFormGroup = this.fb.group({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    gettingthere: new UntypedFormControl('', []),
    accessibilityinformation: new UntypedFormControl('', []),
    notes: new UntypedFormControl('', []),
    latitude: new UntypedFormControl('', [Validators.required]),
    longitude: new UntypedFormControl('', [Validators.required]),
    status: new UntypedFormControl(0, [Validators.required]),
    type: new UntypedFormControl(WaterwayType.UNKNOWN, [Validators.required]),
    gentleSlope: new UntypedFormControl(null, []),
    bigDropOff: new UntypedFormControl(null, []),
    easyToWalkTo: new UntypedFormControl(null, []),
    hasABeach: new UntypedFormControl(null, []),
    itIsMuddy: new UntypedFormControl(null, []),
    lotsOfReeds: new UntypedFormControl(null, []),
    mountainViews: new UntypedFormControl(null, []),
    trainAccess: new UntypedFormControl(null, []),
    nearbyParking: new UntypedFormControl(null, []),
    quietLocation: new UntypedFormControl(null, []),
    rockyGround: new UntypedFormControl(null, []),
    stepsToGetIn: new UntypedFormControl(null, []),
    suitableForKids: new UntypedFormControl(null, []),
    paddling: new UntypedFormControl(null, []),
    skinnyDip: new UntypedFormControl(null, []),
    picnic: new UntypedFormControl(null, []),
    cycling: new UntypedFormControl(null, []),
    boating: new UntypedFormControl(null, []),
    supping: new UntypedFormControl(null, []),
    pubs: new UntypedFormControl(null, []),
    camping: new UntypedFormControl(null, []),
    jumping: new UntypedFormControl(null, []),
    cliffs: new UntypedFormControl(null, []),
    bridges: new UntypedFormControl(null, []),
    chutes: new UntypedFormControl(null, []),
    waterfalls: new UntypedFormControl(null, []),
    dogs: new UntypedFormControl(null, []),
    accessibility: new UntypedFormControl(null, []),
    payToSwim: new UntypedFormControl(null, []),
    longSwim: new UntypedFormControl(null, []),
    privateProperty: new UntypedFormControl(null, []),
    swing: new UntypedFormControl(null, [],),
    pools: new UntypedFormControl(null, [],),
    weir: new UntypedFormControl(null, [],),
    sunset: new UntypedFormControl(null, [],),
    canonical: new UntypedFormControl(null, [],),
    pebbles: new UntypedFormControl(null, [],),
    mapimage: new UntypedFormControl(null, [],),
    caves: new UntypedFormControl(null, [],),
    estuary: new UntypedFormControl(null, [],),
    lido: new UntypedFormControl(null, [],),
    copywrite: new UntypedFormControl(null, [],),
    toilets: new UntypedFormControl(null, [],),
    quarry: new UntypedFormControl(null, [],),
    notifiedCreatorAboutVerification: new UntypedFormControl(null, [],),
    sunrise: new UntypedFormControl(null, [],),
    waterskiing: new UntypedFormControl(null, [],),
    wakeboarding: new UntypedFormControl(null, [],),
    kneeboarding: new UntypedFormControl(null, [],),
    surfing: new UntypedFormControl(null, [],),
    bodyboarding: new UntypedFormControl(null, [],),
    bodysurfing: new UntypedFormControl(null, [],),
    canoeing: new UntypedFormControl(null, [],),
    kayaking: new UntypedFormControl(null, [],),
    windsurfing: new UntypedFormControl(null, [],),
    kiteboarding: new UntypedFormControl(null, [],),
    sailing: new UntypedFormControl(null, [],),
    skimboarding: new UntypedFormControl(null, [],),
    scubadiving: new UntypedFormControl(null, [],),
    snorkeling: new UntypedFormControl(null, [],),
    whitewaterrafting: new UntypedFormControl(null, [],),
    freediving: new UntypedFormControl(null, [],),
    canyoning: new UntypedFormControl(null, [],),
    hydrofoil: new UntypedFormControl(null, [],),
    yachting: new UntypedFormControl(null, [],),
    flyboarding: new UntypedFormControl(null, [],),
    rowing: new UntypedFormControl(null, [],),
    icediving: new UntypedFormControl(null, [],),
    triathlon: new UntypedFormControl(null, [],),
    swimrun: new UntypedFormControl(null, [],),
    walking: new UntypedFormControl(null, [],),
    iceswimming: new UntypedFormControl(null, [],),
    coasteering: new UntypedFormControl(null, [],),
    swimming: new UntypedFormControl(null, [],),
    warnings: this.fb.array([]),
  });



  swimspot!: SwimSpot;
  swimSpotId: string;
  SwimSpotStatus = SwimSpotStatus;
  statusOptions: SelectModel[] = [
    {
      name: "Discarded",
      value: SwimSpotStatus.DISCARDED
    },
    {
      name: "Draft",
      value: SwimSpotStatus.DRAFT
    },
    {
      name: "In Review",
      value: SwimSpotStatus.INREVIEW
    },
    {
      name: "Imported",
      value: SwimSpotStatus.IMPORTED
    },
    {
      name: "Live",
      value: SwimSpotStatus.LIVE
    },
    {
      name: "Unknown",
      value: SwimSpotStatus.UNKNOWN
    }
  ]
  warningTypeOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: SwimSpotWarningType.UNKNOWN
    },
    {
      name: "Info",
      value: SwimSpotWarningType.INFO
    },
    {
      name: "Warning",
      value: SwimSpotWarningType.WARNING
    },
    {
      name: "Danger",
      value: SwimSpotWarningType.DANGER
    },
  ]
  typeOptions: SelectModel[] = [
    {
      name: "Lake",
      value: WaterwayType.LAKE
    },
    {
      name: "River",
      value: WaterwayType.RIVER
    },
    {
      name: "Coastal",
      value: WaterwayType.COASTAL
    },
    {
      name: "Unknown",
      value: WaterwayType.UNKNOWN
    }
  ];
  downloadURL: Observable<string>;
  fireRef: string;
  images$: Observable<ImageModel[]>;
  src: string;
  WaterwayType = WaterwayType;
  ImageStatus = ImageStatus;

  constructor(
    private notify: NotifyService,
    private route: ActivatedRoute,
    private router: Router,
    private data: SwimspotsdataService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private maps: MapsService,
    private http: HttpService,
    private imageData: ImagesDataService,
  ) {
    this.mapsLoaded = this.maps.loadMaps();
  }

  get warnings() {
    return this.form.get('warnings') as UntypedFormArray;
  }

  addWarning(type: SwimSpotWarningType, header: string, body: string, date: string, index: number = null) {
    var formGroup = this.fb.group({
      type: new UntypedFormControl(type, [
        Validators.required
      ]), header: new UntypedFormControl(header, [
        Validators.required
      ]),
      body: new UntypedFormControl(body, [
        Validators.required
      ]),
      date: new UntypedFormControl(date, [
        Validators.required
      ])
    });
    if (index == null) {
      this.warnings.push(formGroup);
    } else {
      this.warnings.insert(index + 1, formGroup);
    }
  }

  moveWarning(index: number, up: boolean) {
    if (index == 0 && up == true) {
      alert("Already at top")
      return
    }
    var warning = this.warnings.at(index);
    this.removeWarning(index);
    if (up) {
      this.warnings.insert(index - 1, warning);
    } else {
      this.warnings.insert(index + 1, warning);
    }
  }

  removeWarning(index) {
    this.warnings.removeAt(index);
  }

  approve() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to approve",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.confirmApprove();
      },
    }
    this.modalService.show(settings);
  }

  confirmApprove() {
    this.loading = true;
    this.http.post(`${environment.api}/portal/swimspots/approve`, { id: this.swimspot.id }).toPromise().then(async () => {
      this.notify.notify("Approved", "Check app to verify", NotifyType.SUCCESS);
      this.approved();
    })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }
  generateImageSetZoom(zoom: number) {
    this.zoom = zoom;
  }
  generateImage() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to generate a map image for this spot?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.confirmGenerateImage();
      },
    }
    this.modalService.show(settings);
  }

  confirmGenerateImage() {
    if (!this.zoom) {
      this.handlerError("Please choose a zoom level");
      return;
    }
    this.loading = true;
    this.generatingMapImage = true;
    this.http.post(`${environment.api}/portal/swimspots/generatemapimage`, { id: this.swimspot.id, zoom: this.zoom }).toPromise().then(async () => {
      this.notify.notify("Generated", "Image will appear soon", NotifyType.SUCCESS);
    })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
        this.generatingMapImage = false;
        this.load();
      })
  }

  dupecheck() {
    this.loading = true;
    this.dupeCheckinProgress = true;
    this.http.get(`${environment.api}/user/search/dupecheck?latitude=${this.swimspot.latitude}&longitude=${this.swimspot.longitude}`).toPromise().then(async (results: any) => {
      this.dupeCheckResults = results.results;
    })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
        this.dupeCheckinProgress = false;
      })
  }

  imageSearch(nextPage: boolean) {
    this.loading = true;
    this.imageSearchInProgress = true;
    var from = "";
    if (nextPage == true && this.imageSearchResults?.results?.length > 0) {
      from = this.imageSearchResults?.results.length.toString();
    }
    this.http.get(`${environment.api}/portal/swimspots/images?q=${this.swimspot.name}&from=${from}`).toPromise().then((results: GoogleSearchResultsTrimmedModel) => {
      if (nextPage == true) {
        this.imageSearchResults.results = this.imageSearchResults.results.concat(results.results);

      } else {
        this.imageSearchResults = results;

      }
    })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
        this.imageSearchInProgress = false;
      })
  }

  openImageInModal(image: ImageModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      large: true,
      close: true,
      component: ImagesupdateComponent,
      componentInputs: [{
        inputName: "passinimage",
        value: image
      },
      ],
      componentOutputs: [
        // {
        //   outputName: "updated",
        //   func: async () => {
        //     this.load();
        //   }
        // }
      ]
    }
    this.modalService.show(settings);
  }

  uploadPics() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: FilemanagerComponent,
      componentInputs: [{
        inputName: "sourceDocumentId",
        value: this.swimSpotId
      },
      {
        inputName: "sourceDocumentImageType",
        value: SourceDocumentType.SWIMSPOT
      },
      {
        inputName: "limit",
        value: 10
      },
      {
        inputName: "uploadOnly",
        value: true
      }],
      componentOutputs: [
        {
          outputName: "onComplete",
          func: async ($event: any) => {
            this.load();
          }
        }
      ],
      close: true,
      large: true
    }
    this.modalService.show(settings);
  }

  approved() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.SUCCESS,
      description: "Where to next?",
      header: "Approved",
      close: true,
      ctaonetext: "Reload",
      ctatwotext: "Back to list",
      ctaonetextclick: async () => {
        this.load();
      },
      ctatwotextclick: () => {
        this.goToList();
      }
    }
    this.modalService.show(settings);
  }

  async ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.swimSpotId = params.id;
      this.load();
    });
  }

  goToList() {
    this.router.navigate(["inreview"]);
  }

  load() {
    this.swimspot = null;
    this.data.read(this.swimSpotId).subscribe((data) => {
      this.swimspot = data;
      this.dataLoaded();
    }, (error) => {
      this.notify.notify("Error", error.message, NotifyType.ERROR);
    });
  }

  async setAsMainPhoto(image: ImageModel) {
    this.swimspot.image = image;
    this.notify.notify("Image Set", "Save Swim Spot to lock it in", NotifyType.WARNING);
  }

  dataLoaded() {
    this.images$ = this.imageData.list(this.swimspot.id);
    this.src = this.swimspot.image != null ? environment.imagesUrl + this.swimspot.image?.image + "_200x200.jpeg?alt=media" : null;
    if (this.swimspot.warnings && this.swimspot.warnings.length > 0) {
      this.swimspot.warnings.forEach(element => {
        this.addWarning(element.type, element.header, element.body, element.date);
      });
    }
    this.options = {
      mapTypeId: 'satellite',
      center: {
        lat: Number.parseFloat(this.swimspot.latitude),
        lng: Number.parseFloat(this.swimspot.longitude)
      },
      zoom: this.zoom,
    };
    this.markerOptions = { draggable: true };
    this.markerPositions = [{
      lat: Number.parseFloat(this.swimspot.latitude),
      lng: Number.parseFloat(this.swimspot.longitude)
    }];
  }

  async update(data: any) {
    this.loading = true;
    const swimspot = {
      ...this.swimspot,
      ...data,
    }
    await this.data
      .update(swimspot)
      .then(() => {
        this.loading = false;
        this.notify.notify("Saved", "Swim Spot Saved", NotifyType.SUCCESS);
      })
      .catch((error: any) => {
        this.handlerError(error.message);
      })
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

}
