import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { UsersDataService } from 'src/portal/services/users-data.service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { UserStatus } from 'src/shared/data/enums/user_status';
import { UserModel } from 'src/shared/data/models/user_model';

@Component({
  selector: 'app-usersupdate',
  templateUrl: './usersupdate.component.html',
  styleUrls: ['./usersupdate.component.scss']
})
export class UsersupdateComponent implements OnInit {


  form!: UntypedFormGroup;
  user!: UserModel;
  statusOptions: SelectModel[] = [
    {
      name: "Live",
      value: UserStatus.LIVE
    },
    {
      name: "Deleted",
      value: UserStatus.DELETED
    },
    {
      name: "Unknown",
      value: UserStatus.UNKNOWN
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private data: UsersDataService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.data.read(params.id).subscribe((data) => {
        this.user = data;
        this.dataLoaded();
      }, (error) => {
        alert(error.message);
      });
    });

  }
  dataLoaded() {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.user?.email ?? "", [Validators.required]),
      status: new UntypedFormControl(this.user?.status ?? UserStatus.UNKNOWN, [Validators.required]),
    });
  }

  async update(data: any) {
    const post = {
      ...this.user,
      ...data,
    }
    if (this.user) {
      await this.data
        .update(post)
        .then(() => {
          alert("Updated")
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    } else {
      await this.data
        .create(post)
        .then(() => {
          alert("Added")
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
    }
  }

  handlerError(message: string) {
    alert(message);
  }
}
