import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewStatus } from 'src/shared/data/enums/review_status';
import { Review } from 'src/shared/data/models/review_model';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsDataService {

  constructor(
    private firestore: FirestoreService
  ) { }

  latest(): Observable<Review[]> {
    return this.firestore.getListByQuery("reviews", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: ReviewStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      },
      {
        type: FirestoreQueryType.LIMIT,
        operator: "==",
        property: "",
        value: 20
      }
    ])
  }
}
