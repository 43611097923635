//! WARNING MAKE SURE TEXT DOES NOT EQUAL ENUM... VERY IMPORTANT!!!
export enum BadgeType {
    UNKNOWN = 0,
    UNKNOWN_d = 'Unknown' as any,
    SOS = 1,
    SOS_d = 'SOS Badge' as any,
    SOSRIVER = 2,
    SOSRIVER_d = 'SOS River' as any,
    SOSLAKE = 3,
    SOSLAKE_d = 'SOS Lake' as any,
    SOSCOASTAL = 4,
    SOSCOASTAL_d = 'SOS Coastal' as any,
    ONEREVIEW = 5,
    ONEREVIEW_d = 'Reviews One' as any,
    FIVEREVIEWS = 6,
    FIVEREVIEWS_d = 'Reviews Five' as any,
    TENREVIEWS = 7,
    TENREVIEWS_d = 'Reviews Ten' as any,
    TWENTYFIVEREVIEWS = 8,
    TWENTYFIVEREVIEWS_d = 'Reviews 25' as any,
    FIFTYREVIEWS = 9,
    FIFTYREVIEWS_d = 'Reviews 50' as any,
    SPOTSONE = 10,
    SPOTSONE_d = 'Spots One' as any,
    SPOTSFIVE = 11,
    SPOTSFIVE_d = 'Spots Five' as any,
    SPOTSTEN = 12,
    SPOTSTEN_d = 'Spots Ten' as any,
    SPOTSFIFTEEN = 13,
    SPOTSFIFTEEN_d = 'Spots Fifteen' as any,
    SPOTSTWENTY = 14,
    SPOTSTWENTY_d = 'Spots Twenty' as any,
    SPOTSTWENTYFIVE = 15,
    SPOTSTWENTYFIVE_d = 'Spots Twenty Five' as any,
    SPOTSTWENTYTHIRTY = 16,
    SPOTSTWENTYTHIRTY_d = 'Spots Thirty' as any,
    SWIMMINGDISTANCE50 = 17,
    SWIMMINGDISTANCE50_d = 'Swimming Distance - 50m' as any,
    SWIMMINGDISTANCE100 = 18,
    SWIMMINGDISTANCE100_d = 'Swimming Distance - 100m' as any,
    SWIMMINGDISTANCE200 = 19,
    SWIMMINGDISTANCE200_d = 'Swimming Distance - 200m' as any,
    SWIMMINGDISTANCE500 = 20,
    SWIMMINGDISTANCE500_d = 'Swimming Distance - 500m' as any,
    SWIMMINGDISTANCE1000 = 21,
    SWIMMINGDISTANCE1000_d = 'Swimming Distance - 1000m' as any,
    SWIMMINGDISTANCE1500 = 22,
    SWIMMINGDISTANCE1500_d = 'Swimming Distance - 1500m' as any,
    SWIMMINGDISTANCEMILE = 23,
    SWIMMINGDISTANCEMILE_d = 'Swimming Distance - Mile - 1609m' as any,
    SWIMMINGDISTANCEHALFIRONMAN = 24,
    SWIMMINGDISTANCEHALFIRONMAN_d = 'Swimming Distance - Half Ironman - 1900m' as any,
    SWIMMINGDISTANCEFULLIRONMAN = 25,
    SWIMMINGDISTANCEFULLIRONMAN_d = 'Swimming Distance - Half Ironman - 3800m' as any,
    SWIMMINGDISTANCE5000 = 26,
    SWIMMINGDISTANCE5000_d = 'Swimming Distance - 5000m' as any,
    SWIMMINGDISTANCE10000 = 27,
    SWIMMINGDISTANCE10000_d = 'Swimming Distance - 10000m' as any,
    LAKESWIMMER = 28,
    LAKESWIMMER_d = 'Lake Swimmer' as any,
    COASTALSWIMMER = 29,
    COASTALSWIMMER_d = 'Coastal Swimmer' as any,
    RIVERSWIMMER = 30,
    RIVERSWIMMER_d = 'River Swimmer' as any,
}

